import { toast } from 'react-toastify';

export const validateAmountSchedule = (amount, netPay, frequency, withdrawalPercent, maxAmount) => {

    const amountNum = Number(amount);
    const dailyAmount = Number(netPay / 30).toFixed(0);
    const weeklyAmount = Number(dailyAmount * 7).toFixed(0);
    const dailyLimit = withdrawalPercent !== null ? Number(dailyAmount * withdrawalPercent /100).toFixed(0) : Number(dailyAmount * 0.5);
    const halfDailyAmount =  withdrawalPercent !== null ? (Number(dailyAmount) * Number(withdrawalPercent / 100)).toFixed(0) :(Number(dailyAmount) / 2).toFixed(0) ;
    const halfWeeklyAmount = withdrawalPercent !== null ? (Number(weeklyAmount) * Number(withdrawalPercent / 100)).toFixed(0) : (Number(weeklyAmount) / 2).toFixed(0);
    const weeklyLimit = withdrawalPercent !== null ? Number(weeklyAmount * withdrawalPercent / 100).toFixed(0) : Number(weeklyAmount * 0.5);

    if (amount === "" || frequency === "") {
        toast.error('Please enter all fields');
        return false;
    }
    
    if (frequency === 'daily' && amountNum > halfDailyAmount) {
        toast.error(`Amount cannot be greater than ${dailyLimit} daily`);  
        return false;    
    }else if (halfDailyAmount < 500) {
        toast.error('Amount is less than 500');
        return false;
    };
    
    if (frequency === 'weekly' && amountNum > halfWeeklyAmount) {
        toast.error(`Amount cannot be greater than ${weeklyLimit} weekly`);
        return false;  
    }; 
    if( amountNum > maxAmount) {
        toast.error(`Amount cannot be greater than ${Math.round(maxAmount)}`);
        return false;  
    }; 
        return true;
};

export const validateAmountScheduleAutopay = (amount, netPay, frequency, withdrawalPercent, maxAmount) => {

    const amountNum = Number(amount);
    const dailyAmount = Number(netPay / 30).toFixed(0);
    const weeklyAmount = Number(dailyAmount * 7).toFixed(0);
    // const dailyLimit = withdrawalPercent !== null ? Number(dailyAmount * withdrawalPercent /100).toFixed(0) : Number(dailyAmount * 0.5);
    // const halfDailyAmount =  withdrawalPercent !== null ? (Number(dailyAmount) * Number(withdrawalPercent / 100)).toFixed(0) :(Number(dailyAmount) / 2).toFixed(0);
    const halfWeeklyAmount = withdrawalPercent !== null ? (Number(weeklyAmount) * Number(withdrawalPercent / 100)).toFixed(0) : (Number(weeklyAmount) / 2).toFixed(0);
    const weeklyLimit = withdrawalPercent !== null ? Number(weeklyAmount * withdrawalPercent / 100).toFixed(0) : Number(weeklyAmount * 0.5);

    if (amount === "" || frequency === "") {
        toast.error('Please enter all fields');
        return false;
    }
    
     if (amountNum < 500) {
        toast.error('Minimum amount is 500');
        return false;
    };
    
    if (frequency === 'weekly' && amountNum > halfWeeklyAmount) {
        toast.error(`Amount cannot be greater than ${weeklyLimit} weekly`);
        return false;  
    }; 
    if ( amountNum > maxAmount) {
        toast.error(`Amount cannot be greater than ${Math.round(maxAmount)}`);
        return false;  
    };
        return true;
};
