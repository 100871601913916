

const useIdleChecker = () => {
    
    return (
        <>
        </>
    );
}


export default useIdleChecker;