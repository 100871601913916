import * as actions from "./actionTypes";
import { toast } from 'react-toastify';

const initialState = {
    loading: false,
    allDashboard: {},
    loadingNewWithdrawal: false,
    newWithdrawalSuccess: false,
    newWithdrawal: {},
    withdrawalFeeLoading: false,
    withdrawalFee: {},
    withdrawalFeePayload: null,
    autopayFeeLoading: false,
    autopayFee: {},
    current: 0,
    withdrawalParams: {},
    withdrawalReasons: {},
    loadingReasons: false,
    successReasonWithdrawal: false,
};


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.GET_ALL_DASHBOARD:
            state = {
                ...state,
                loading: false,
                allDashboard: { ...action.payload }
            }
            return state;


        case actions.GET_ALL_DASHBOARD_START:
            state = {
                ...state,
                loading: true,
            }
            return state;


        case actions.GET_ALL_DASHBOARD_FAILED:
            toast.error(action.payload.message);
            state = {
                ...state,
                loading: false,
                allDashboard: {},
            }
            return state;


        case actions.GET_WITHDRAWAL_FEE:
            state = {
                ...state,
                withdrawalFeeLoading: false,
                withdrawalFee: action.payload.fee,
                withdrawalFeePayload: action.payload,
            }
            return state;


        case actions.GET_WITHDRAWAL_FEE_START:
            state = {
                ...state,
                withdrawalFeeLoading: true,
            }
            return state;


        case actions.GET_WITHDRAWAL_FEE_FAILED:
            toast.error(action.payload.message);
            state = {
                ...state,
                withdrawalFeeLoading: false,
                withdrawalFee: {},
                withdrawalFeePayload: null,
            }
            return state;
        
        // autopay fee
        case actions.GET_AUTOPAY_FEE:
            state = {
                ...state,
                autopayFeeLoading: false,
                autopayFee: action.payload,
            }
            return state;


        case actions.GET_AUTOPAY_FEE_START:
            state = {
                ...state,
               autopayFeeLoading: true,
            }
            return state;


        case actions.GET_AUTOPAY_FEE_FAILED:
            toast.error(action.payload.message);
            state = {
                ...state,
               autopayFeeLoading: false,
                autopayFee: {},
            }
            return state;


        case actions.NEW_WITHDRAWAL:
            state = {
                ...state,
                loadingNewWithdrawal: false,
                newWithdrawalSuccess: true,
                newWithdrawal: { ...action.payload }
            }
            return state;


        case actions.NEW_WITHDRAWAL_START:
            state = {
                ...state,
                loadingNewWithdrawal: true,
                newWithdrawalSuccess: false,
                newWithdrawal: {},
            }
            return state;


        case actions.NEW_WITHDRAWAL_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.data.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loadingNewWithdrawal: false,
                newWithdrawalSuccess: false,
                newWithdrawal: {},
            }
            return state;

        // withdrawal reason
        case actions.GET_WITHDRAWAL_REASON:
            state = {
                ...state,
                loadingReasons: false,
                successReasonWithdrawal: true,
                withdrawalReasons: { ...action.payload }
            }
            return state;


        case actions.GET_WITHDRAWAL_REASON_START:
            state = {
                ...state,
                loadingReasons: true,
                successReasonWithdrawal: false,
                withdrawalReasons: {},
            }
            return state;


        case actions.GET_WITHDRAWAL_REASON_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loadingReasons: false,
                successReasonWithdrawal: false,
                withdrawalReasons: {},
            }
            return state;

        case actions.NEW_WITHDRAWAL_RESET:
            state = {
                ...state,
                loadingNewWithdrawal: false,
                newWithdrawalSuccess: false,
                newWithdrawal: {},
            }
            return state;
        default:
            return state;
    }
}