import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from "assets/images/icons/grn-logo.svg";
import successGif from "assets/images/icons/project-icons/success-green.gif";
// import successIcon from "assets/images/newDesignAssets/svgs/success.svg";

class ResetPasswordSuccess extends Component {
    state = {  }

    render() { 
        return (
            <div className="w-full p-8 sm:p-14 md:my-auto">
                <div className="pb-14 lg:pl-4 lg:pr-4">
                    <div className="font-recoleta font-semibold text-2xl sm:text-3xl text-center">
                        <Link to="/" className=" my-8 h-8 sm:h-10 flex justify-center animate hover:scale-110 ">
                            <img src={logo} alt="EarniPay logo" className="h-full object-contain" />
                        </Link>
                    </div>
                    <div className="mt-8 text-center max-w-xs mx-auto">
                        <div className="h-48">
                            <img src={successGif} alt="EarniPay" className="h-full object-contain mx-auto" />
                        </div>
                        <div className="mt-0 font-semibold text-2xl sm:text-3xl">
                            Password Reset Successfully
                        </div>
                        <div className="mt-4 text-gray-500">
                            {/* You can now gain access to your account using your phone number and your new pin. */}
                            You can now gain access to your account using your email address and your new password.
                        </div>

                        <Link to="/login" className="btn btn-lg btn-block btn-ep-blue mt-8">Login</Link>

                        <div className="mt-6 checkbox hidden">
                            <label className="cursor-pointer inline-block">
                                <input type="checkbox" className="w-5 h-5 mr-2 form-checkbox" required />
                                <div className="text-sm text-gray-500 overflow-hidden">
                                I agree with terms &nbsp; conditions
                                    <Link to={{pathname:"https://earnipay.com/terms"}} target="_blank" className="font-bold text-gray-900 hover:underline">Terms and Conditions</Link>
                                    , and &nbsp;
                                    <Link to={{pathname:"https://earnipay.com/privacy-policy"}} target="_blank" className="font-bold text-gray-900 hover:underline">Privacy Policy</Link>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
 
export default ResetPasswordSuccess;