import './authPages.css';
import React, { useEffect, useState } from 'react';
import Joi from "joi-browser";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from "assets/images/newDesignAssets/svgs/logo.svg";

import { ProgressTracker, Steps } from 'utils/ProgressTracker';

import { setLoginPin } from 'store/auth/user/action';

const ConfirmNewPin = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [resetPasswordForm, setResetPasswordForm] = useState({
        newpin1: "",
        newpin2: "",
        newpin3: "",
        newpin4: ""
    });
    const [bgCol, setBgCol] = useState({
        box1: "#F3F4F6",
        box2: "#F3F4F6",
        box3: "#F3F4F6",
        box4: "#F3F4F6",
    });
    const { activationOTP, userPin } = useSelector(state => state.auth.user);
    const { signUpUser, rawData } = useSelector(state => state.auth.userPersist);
    const {  loading } = useSelector(state => state.auth.user);
    const currentStage = 2;
    const [, setErrors] = useState({});

    const formChange = (e) => {
        setResetPasswordForm({ ...resetPasswordForm, [e.currentTarget.name]: e.currentTarget.value });
    }

    const schema = {
        newpin1: Joi.string().length(1).required().label("newpin1").error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "any.empty":
                        err.message = "Please enter your new password";
                        break;
                    case "string.length":
                        err.message = "Please enter your new password";
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
        newpin2: Joi.string().length(1).required().label("newpin2").error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "any.empty":
                        err.message = "Please enter your new password";
                        break;
                    case "string.length":
                        err.message = "Please enter your new password";
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
        newpin3: Joi.string().length(1).required().label("newpin3").error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "any.empty":
                        err.message = "Please enter your new password";
                        break;
                    case "string.length":
                        err.message = "Please enter your new password";
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
        newpin4: Joi.string().length(1).required().label("newpin4").error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "any.empty":
                        err.message = "Please enter your new password";
                        break;
                    case "string.length":
                        err.message = "Please enter your new password";
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
    }

    const validate = () => {
        const options = { abortEarly: false };
        const result = Joi.validate(resetPasswordForm, schema, options);
        const error = result.error;
        if (!error) return null;

        const errors = {};
        for (let item of result.error.details) {
            errors[item.path[0]] = item.message;
        }
        return errors;
    };

    const submitForm = (e) => {
        e.preventDefault();
        const errors = validate();
        setErrors({ errors: errors || {} });
        const { newpin1, newpin2, newpin3, newpin4, } = resetPasswordForm;
        if (errors) {
            for (var x in errors) {
                toast.error(errors[x]);
            };
            return;
        }
        else {
            // alert('all good');
            const pinConfirm = `${newpin1}${newpin2}${newpin3}${newpin4}`;
            const { phoneNumber } = signUpUser;
            const { otp } = activationOTP;
            const { pin } = userPin;
            const signUpForm = {
                phoneNumber: phoneNumber,
                activationPin: otp,
                pin: pin,
                pin_confirmation: pinConfirm
            }
            dispatch(setLoginPin(signUpForm)).then(() => {
                if (rawData && rawData.message === 'Activation Successful') {
                    history.push("/get-started/success");
                };
            });
        }
    };

    useEffect(() => {
        if (rawData && rawData.message === 'Activation Successful') {
            history.push("/get-started/success");
        };

        //eslint-disable-next-line
    }, [rawData]);
    return (
        <div className="w-full p-8 sm:p-14 md:my-auto">
            <div className="pb-14 lg:pl-4 lg:pr-4">

                <div className="font-recoleta font-semibold text-2xl sm:text-3xl text-center">
                    <Link to="/login" className=" my-8 h-8 sm:h-10 flex justify-center animate hover:scale-110 ">
                        <img src={logo} alt="EarniPay logo" className="h-full object-contain" />
                    </Link>
                </div>

                <div className="font-sans">
                    <ProgressTracker current={currentStage} />
                    <div className='py-2'>
                        <Steps current={currentStage} />
                    </div>
                </div>
                <div>

                    <div className="mt-10 w-full max-w-lg mx-auto">

                        <div className="note mb-4 text-center hidden">
                            A password reset pin has been sent to your phone number
                        </div>

                        <div className='bg-white w-full py-5'>
                            <div className=" mb-4 text-left w-5/6 mx-auto">
                                <div className='font-recoleta font-semibold py-2 text-lg lg:text-2xl '>Confirm Access Pin</div>
                            </div>
                            <form onSubmit={submitForm} className='w-5/6 mx-auto'>

                                <div className="form-group">

                                    <div className='flex justify-between flex-wrap'>
                                        <input type="password" name="newpin1" className="form-input text-center bg-new-ep-grey" id='pin-input1' maxLength={1} value={resetPasswordForm.newpin1} onChange={formChange} style={{ "backgroundColor": `${bgCol.box1}`, "color": "#fff" }} onKeyUp={() => setBgCol({ box1: "#000" })}/>
                                        <input type="password" name="newpin2" className="form-input text-center bg-new-ep-grey" id='pin-input2' maxLength={1} value={resetPasswordForm.newpin2} onChange={formChange} style={{ "backgroundColor": `${bgCol.box2}`, "color": "#fff" }} onKeyUp={() => setBgCol({ box2: "#000" })}/>
                                        <input type="password" name="newpin3" className="form-input text-center bg-new-ep-grey" id='pin-input3' maxLength={1} value={resetPasswordForm.newpin3} onChange={formChange} style={{ "backgroundColor": `${bgCol.box3}`, "color": "#fff" }} onKeyUp={() => setBgCol({ box3: "#000" })}/>
                                        <input type="password" name="newpin4" className="form-input text-center bg-new-ep-grey" id='pin-input4' maxLength={1} value={resetPasswordForm.newpin4} onChange={formChange} style={{ "backgroundColor": `${bgCol.box4}`, "color": "#fff" }} onKeyUp={() => setBgCol({ box4: "#000" })}/>
                                    </div>
                                </div>

                                <button type="submit" className="btn btn-lg btn-block btn-ep-blue mt-8" disabled={loading}>
                                    <FontAwesomeIcon icon="spinner" spin className={"text-xl mr-2 " + (loading ? "inline" : "hidden")} />
                                    Continue
                                </button>

                            </form>

                        </div>

                    </div>
                </div>

            </div>
        </div>
    );
}

export default ConfirmNewPin;