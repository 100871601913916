export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_START = "LOGIN_USER_START";
export const LOGIN_USER_FAILED = "LOGIN_USER_FAILED";

export const SAVE_SIGNUP_DETAILS = "SAVE_SIGNUP_DETAILS";
export const SAVE_ACTIVATION_PIN = "SAVE_ACTIVATION_PIN";
export const SAVE_NEW_PIN = "SAVE_NEW_PIN";

export const SEND_ACTIVATION_PIN = "SEND_ACTIVATION_PIN";
export const SEND_ACTIVATION_PIN_START = "SEND_ACTIVATION_PIN_START";
export const SEND_ACTIVATION_PIN_FAILED = "SEND_ACTIVATION_PIN_FAILED";
export const SEND_ACTIVATION_PIN_RESET = "SEND_ACTIVATION_PIN_RESET";

export const SET_LOGIN_PIN = "SET_LOGIN_PIN";
export const SET_LOGIN_PIN_START = "SET_LOGIN_PIN_START";
export const SET_LOGIN_PIN_FAILED = "SET_LOGIN_PIN_FAILED";

export const SIGNUP_COMPLETE = "SIGNUP_COMPLETE";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_START = "FORGOT_PASSWORD_START";
export const FORGOT_PASSWORD_FAILED = "FORGOT_PASSWORD_FAILED";
export const FORGOT_PASSWORD_RESET = "FORGOT_PASSWORD_RESET";

export const SAVE_FORGOT_PASSWORD_FORM = "SAVE_FORGOT_PASSWORD_FORM";
export const SAVE_NEW_PIN_DETAILS = "SAVE_NEW_PIN_DETAILS";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";
export const RESET_PASSWORD_RESET = "RESET_PASSWORD_RESET";

export const SET_PASSWORD = "SET_PASSWORD";
export const SET_PASSWORD_START = "SET_PASSWORD_START";
export const SET_PASSWORD_FAILED = "SET_PASSWORD_FAILED";
export const SET_PASSWORD_RESET = "SET_PASSWORD_RESET";

export const EMAIL_SEND_OTP = "EMAIL_SEND_OTP";
export const EMAIL_SEND_OTP_START = "EMAIL_SEND_OTP_START";
export const EMAIL_SEND_OTP_FAILED = "EMAIL_SEND_OTP_FAILED";
export const EMAIL_SEND_OTP_RESET = "EMAIL_SEND_OTP_RESET";

export const EMAIL_VERIFY_OTP = "EMAIL_VERIFY_OTP";
export const EMAIL_VERIFY_OTP_START = "EMAIL_VERIFY_OTP_START";
export const EMAIL_VERIFY_OTP_FAILED = "EMAIL_VERIFY_OTP_FAILED";
export const EMAIL_VERIFY_OTP_RESET = "EMAIL_VERIFY_OTP_RESET";

export const UPDATE_EMAIL = "UPDATE_EMAIL";
export const UPDATE_EMAIL_START = "UPDATE_EMAIL_START";
export const UPDATE_EMAIL_FAILED = "UPDATE_EMAIL_FAILED";
export const UPDATE_EMAIL_RESET = "UPDATE_EMAIL_RESET";

export const VALIDATE_ACTIVATION_PIN_SUCCESS = "VALIDATE_ACTIVATION_PIN_SUCCESS";
export const VALIDATE_ACTIVATION_PIN_START = "VALIDATE_ACTIVATION_PIN_START";
export const VALIDATE_ACTIVATION_PIN_FAILED = "VALIDATE_ACTIVATION_PIN_FAILED";
export const VALIDATE_ACTIVATION_PIN_RESET = "VALIDATE_ACTIVATION_PIN_RESET";

export const UNSUBSCRIBE_EMAIL = "UNSUBSCRIBE_EMAIL";
export const UNSUBSCRIBE_EMAIL_START = "UNSUBSCRIBE_EMAIL_START";
export const UNSUBSCRIBE_EMAIL_FAILED = "UNSUBSCRIBE_EMAIL_FAILED";

export const UPDATE_LOGIN_USER = "UPDATE_LOGIN_USER";
export const UPDATE_LOGIN_PROFILE = "UPDATE_LOGIN_PROFILE";
export const SOFT_LOGOUT = "SOFT_LOGOUT";
export const LOGOUT_USER = "LOGOUT_USER";
