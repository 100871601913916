import React, { Component } from "react";
import store from "store/store";
import Joi from "joi-browser";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import appStore from "assets/images/icons/app-store.png";
import playStore from "assets/images/icons/play-store.png";
import * as auth from "store/auth/user/action";
import logo from "assets/images/icons/grn-logo.svg";
import DynamicHeadTag from "components/common/DynamicHeadTag";

class GetStarted2 extends Component {
	state = {
		signUpForm: {
			phoneNumber: "",
			referralCode: "",
		},
		lockReferralCode: false,
		errors: {},
		storeUnsubscribe: "",
	};

	formChange = (e) => {
		const formValue = { ...this.state.signUpForm };
		formValue[e.currentTarget.name] = e.currentTarget.value;
		this.setState({ signUpForm: formValue });
	};

	schema = {
		phoneNumber: Joi.string()
			.min(11)
			.max(11)
			.required()
			.label("Phone Number")
			.error((errors) => {
				errors.forEach((err) => {
					switch (err.type) {
						case "any.empty":
							err.message = "Please enter your phone number";
							break;
						case "string.min":
							err.message = `Please enter 11 digits for your phone number`;
							break;
						case "string.max":
							err.message = `Please enter 11 digits for your phone number`;
							break;
						default:
							break;
					}
				});
				return errors;
			}),
		referralCode: Joi.string()
			.allow(null, "")
			.label("Referral Code")
			.error((errors) => {
				errors.forEach((err) => {
					/*
                console.log(err);
                switch (err.type) {
                    case "any.empty":
                        err.message = "Please enter your referral code";
                        break;
                    default:
                        break;
                }
                */
				});
				return errors;
			}),
	};

	validate = () => {
		const options = { abortEarly: false };
		const result = Joi.validate(this.state.signUpForm, this.schema, options);
		// console.log(result);
		const error = result.error;
		if (!error) return null;

		const errors = {};
		for (let item of result.error.details) {
			errors[item.path[0]] = item.message;
		}
		return errors;
	};

	submitForm = (e) => {
		e.preventDefault();
		const errors = this.validate();
		this.setState({ errors: errors || {} });
		if (errors) {
			// console.log(errors);
			for (var x in errors) {
				toast.error(errors[x]);
			}
			return;
		} else {
			// alert('all good');
			// console.log(this.state.signUpForm);

			this.props.sendActivationPin(this.state.signUpForm);

			this.unsubscribe = store.subscribe(() => {
				if (this.props.sendActivationPinLoading === false) {
					this.unsubscribe();
					const { sendActivationPinResponse } = this.props;
					if (sendActivationPinResponse.message === "Account not Found") {
						this.props.history.push("/get-started/null");
					} else {
						this.props.saveSignUpDetails(this.state.signUpForm.phoneNumber);
					}
				}
			});
			this.setState({ storeUnsubscribe: this.unsubscribe });
		}
	};

	componentDidMount() {
		if ("referralCode" in this.props.match.params) {
			const { referralCode } = this.props.match.params;
			const lockReferralCode = true;
			const signUpForm = {
				...this.state.signUpForm,
				referralCode,
			};
			this.setState({ signUpForm, lockReferralCode });
		}

		this.unsubscribe = store.subscribe(() => {
			if (this.props.signUpPhoneNumberVerfied === true) {
				this.props.resetSendActivationPin();
				this.props.history.push("/get-started/verify-phone");
			}
		});
		this.setState({ storeUnsubscribe: this.unsubscribe });
		// this.props.resetSignup()
	}

	componentWillUnmount() {
		const storeUnsubscribe = this.state.storeUnsubscribe;
		if (
			storeUnsubscribe !== "" &&
			storeUnsubscribe !== undefined &&
			storeUnsubscribe !== null
		) {
			storeUnsubscribe();
		}
	}

	render() {
		// const { signUpForm, lockReferralCode } = this.state;

		return (
			<div className="w-full p-8 sm:p-14 md:my-auto">
				<DynamicHeadTag headerText="Get Started" />

				<div className="pb-14 max-w-lg mx-auto">
					<div className="text-center">
						<Link to="/" className=" my-8 h-8 sm:h-10 flex justify-center animate hover:scale-110 ">
							<img src={logo} alt="EarniPay logo" className="h-full object-contain" />
						</Link>
						<div className="font-recoleta font-semibold text-2xl sm:text-3xl text-center ">
							Join Earnipay
						</div>
						<div className="mt-2 text-base">
							Signup on the Earnipay App
						</div>
					</div>

					<div className="mt-10 w-full max-w-lg mx-auto bg-white p-10 rounded-md">

						<div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
							<div className="h-12">
								<img src={appStore} className="object-contain" alt="google playstore" />
							</div>
							<div className="h-12">
								<img src={playStore} className="object-contain" alt="app store" />
							</div>
						</div>

						{/* 
						<form onSubmit={this.submitForm}>
							<div className="form-group">
								<label>
									Phone Number
									<span className="form-input-required">*</span>
								</label>
								<input
									type="tel"
									name="phoneNumber"
									className="form-input"
									value={signUpForm.phoneNumber}
									onChange={this.formChange}
									required
								/>
							</div>

							<div className="form-group hidden">
								<label>
									Referral Code
									{!lockReferralCode && (
										<span className="form-input-required">(Optional)</span>
									)}
								</label>
								<input
									type="text"
									name="referralCode"
									placeholder="EXXXXXXXXXXX"
									className="form-input"
									value={signUpForm.referralCode}
									onChange={this.formChange}
									readOnly={lockReferralCode}
								/>
							</div>
								
							<button
								type="submit"
								className="btn btn-lg btn-block btn-ep-blue mt-4"
								disabled={this.props.sendActivationPinLoading}>
								<FontAwesomeIcon
									icon="spinner"
									spin
									className={
										"text-xl mr-2 " +
										(this.props.sendActivationPinLoading
											? "inline"
											: "hidden")
									}
								/>
								Continue
								<FontAwesomeIcon
									icon="angle-right"
									className="text-xl ml-2 inline"
								/>
							</button>

							<div className="mt-3 text-sm font-semibold text-gray-500">
								Already have an account? &nbsp;
								<Link to="/login" className="text-ep-blue hover:underline">
									Login
								</Link>
							</div>

							<div
								className="mt-6 text-gray-500 hidden"
								style={{ fontSize: "10px" }}>
								Earnipay respects your privacy and is committed to protecting
								your Personal Data. We are transparent with you about how we
								collect and use your Personal Data to provide you access to
								your earned salaries via our platform. For more information
								check out our Privacy Policy.
							</div>
						</form>
						*/}
					</div>
					
					<div className="mt-3 text-sm text-center font-semibold text-gray-500">
						Already have an account? &nbsp;
						<Link to="/login" className="text-ep-blue hover:underline">
							Login
						</Link>
					</div>

				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	sendActivationPinLoading: state.auth.user.sendActivationPinLoading,
	sendActivationPinResponse: state.auth.user.sendActivationPinResponse,
	signingUp: state.auth.userPersist.signingUp,
	signUpPhoneNumberVerfied: state.auth.userPersist.signUpPhoneNumberVerfied,
});

const mapDispatchToProps = (dispatch) => ({
	sendActivationPin: (params) => dispatch(auth.sendActivationPin(params)),
	saveSignUpDetails: (phoneNumber) =>
		dispatch(auth.saveSignUpDetails(phoneNumber)),
	resetSendActivationPin: () => dispatch(auth.resetSendActivationPin()),
	// resetSignup: () => dispatch(auth.resetSignUp()),
});

export default connect(mapStateToProps, mapDispatchToProps)(GetStarted2);
