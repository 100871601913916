import * as actions from "./actionTypes";
import * as config from "config";

export function getAllWithdrawals(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetAllWithdrawals,
            method: "post",
            data: params,
            onStart: actions.GET_ALL_WITHDRAWALS_START,
            onSuccess: actions.GET_ALL_WITHDRAWALS,
            onError: actions.GET_ALL_WITHDRAWALS_FAILED,
        },
    }
};
export function getAllWithdrawalsSidebar(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetAllWithdrawals,
            method: "post",
            data: params,
            onStart: actions.GET_ALL_WITHDRAWALSIDEBAR_START,
            onSuccess: actions.GET_ALL_WITHDRAWALSIDEBAR,
            onError: actions.GET_ALL_WITHDRAWALSIDEBAR_FAILED,
        },
    }
};
export function next(amount, purpose='') {
    return {
        type: actions.NEXT,
        payload: {
            amount,
            purpose,
        }
    }
};

export function prev() {
    return {
        type: actions.PREV,
    }
};

// export function clearFormWithdrawal() {
//     return {
//         type: actions.PREV,
//     }
// };

export function resetWithdrawalForm() {
    return {
        // type: actions.PREV,
        type: actions.ZERO,
    }
};


export function resetReloadPage() {
    return {
        type: actions.RELOAD_PAGE_RESET,
        payload: {},
    }
};


// autopayment schedule
export const getAllWithdrawalSchedule = (params) => {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetPaymentSchedules,
            method: "get",
            data: params,
            onStart: actions.GET_ALL_PAYMENT_SCHEDULE_START,
            onSuccess: actions.GET_ALL_PAYMENT_SCHEDULE,
            onError: actions.GET_ALL_PAYMENT_SCHEDULE_FAILED,
        },
    }
};

export const getWithdrawalSchedule = (id) => {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiGetPaymentSchedules}/${id}`,
            method: "get",
            schedule: {},
            onStart: actions.GET_PAYMENT_SCHEDULE_START,
            onSuccess: actions.GET_PAYMENT_SCHEDULE,
            onError: actions.GET_PAYMENT_SCHEDULE_FAILED,
        },
    }
};

export const createWithdrawalSchedule = (params) => {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiCreatePaymentSchedule,
            method: "post",
            data: params,
            onStart: actions.CREATE_PAYMENT_SCHEDULE_START,
            onSuccess: actions.CREATE_PAYMENT_SCHEDULE,
            onError: actions.CREATE_PAYMENT_SCHEDULE_FAILED,
        },
    }
};

export const activateAutopay = () => {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiOnboardAutopay,
            method: "post",
            data: {
                autoPaySetup: true
            },
            onStart: actions.ACTIVATE_AUTOPAY_START,
            onSuccess: actions.ACTIVATE_AUTOPAY,
            onError: actions.ACTIVATE_AUTOPAY_FAILED,
        },
    }
};

export const updateWithdrawalSchedule = (params, id) => {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiCreatePaymentSchedule}/${id}`,
            method: "put",
            data: params,
            onStart: actions.UPDATE_PAYMENT_SCHEDULE_START,
            onSuccess: actions.UPDATE_PAYMENT_SCHEDULE,
            onError: actions.UPDATE_PAYMENT_SCHEDULE_FAILED,
        },
    }
};

export const deleteWithdrawalSchedule = (id, params) => {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiGetPaymentSchedules}/${id}`,
            method: "delete",
            data: params,
            onStart: actions.DELETE_PAYMENT_SCHEDULE_START,
            onSuccess: actions.DELETE_PAYMENT_SCHEDULE,
            onError: actions.DELETE_PAYMENT_SCHEDULE_FAILED,
        },
    }
};
// :id/cancel-fee
export const cancelAutopay = (id) => {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.cancelAutopayFee}/${id}/cancel-fee`,
            method: "get",
            onStart: actions.CANCEL_AUTOPAY_START,
            onSuccess: actions.CANCEL_AUTOPAY,
            onError: actions.CANCEL_AUTOPAY_FAILED,
        },
    }
};
