/* eslint-disable no-duplicate-case */
import * as actions from "./actionTypes";
import { toast } from 'react-toastify';

const initialState = {
    loadingPosts: false,
    loadingPost: false,
    loadingArticle: false,
    loadingCards: false,
    loadingPortfolio: false,
    loadingCategories: false,
    loadingOnboardingQuestions: false,
    loadingArticleQuestions: false,
    loadingTermsOfTheDay: false,
    loadingCourses: false,
    loadingProfileCourse: false,
    loadingProfileCourseUpdate: false,
    loadingCourse: false,
    loadingCourseProgress: false,
    loadingArticlesOfCourse: false,
    loadingPortfolioCourses: false,
    loadingEnrolledCourses: false,
    loadingLikeCourse: false,
    successLikeCourse: false,
    loadingViewCourse: false,
    successViewCourse: false,
    loadingUnLikeCourse: false,
    successUnLikeCourse:false,
    loadingLikeArticle: false,
    successLikeArticle: false,
    loadingViewArticle: false,
    loadingArticleTestResult: false,
    successViewArticle: false,
    loadingUnLikeArticle: false,
    successUnLikeArticle:false,
    posts: {},
    // CARD
    loadingLikeCard: false,
    likedCard: {},
    loadingUnLikeCard: false,
    unLikedCard: {},
    loadingBookmarkCard: false,
    bookmarkedCard: {},
    loadingUnBookmarkCard: false,
    unBookmarkedCard: {},
    // CARD END
    // onboarding quiz test
    loadingQuizResult: false,
    quizResult: {},
    articleTestResult: {},
    profileCourse: {},
    profileCourseUpdate: {},
    successMessage: '',
    articlesOfCourse: {},
    portfolioCourses: {},
    categories: {},
    post: {},
    courseProgress: {},
    questions: {},
    article: {},
    likedArticle: {},
    viewedArticle: {},
    progressedArticle: {},
    loadingArticleProgress: false,
    cards: {},
    myCourses: {},
    courseSubscribed: {},
    loadingCourseSubscribed: false,
    termsOfTheDay: {},
    courses: {},
    course: {},
    coursesInPorfolio: {},
    portfolio: {},
    enrolledCourses: {},
    isEnrolled: false,
    loadingPortfolioEnroll: false,
    loadingMyCourses: false,
    portfolioEnrolled: {},
    loadingCourseEnroll: false,
    courseEnrolled: {},
    articlesWithProgress: {},
    loadingArticlesWithProgress: false,
    loadingCoursesWithProgress: false,
    coursesWithProgress: {},
    isSuccessfulGetOnboardingQuestions: false,
    isSuccessfulGetArticleQuestions: false,
    isSuccessfulGetTermsOfTheDay: false,
    isSuccessfulGetPosts: false,
    isSuccessfulGetPost: false,
    isSuccessfulGetPortfolio: false,
    isSuccessfulGetArticle: false,
    isSuccessfulGetCards: false,
    isSuccessfulGetArticlesOfCourse: false,
    isSuccessfulGetCourses: false,
    isSuccessfulGetCourse: false,
    isSuccessfulGetPortfolioCourses: false,
    isSuccessfulGetEnrolledCourses: false,
    isSuccessfulGetCategories: false,
    reloadPage: false,
};


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.GET_ALL_POSTS_START:
            state = {
                ...state,
                loadingPosts: true,
            }
            return state;

        case actions.GET_ALL_POSTS_SUCCESS:
            state = {
                ...state,
                loadingPosts: false,
                isSuccessfulGetPosts: true,
                posts: {...action.payload}
            }
            return state;

        case actions.GET_ALL_POSTS_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loading: false,
                posts: {},
            }
            return state;

        case actions.GET_ALL_CATEGORIES_START:
            state = {
                ...state,
                loadingCategories: true,
            }
            return state;

        case actions.GET_ALL_CATEGORIES_SUCCESS:
            state = {
                ...state,
                loadingCategories: false,
                isSuccessfulGetCategories: true,
                categories: {...action.payload}
            }
            return state;

        case actions.GET_ALL_CATEGORIES_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loading: false,
                categories: {},
            }
            return state;
    
        case actions.GET_POST_START:
            state = {
                ...state,
                loadingPost: true,
            }
            return state;

        case actions.GET_POST_SUCCESS:
            state = {
                ...state,
                loadingPost: false,
                isSuccessfulGetPost: true,
                post: {...action.payload}
            }
            // console.log(action.payload.data);
            return state;

        case actions.GET_POST_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loading: false,
                post: {},
            }
            return state;

        //new financial education
        //onboarding questions
        case actions.GET_ONBOARDING_QUESTION_START:
                state = {
                    ...state,
                    loadingOnboardingQuestions: true,
                }
                return state;
    
        case actions.GET_ONBOARDING_QUESTION_SUCCESS:
                state = {
                    ...state,
                    loadingOnboardingQuestions: false,
                    isSuccessfulGetOnboardingQuestions: true,
                    questions: {...action.payload}
                }
                return state;
    
        case actions.GET_ONBOARDING_QUESTION_FAILED:
                if (action.payload.message === "Validation Errors") {
                    const payloadErrors = action.payload.errors;
                    Object.keys(payloadErrors).forEach(function (key) {
                        for (let i = 0; i < payloadErrors[key].length; i++) {
                            toast.error(payloadErrors[key][i]);
                        }
                    });
                }
                else {
                    toast.error(action.payload.message);
                }
    
                state = {
                    ...state,
                    loadingOnboardingQuestions: false,
                    questions: {},
                }
                return state;
        
        //article questions
        case actions.GET_ARTICLE_QUESTION_START:
        state = {
            ...state,
            loadingArticleQuestions: true,
        }
        return state;
    
        case actions.GET_ARTICLE_QUESTION_SUCCESS:
                state = {
                    ...state,
                    loadingArticleQuestions: false,
                    isSuccessfulGetArticleQuestions: true,
                    questions: {...action.payload}
                }
                return state;
    
        case actions.GET_ARTICLE_QUESTION_FAILED:
                if (action.payload.message === "Validation Errors") {
                    const payloadErrors = action.payload.errors;
                    Object.keys(payloadErrors).forEach(function (key) {
                        for (let i = 0; i < payloadErrors[key].length; i++) {
                            toast.error(payloadErrors[key][i]);
                        }
                    });
                }
                else {
                    toast.error(action.payload.message);
                }
    
                state = {
                    ...state,
                    loadingArticleQuestions: false,
                    questions: {},
                }
                return state;
        // terms of the day
        case actions.GET_TERMS_OF_THE_DAY_START:
                state = {
                    ...state,
                    loadingTermsOfTheDay: true,
                }
                return state;
    
        case actions.GET_TERMS_OF_THE_DAY_SUCCESS:
                state = {
                    ...state,
                    loadingTermsOfTheDay: false,
                    isSuccessfulGetTermsOfTheDay: true,
                    termsOfTheDay: {...action.payload}
                }
                return state;
    
        case actions.GET_TERMS_OF_THE_DAY_FAILED:
                if (action.payload.message === "Validation Errors") {
                    const payloadErrors = action.payload.errors;
                    Object.keys(payloadErrors).forEach(function (key) {
                        for (let i = 0; i < payloadErrors[key].length; i++) {
                            toast.error(payloadErrors[key][i]);
                        }
                    });
                }
                else {
                    toast.error(action.payload.message);
                }
    
                state = {
                    ...state,
                    loadingTermsOfTheDay: false,
                    termsOfTheDay: {},
                }
                return state;

        // Progress arrticle in course
        case actions.GET_ARTICLE_PROGRESS_IN_COURSE_START:
                state = {
                    ...state,
                    loadingArticlesWithProgress: true,
                }
                return state;
    
        case actions.GET_ARTICLE_PROGRESS_IN_COURSE_SUCCESS:
                state = {
                    ...state,
                    loadingArticlesWithProgress: false,
                    articlesWithProgress: {...action.payload}
                }
                return state;
    
        case actions.GET_ARTICLE_PROGRESS_IN_COURSE_FAILED:
                if (action.payload.message === "Validation Errors") {
                    const payloadErrors = action.payload.errors;
                    Object.keys(payloadErrors).forEach(function (key) {
                        for (let i = 0; i < payloadErrors[key].length; i++) {
                            toast.error(payloadErrors[key][i]);
                        }
                    });
                }
                else {
                    toast.error(action.payload.message);
                }
    
                state = {
                    ...state,
                    loadingArticlesWithProgress: false,
                    articlesWithProgress: {},
                }
                return state;
        
        // get all courses
        case actions.GET_ALL_COURSES_START:
                state = {
                    ...state,
                    loadingCourses: true,
                }
                return state;
    
        case actions.GET_ALL_COURSES_SUCCESS:
                state = {
                    ...state,
                    loadingCourses: false,
                    isSuccessfulGetCourses: true,
                    courses: {...action.payload}
                }
                return state;
    
        case actions.GET_ALL_COURSES_FAILED:
                if (action.payload.message === "Validation Errors") {
                    const payloadErrors = action.payload.errors;
                    Object.keys(payloadErrors).forEach(function (key) {
                        for (let i = 0; i < payloadErrors[key].length; i++) {
                            toast.error(payloadErrors[key][i]);
                        }
                    });
                }
                else {
                    toast.error(action.payload.message);
                }
    
                state = {
                    ...state,
                    loadingCourses: false,
                    courses: {},
                }
                return state; 

        // get  course
        case actions.GET_COURSE_START:
            state = {
                ...state,
                loadingCourse: true,
            }
            return state;

        case actions.GET_COURSE_SUCCESS:
                state = {
                    ...state,
                    loadingCourse: false,
                    isSuccessfulGetCourse: true,
                    course: {...action.payload}
                }
                return state;

        case actions.GET_COURSE_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loadingCourse: false,
                course: {},
            }
            return state; 
            
        // GET all-enrolled-courses
        case actions.GET_ALL_ENROLLED_COURSES_START:
            state = {
                ...state,
                loadingEnrolledCourses: true,
            }
            return state;

        case actions.GET_ALL_ENROLLED_COURSES_SUCCESS:
                state = {
                    ...state,
                    loadingEnrolledCourses: false,
                    isSuccessfulGetEnrolledCourses: true,
                    enrolledCourses: {...action.payload}
                }
                return state;

        case actions.GET_ALL_ENROLLED_COURSES_FAILED:
                if (action.payload.message === "Validation Errors") {
                    const payloadErrors = action.payload.errors;
                    Object.keys(payloadErrors).forEach(function (key) {
                        for (let i = 0; i < payloadErrors[key].length; i++) {
                            toast.error(payloadErrors[key][i]);
                        }
                    });
                }
                else {
                    toast.error(action.payload.message);
                }

                state = {
                    ...state,
                    loadingEnrolledCourses: false,
                    enrolledCourses: {},
                }
                return state; 
        
        // GET-courses-progress
        case actions.GET_COURSES_PROGRESS_START:
            state = {
                ...state,
                loadingEnrolledCourses: true,
            }
            return state;

        case actions.GET_COURSES_PROGRESS_SUCCESS:
                state = {
                    ...state,
                    loadingEnrolledCourses: false,
                    isSuccessfulGetEnrolledCourses: true,
                    enrolledCourses: {...action.payload}
                }
                return state;

        case actions.GET_COURSES_PROGRESS_FAILED:
                if (action.payload.message === "Validation Errors") {
                    const payloadErrors = action.payload.errors;
                    Object.keys(payloadErrors).forEach(function (key) {
                        for (let i = 0; i < payloadErrors[key].length; i++) {
                            toast.error(payloadErrors[key][i]);
                        }
                    });
                }
                else {
                    toast.error(action.payload.message);
                }

                state = {
                    ...state,
                    loadingEnrolledCourses: false,
                    enrolledCourses: {},
                }
                return state; 
        // GET PORTFOLIO COURSES
        case actions.GET_PORTFOLIO_COURSES_START:
            state = {
                ...state,
                loadingPortfolioCourses: true,
            }
            return state;

        case actions.GET_PORTFOLIO_COURSES_SUCCESS:
                state = {
                    ...state,
                    loadingPortfolioCourses: false,
                    isSuccessfulGetPortfolioCourses: true,
                    portfolioCourses: {...action.payload}
                }
                return state;

        case actions.GET_PORTFOLIO_COURSES_FAILED:
                        if (action.payload.message === "Validation Errors") {
                            const payloadErrors = action.payload.errors;
                            Object.keys(payloadErrors).forEach(function (key) {
                                for (let i = 0; i < payloadErrors[key].length; i++) {
                                    toast.error(payloadErrors[key][i]);
                                }
                            });
                        }
                        else {
                            toast.error(action.payload.message);
                        }
        
                        state = {
                            ...state,
                            loadingEnrolledCourses: false,
                            portfolioCourses: {},
                        }
                        return state; 
        // GET ARTICLES BY COURSE ID
        case actions.GET_ARTICLES_BY_COURSEID_START:
            state = {
                ...state,
                loadingArticlesOfCourse: true,
            }
            return state;

        case actions.GET_ARTICLES_BY_COURSEID_SUCCESS:
                state = {
                    ...state,
                    loadingArticlesOfCourse: false,
                    isSuccessfulGetArticlesOfCourse: true,
                    articlesOfCourse: {...action.payload}
                }
                return state;

        case actions.GET_ARTICLES_BY_COURSEID_FAILED:
                        if (action.payload.message === "Validation Errors") {
                            const payloadErrors = action.payload.errors;
                            Object.keys(payloadErrors).forEach(function (key) {
                                for (let i = 0; i < payloadErrors[key].length; i++) {
                                    toast.error(payloadErrors[key][i]);
                                }
                            });
                        }
                        else {
                            toast.error(action.payload.message);
                        }
        
                        state = {
                            ...state,
                            loadingArticlesOfCourse: false,
                            articlesOfCourse: {},
                        }
                        return state; 
        
        // GET ARTICLE DETAILS
        case actions.GET_ARTICLE_START:
            state = {
                ...state,
                loadingArticle: true,
            }
            return state;

        case actions.GET_ARTICLE_SUCCESS:
                state = {
                    ...state,
                    loadingArticle: false,
                    isSuccessfulGetArticle: true,
                    article: {...action.payload}
                }
                return state;

        case actions.GET_ARTICLE_FAILED:
                        if (action.payload.message === "Validation Errors") {
                            const payloadErrors = action.payload.errors;
                            Object.keys(payloadErrors).forEach(function (key) {
                                for (let i = 0; i < payloadErrors[key].length; i++) {
                                    toast.error(payloadErrors[key][i]);
                                }
                            });
                        }
                        else {
                            toast.error(action.payload.message);
                        }
        
                        state = {
                            ...state,
                            loadingArticle: false,
                            article: {},
                        }
                        return state; 
        
         // GET CARDS BY ARTICLE ID
         case actions.GET_CARDS_START:
            state = {
                ...state,
                loadingCards: true,
            }
            return state;

        case actions.GET_CARDS_SUCCESS:
                state = {
                    ...state,
                    loadingCards: false,
                    isSuccessfulGetCards: true,
                    cards: {...action.payload}
                }
                return state;

        case actions.GET_CARDS_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loadingCards: false,
                cards: {},
            }
            return state; 
                  
        // GET PORTFOLIO DETAILS
        case actions.GET_PORTFOLIO_START:
            state = {
                ...state,
                loadingPortfolio: true,
            }
            return state;

        case actions.GET_PORTFOLIO_SUCCESS:
                state = {
                    ...state,
                    loadingPortfolio: false,
                    isSuccessfulGetPortfolio: true,
                    portfolio: {...action.payload}
                }
                return state;

        case actions.GET_PORTFOLIO_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loadingPortfolio: false,
                portfolio: {},
            }
            return state; 

        // GET COURSES BY PORTFOLIO ID
        case actions.GET_PORTFOLIO_START:
            state = {
                ...state,
                loadingPortfolio: true,
            }
            return state;

        case actions.GET_PORTFOLIO_SUCCESS:
                state = {
                    ...state,
                    loadingPortfolio: false,
                    isSuccessfulGetPortfolio: true,
                    coursesInPorfolio: {...action.payload}
                }
                return state;

        case actions.GET_PORTFOLIO_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loadingPortfolio: false,
                coursesInPorfolio: {},
            }
            return state; 

        // Like a course
        case actions.LIKE_START:
            state = {
                ...state,
                loadingLikeCourse: true,
            }
            return state;

        case actions.LIKE_SUCCESS:
            state = {
                ...state,
                loadingLikeCourse: false,
                successLikeCourse: true,
                course: {...action.payload}
            }
            return state;

        case actions.LIKE_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loadingLikeCourse: false,
                course: {},
            }
            return state; 
        
        // VIEW a course
        case actions.VIEW_START:
        state = {
            ...state,
            loadingViewCourse: true,
        }
        return state;

        case actions.VIEW_SUCCESS:
            state = {
                ...state,
                loadingViewCourse: false,
                successViewCourse: true,
            }
            return state;

        case actions.VIEW_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loadingViewCourse: false,
            }
            return state; 
        
        // UnLike a course
        case actions.UNLIKE_START:
            state = {
                ...state,
                loadingUnLikeCourse: true,
            }
            return state;
        case actions.UNLIKE_SUCCESS:
            state = {
                ...state,
                loadingUnLikeCourse: false,
                successUnLikeCourse: true,
                course: {...action.payload}
            }
            return state;
        case actions.UNLIKE_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }
            state = {
                ...state,
                loadingUnLikeCourse: false,
                course: {},
            }
            return state; 

        // like an article
        case actions.LIKE_ARTICLE_START:
            state = {
                ...state,
                loadingLikeArticle: true,
            }
            return state;

        case actions.LIKE_ARTICLE_SUCCESS:
            state = {
                ...state,
                loadingLikeArticle: false,
                successLikeArticle: true,
                likedArticle: {...action.payload},
            }
            return state;

        case actions.LIKE_ARTICLE_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loadingLikeArticle: false,
                likedArticle: {},
            }
            return state;
        
        // view an article
        case actions.VIEW_ARTICLE_START:
            state = {
                ...state,
                loadingViewArticle: true,
            }
            return state;

        case actions.VIEW_ARTICLE_SUCCESS:
            state = {
                ...state,
                loadingViewArticle: false,
                viewedArticle: {...action.payload},
            }
            return state;

        case actions.VIEW_ARTICLE_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loadingViewArticle: false,
                viewedArticle: {},
            }
            return state;
        
        // unlike article
        case actions.UNLIKE_ARTICLE_START:
            state = {
                ...state,
                loadingUnLikeArticle: true,
            }
            return state;

        case actions.UNLIKE_ARTICLE_SUCCESS:
            state = {
                ...state,
                loadingUnLikeArticle: false,
                successUnLikeArticle: true,
                likedArticle: {...action.payload}
            }
            return state;

        case actions.UNLIKE_ARTICLE_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loadingUnLikeArticle: false,
                likedArticle: {},
            }
            return state;
    
        
        // post and get article progress
        case actions.ARTICLE_PROGRESS_START:
            state = {
                ...state,
                loadingArticleProgress: true,
            }
            return state;

        case actions.ARTICLE_PROGRESS_SUCCESS:
            state = {
                ...state,
                loadingArticleProgress: false,
                progressedArticle: {...action.payload.data}
            }
            return state;

        case actions.ARTICLE_PROGRESS_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loadingArticleProgress: false,
                progressedArticle: {},
            }
            return state;
    
        // get course profile status
        case actions.GET_COURSE_STATUS_START:
            state = {
                ...state,
                loadingProfileCourse: true,
            }
            return state;

        case actions.GET_COURSE_STATUS_SUCCESS:
            state = {
                ...state,
                loadingProfileCourse: false,
                profileCourse: {...action.payload}
            }
            return state;

        case actions.GET_COURSE_STATUS_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loadingProfileCourse: false,
                profileCourse: {},
            }
            return state;

        // update onboarding course profile status
        case actions.UPDATE_COURSE_ONBOARDING_STATUS_START:
            state = {
                ...state,
                loadingProfileCourseUpdate: true,
            }
            return state;

        case actions.UPDATE_COURSE_ONBOARDING_STATUS_SUCCESS:
            state = {
                ...state,
                loadingProfileCourseUpdate: false,
                profileCourseUpdate: {...action.payload}
            }
            return state;

        case actions.UPDATE_COURSE_ONBOARDING_STATUS_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loadingProfileCourseUpdate: false,
                profileCourseUpdate: {},
            }
            return state;

        // posting course progress
        case actions.POST_COURSE_PROGRESS_START:
            state = {
                ...state,
                loadingCourseProgress: true,
            }
            return state;

        case actions.POST_COURSE_PROGRESS_SUCCESS:
            state = {
                ...state,
                loadingCourseProgress: false,
                courseProgress: {...action.payload}
            }
            return state;

        case actions.POST_COURSE_PROGRESS_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loadingCourseProgress: false,
                courseProgress: {},
            }
            return state;

        // LIKE A CARD
        case actions.LIKE_CARD_START:
            state = {
                ...state,
                loadingLikeCard: true,
            }
            return state;

        case actions.LIKE_CARD_SUCCESS:
            state = {
                ...state,
                loadingLikeCard: false,
                likedCard: {...action.payload}
            }
            return state;

        case actions.LIKE_CARD_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loadingLikeCard: false,
                likedCard: {},
            }
            return state;

        // UNLIKE A CARD
        case actions.UNLIKE_CARD_START:
            state = {
                ...state,
                loadingUnLikeCard: true,
            }
            return state;

        case actions.UNLIKE_CARD_SUCCESS:
            state = {
                ...state,
                loadingUnLikeCard: false,
                unLikedCard: {...action.payload}
            }
            return state;

        case actions.UNLIKE_CARD_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loadingUnLikeCard: false,
                unLikedCard: {},
            }
            return state;

        // BOOKMARK A CARD
        case actions.BOOKMARK_CARD_START:
            state = {
                ...state,
                loadingBookmarkCard: true,
            }
            return state;

        case actions.BOOKMARK_CARD_SUCCESS:
            state = {
                ...state,
                loadingBookmarkCard: false,
                bookmarkedCard: {...action.payload}
            }
            return state;

        case actions.BOOKMARK_CARD_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loadingBookmarkCard: false,
                bookmarkedCard: {},
            }
            return state;

        // UNBOOKMARK A CARD
        case actions.UNBOOKMARK_CARD_START:
            state = {
                ...state,
                loadingUnBookmarkCard: true,
            }
            return state;

        case actions.UNBOOKMARK_CARD_SUCCESS:
            state = {
                ...state,
                loadingUnBookmarkCard: false,
                unBookmarkedCard: {...action.payload}
            }
            return state;

        case actions.UNBOOKMARK_CARD_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loadingUnBookmarkCard: false,
                unBookmarkedCard: {},
            }
            return state;

        // post onboarding test
        case actions.POST_ONBOARDING_QUIZ_START:
            state = {
                ...state,
                loadingQuizResult: true,
            }
            return state;

        case actions.POST_ONBOARDING_QUIZ_SUCCESS:
            state = {
                ...state,
                loadingQuizResult: false,
                quizResult: {...action.payload}
            }
            return state;

        case actions.POST_ONBOARDING_QUIZ_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loadingQuizResult: false,
                quizResult: {},
            }
            return state;
       
        
        // record article test result
        case actions.RECORD_ARTICLE_TEST_START:
            state = {
                ...state,
                loadingArticleTestResult: true,
            }
            return state;

        case actions.RECORD_ARTICLE_TEST_SUCCESS:
            state = {
                ...state,
                loadingArticleTestResult: false,
                articleTestResult: {...action.payload}
            }
            return state;

        case actions.RECORD_ARTICLE_TEST_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loadingArticleTestResult: false,
                articleTestResult: {},
            }
            return state;

        // enroll portfolio
        case actions.ENROLL_PORTFOLIO_START:
            state = {
                ...state,
                loadingPortfolioEnroll: true,
            }
            return state;

        case actions.ENROLL_PORTFOLIO_SUCCESS:
            state = {
                ...state,
                loadingPortfolioEnroll: false,
                portfolioEnrolled: {...action.payload}
            }
            return state;

        case actions.ENROLL_PORTFOLIO_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loadingPortfolioEnroll: false,
                portfolioEnrolled: {},
            }
            return state;

        // enroll course
        case actions.ENROLL_COURSE_START:
            state = {
                ...state,
                loadingCourseEnroll: true,
                isEnrolled: false,
            }
            return state;

        case actions.ENROLL_COURSE_SUCCESS:
            state = {
                ...state,
                loadingCourseEnroll: false,
                courseEnrolled: { ...action.payload },
                isEnrolled: true,
            }
            return state;

        case actions.ENROLL_COURSE_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loadingCourseEnroll: false,
                courseEnrolled: {},
                isEnrolled: false,
            }
            return state;

        
        // GET courses with progress stats
        case actions.GET_COURSES_WITH_PROGRESS_START:
            state = {
                ...state,
                loadingCoursesWithProgress: true,
            }
            return state;

        case actions.GET_COURSES_WITH_PROGRESS_SUCCESS:
            state = {
                ...state,
                loadingCoursesWithProgress: false,
                coursesWithProgress: {...action.payload}
            }
            return state;

        case actions.GET_COURSES_WITH_PROGRESS_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loadingCoursesWithProgress: false,
                coursesWithProgress: {},
            }
            return state;
        // GET my courses
        case actions.GET_MY_COURSE_START:
            state = {
                ...state,
                loadingMyCourses: true,
            }
            return state;

        case actions.GET_MY_COURSE_SUCCESS:
            state = {
                ...state,
                loadingMyCourses: false,
                myCourses: {...action.payload.data}
            }
            return state;

        case actions.GET_MY_COURSE_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loadingMyCourses: false,
                myCourses: {},
            }
            return state;
        
        // GET course progress
        case actions.GET_COURSE_PROGRESS_START:
            state = {
                ...state,
                loadingCourseSubscribed: true,
                isSuccessfulSubscribed: false
            }
            return state;

        case actions.GET_COURSE_PROGRESS_SUCCESS:
            state = {
                ...state,
                loadingCourseSubscribed: false,
                courseSubscribed: { ...action.payload },
                isSuccessfulSubscribed: true
            }
            return state;

        case actions.GET_COURSE_PROGRESS_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }
            state = {
                ...state,
                loadingCourseSubscribed: false,
                courseSubscribed: {},
                isSuccessfulSubscribed: false,
            }
            return state;
        default:
            return state;
    }
}