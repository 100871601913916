import ReactGA from 'react-ga';

const googleAnalyticsTrack = () => {
    const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACK_ID;
    const url = window.location.pathname + window.location.search;
    // console.log("TRACKING_ID", TRACKING_ID);
    // console.log("url", url);

    if (TRACKING_ID !== "00000000"){
        ReactGA.initialize(TRACKING_ID);
        ReactGA.pageview(url);
    }

    return null;
};

export default googleAnalyticsTrack;
