import React, { useEffect, useMemo, useState } from "react";
import queryString from "query-string";
import { CurrencyFormat2 } from "utils/currencyFormat";
import Pagination from "components/common/pagination";
import { TimeFormat, DateFormatTable } from "utils/dateFormat";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Joi from "joi-browser";

import { getAllWithdrawals } from "store/entities/withdrawals/action";
// import { getNotifications } from "store/entities/notifications/action";

// SVGs for search and filter
import edusearch from "assets/images/icons/project-icons/edusearch.svg";
import iconFilter from "assets/images/newDesignAssets/svgs/IconFilter.svg";
import FilterTransactionModal from "./FilterTransactionModal";
import { toast } from "react-toastify";
import DynamicHeadTag from "components/common/DynamicHeadTag";
import googleAnalyticsTrack from "utils/googleAnalyticsTrack";

const List = (props) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [allWithdrawalParams, setAllWithdrawalsParams] = useState({
		startDate: "",
		endDate: "",
	});
	const [activeTab, setActiveTab] = useState("withdrawals");
	const [searchParams, setSearchParams] = useState({
		search: "",
	});
	const [filterForm, setFilterForm] = useState({
		startDate: "",
		endDate: "",
	});

	const { notifications } = useSelector((s) => s.entities.notifications);
	const memoizedNotes = useMemo(
		() => (notifications || []).filter((item) => item.isRead === false),
		[notifications]
	);
	// console.log('memoizedNotes', memoizedNotes);
	const { allWithdrawals, loading: loadingWithdrawals } = useSelector(
		(s) => s.entities.withdrawals
	);

	const handleFilterFormChange = (e) => {
		const { name, value } = e.target;
		setFilterForm({ ...filterForm, [name]: value });
	};

	const listAllWithdrawals = () => {
		const qs = queryString.parse(props.location.search);

		let allWithdrawalsParams = { ...allWithdrawalParams };

		const limit = 10;
		const page = 1;

		if (qs.startDate !== "") {
			allWithdrawalsParams = {
				...allWithdrawalsParams,
				startDate: filterForm.startDate,
			};
		} else {
			allWithdrawalsParams = { ...allWithdrawalsParams, startDate: "" };
		}
		if (qs.endDate !== "") {
			allWithdrawalsParams = {
				...allWithdrawalsParams,
				endDate: filterForm.endDate,
			};
		} else {
			allWithdrawalsParams = { ...allWithdrawalsParams, endDate: "" };
		}

		if (qs.limit !== undefined && !isNaN(qs.limit) && qs.limit > 0) {
			allWithdrawalsParams = { ...allWithdrawalsParams, limit: qs.limit };
		} else {
			allWithdrawalsParams = { ...allWithdrawalsParams, limit: limit };
		}
		if (qs.page !== undefined && !isNaN(qs.page) && qs.page > 0) {
			allWithdrawalsParams = { ...allWithdrawalsParams, page: qs.page };
		} else {
			allWithdrawalsParams = { ...allWithdrawalsParams, page: page };
		}
		if (qs.search !== undefined) {
			allWithdrawalsParams = { ...allWithdrawalsParams, keyword: qs.search };
			setShowSearchQuery(true);
		} else {
			allWithdrawalsParams = { ...allWithdrawalsParams, keyword: "" };
			setShowSearchQuery(false);
		}

		setAllWithdrawalsParams(allWithdrawalsParams);
		dispatch(getAllWithdrawals(allWithdrawalsParams));
		// dispatch(getNotifications({ limit: 0 }));
	};

	const filterTransaction = () => {
		gotoUrlQuery({ ...filterForm });
		listAllWithdrawals();
		setOpen(false);
	};

	useEffect(() => {
		googleAnalyticsTrack();
	}, []);

	useEffect(() => {
		listAllWithdrawals();
		//eslint-disable-next-line
	}, [dispatch, props]);

	const allWithdrawalData = useMemo(
		() => allWithdrawals?.withdrawals?.docs || [],
		[allWithdrawals.withdrawals]
	);

	const data = allWithdrawals?.withdrawals;

	const [, setErrors] = useState({});
	const [showSearchQuery, setShowSearchQuery] = useState("");
	const [open, setOpen] = useState(false);

	const schema = {
		search: Joi.string()
			.required()
			.label("Search")
			.error((errors) => {
				errors.forEach((err) => {
					switch (err.type) {
						case "any.empty":
							err.message = "Please enter a keyword to search";
							break;
						default:
							break;
					}
				});
				return errors;
			}),
	};

	// form validation
	const validate = () => {
		const options = { abortEarly: false, allowUnknown: false };
		const result = Joi.validate(searchParams, schema, options);
		const error = result.error;
		if (!error) return null;
		const errors = {};
		for (let item of error.details) {
			errors[item.path[0]] = item.message;
		}
		return errors;
	};
	const handleOpen = () => setOpen(!open);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setSearchParams({ ...searchParams, [name]: value });
	};

	const gotoUrlQuery = (params) => {
		const qs = queryString.parse(props.location.search);
		Object.keys(params).forEach((paramName) => {
			delete qs[paramName];
		});
		let qsToUrl = new URLSearchParams(qs).toString();
		qsToUrl =
			props.location.pathname + "?" + (qsToUrl !== "" ? qsToUrl + "&" : "");

		Object.keys(params).forEach((paramName) => {
			if (params[paramName] !== "") {
				qsToUrl += paramName + "=" + params[paramName] + "&";
			}
		});

		qsToUrl = qsToUrl.substring(0, qsToUrl.length - 1);
		props.history.push(qsToUrl);
	};

	const clearSearch = () => {
		setShowSearchQuery(false);
		setSearchParams({ ...searchParams, search: "" });
		setFilterForm({ ...filterForm, startDate: "", endDate: "" });
		setAllWithdrawalsParams({
			...allWithdrawalParams,
			startDate: "",
			endDate: "",
			keyword: "",
		});
		props.history.push("/withdrawals");
	};

	const submitForm = (e) => {
		e.preventDefault();
		const errors = validate();
		setErrors({ errors: errors || {} });
		if (errors) {
			for (var x in errors) {
				toast.error(errors[x]);
			}
			return;
		} else {
			gotoUrlQuery({ search: searchParams.search.trim() });
		}
	};
	const handleToggleNotification = () => {
		history.push("/notifications");
	};

	return (
		<div className="p-0">
			
			<DynamicHeadTag
				headerText="Withdrawals"
				headerDescription="Easily and securely withdraw your funds from your account to your preferred payment method."
			/>

			<div className="flex justify-between mb-6">
				<div className=" text-base font-semibold w-24 ">
					<Link
						to="/"
						className="my-auto inline-block btn btn-md bg-gray-300 sm:text-xs w-30"
						style={{ color: "#6B7280", borderColor: "gray" }}>
						<FontAwesomeIcon icon="angle-left" className="mr-3" />
						Back
					</Link>
				</div>
				<div className=" text-base font-semibold flex justify-end relative">
					{/* 
					<FontAwesomeIcon
						icon="bell"
						className=" w-5 h-5 inline-block my-auto cursor-pointer"
						onClick={handleToggleNotification}
					/>
					 */}
					{memoizedNotes && memoizedNotes.length > 0 && (
						<div
							className="w-6 h-6 rounded-full bg-new-ep-blue text-center text-white absolute -top-2 -right-2 cursor-pointer"
							style={{ fontSize: "8px" }}
							onClick={handleToggleNotification}>
							{memoizedNotes.length}
						</div>
					)}
				</div>
			</div>
			<div className="md:flex justify-between my-6">
				<div className="my-auto pt-2">
					<div className="font-semibold font-recoleta text-xl lg:text-2xl flex items-center ">
						Transactions History
					</div>
				</div>
				<div className="search my-auto">
					<form className="w-full" onSubmit={submitForm}>
						<div className="flex space-x-2 md:justify-between lg:justify-between md:w-full h-14 align-middle">
							<div className="relative left-2">
								<label htmlFor="search">
									<img
										src={edusearch}
										alt="search"
										className=" w-4 h-4 m-auto form-group-icon-attach my-auto float-left relative top-7 left-4"
									/>
								</label>
								<input
									type="search"
									name="search"
									placeholder="Keyword Search"
									className=" outline-none w-full h-10 pl-12 text-base text-fade font-sans search-border-right"
									id="tr-filt-input"
									value={searchParams.search}
									onChange={handleChange}
								/>
							</div>
							<div
								className="h-12 pt-2 pb-2 bottom-2 cursor-pointer relative top-2 flex"
								onClick={() => {
									handleOpen();
								}}>
								<div className="flex justify-between align-top space-x-2 m-auto search-border h-10 px-5 w-32 ">
									<span className="inline-block font-sans text-base text-gray-500 left-5 m-auto">
										Filter
									</span>
									<div className="w-4 h4">
										<img
											src={iconFilter}
											alt="earnipay filter"
											className="h-full w-full object-contain m-auto"
										/>
									</div>
								</div>
							</div>
						</div>
					</form>
					{/* cancel search */}
					{(showSearchQuery === true ||
						props.history.location.search !== "") && (
						<div className="mt-2 pt-2 flex">
							<div
								onClick={clearSearch}
								className="mx-auto cursor-pointer border-b-2 border-transparent hover:border-white">
								<FontAwesomeIcon icon="times" className="mr-2" />
								Cancel Search
							</div>
						</div>
					)}
				</div>
			</div>
			<div className="my-4 font-sans">
				<div
					className={`inline-block ${
						activeTab === "withdrawals" ? "tab-bg" : "bg-default"
					}`}
					onClick={() => setActiveTab("withdrawals")}>
					On-Demand Pay
				</div>
			</div>
			{activeTab === "withdrawals" && (
				<>
					<div className="mt-6">
						<div className="table-container bg-white rounded-md">
							<table className="table table-auto table-responsive table-nowrap table-border border-collapse table-align-top">
								<thead
									className="rounded"
									style={{ backgroundColor: "#D9E3FF" }}>
									<tr>
										<th>
											Amount <br /> Requested
										</th>
										<th>Transaction</th>
										<th>Transaction Fee</th>
										<th>Payment Reason</th>
										<th>Amount Paid</th>
										<th>Time</th>
										<th>Date</th>
										<th>Status</th>
									</tr>
								</thead>
								<tbody>
									{loadingWithdrawals === true && (
										<tr>
											<td colSpan="6">
												<div className="table-info">
													<FontAwesomeIcon icon="spinner" spin />
													<div className="font-medium uppercase">Loading</div>
												</div>
											</td>
										</tr>
									)}

									{loadingWithdrawals === false &&
										!allWithdrawals?.withdrawals && (
											<tr>
												<td colSpan="6">
													<div className="table-info">
														<FontAwesomeIcon icon="unlink" />
														<div className="font-medium uppercase">
															An error occurred
															<br />
															Please try again later.
														</div>
													</div>
												</td>
											</tr>
										)}

									{loadingWithdrawals === false &&
										allWithdrawals &&
										allWithdrawalData &&
										allWithdrawalData.length <= 0 && (
											<tr>
												<td colSpan="6">
													{data?.page <= data?.totalPages && (
														<div className="table-info">
															<FontAwesomeIcon icon="list" />
															<div className="font-medium uppercase">
																No withdrawals yet
															</div>
														</div>
													)}

													{data?.page > data?.totalPages && (
														<div className="table-info">
															<FontAwesomeIcon icon="exclamation-triangle" />
															<div className="font-medium uppercase">
																Invalid Page Number
															</div>
															<div className="mt-2">
																<Link
																	to="?page=1"
																	className="btn btn-ep-blue btn-sm mx-auto">
																	Goto Page 1
																</Link>
															</div>
														</div>
													)}
												</td>
											</tr>
										)}

									{loadingWithdrawals === false &&
										data &&
										allWithdrawalData.length > 0 &&
										allWithdrawalData.map((withdrawal, index) => (
											<tr
												key={index}
												className="hover:bg-gray-100 cursor-pointer border-l-0 border-r-0">
												<td>
													<div className="flex text-gray-500">
														{CurrencyFormat2(withdrawal.amountRequested)}
													</div>
												</td>
												<td>
													<div className="flex text-red-500">Earnipay</div>
												</td>
												<td>
													<div className="flex text-gray-500">
														{CurrencyFormat2(withdrawal.fee)}
													</div>
												</td>
												<td>
													<div className="flex text-red-500">
														{withdrawal.reason
															? `${withdrawal.reason}`
															: "----"}
													</div>
												</td>
												<td>
													<div className="flex text-gray-500">
														{CurrencyFormat2(withdrawal.actualPaid)}
													</div>
												</td>

												<td>
													<div className="text-gray-500">
														{TimeFormat(withdrawal.createdAt)}
													</div>
												</td>
												<td>
													<div className="flex text-gray-500">
														{DateFormatTable(withdrawal.createdAt)}
													</div>
												</td>
												<td>
													{withdrawal.status === "pending" && (
														<>
															<div className="label label-yellow uppercase">
																Pending
															</div>
															<div className="w-24 mt-2 text-xs text-center">
																Awaiting
																<br />
																employer
																<br />
																approval
															</div>
														</>
													)}
													{withdrawal.status === "paid" && (
														<div className="label label-green">Completed</div>
													)}
													{withdrawal.status === "processing" && (
														<div className="label label-green">Processing</div>
													)}
													{withdrawal.status === "approved" && (
														<div className="label label-blue">Approved</div>
													)}
													{withdrawal.status === "cut-off" && (
														<div className="label label-red">Cut-Off</div>
													)}
													{withdrawal.status === "cancelled" && (
														<div className="label label-red">Cancelled</div>
													)}
												</td>
											</tr>
										))}
								</tbody>
							</table>
						</div>
					</div>
					{loadingWithdrawals === false &&
						data &&
						allWithdrawalData &&
						allWithdrawalData.length > 0 && (
							<div className="mt-4">
								<Pagination
									data={data}
									url={props.location.search}
									limit={allWithdrawalParams.limit}
								/>
							</div>
						)}
				</>
			)}
			<FilterTransactionModal
				handleOpen={handleOpen}
				open={open}
				handleFilterFormChange={handleFilterFormChange}
				filterForm={filterForm}
				filterTransaction={filterTransaction}
				allWithdrawalsParams={allWithdrawalParams}
				submitForm={submitForm}
			/>
		</div>
	);
};

export default List;
