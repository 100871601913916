import React, { Component } from 'react';
import store from "store/store";
import Joi from "joi-browser";
import logger from "utils/logger";
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as auth from "store/auth/user/action";
import logo from "assets/images/newDesignAssets/svgs/logo.svg";

// components
import{ Steps, StepLabel } from './Step';
class GetStarted2 extends Component {
    state = { 
        signUpForm: {
            pin1: "",
            pin2: "",
            pin3: "",
            pin4: "",
        },
        errors: {},
        storeUnsubscribe: "",
    }

    
    formChange = (e) => {
        const formValue = {...this.state.signUpForm};
        formValue[e.currentTarget.name] = e.currentTarget.value;
        this.setState({signUpForm: formValue});
    }
    

    schema = {
        pin1: Joi.string().length(1).required().label("otp1").error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "any.empty":
                        err.message = "Please enter the 4-digit code sent to your phone number";
                        break;
                    case "string.length":
                        err.message = "Please enter the 4-digit code sent to your phone number";
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
        pin2: Joi.string().length(1).required().label("otp2").error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "any.empty":
                        err.message = "Please enter the 4-digit code sent to your phone number";
                        break;
                    case "string.length":
                        err.message = "Please enter the 4-digit code sent to your phone number";
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
        pin3: Joi.string().length(1).required().label("otp3").error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "any.empty":
                        err.message = "Please enter the 4-digit code sent to your phone number";
                        break;
                    case "string.length":
                        err.message = "Please enter the 4-digit code sent to your phone number";
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
        pin4: Joi.string().length(1).required().label("otp4").error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "any.empty":
                        err.message = "Please enter the 4-digit code sent to your phone number";
                        break;
                    case "string.length":
                        err.message = "Please enter the 4-digit code sent to your phone number";
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
    };


    validate = () => {
        const options = { abortEarly: false };
        const result = Joi.validate(this.state.signUpForm, this.schema, options);
        // console.log(result);
        const error = result.error;
        if (!error) return null;
    
        const errors = {};
        for (let item of result.error.details){
            errors[item.path[0]] = item.message;
        }
        return errors;
    };


    submitForm = (e) => {
        e.preventDefault();
        const errors = this.validate();
        this.setState({ errors: errors || {} });
        this.props.history.push('/get-started/confirm-pin');
        
        if (errors){
            // console.log(errors);
            for (var x in errors) {
                toast.error(errors[x]);
            };
            return;
        }
        else{
            
            // alert('all good');
            // console.log(this.state.signUpForm);
            const {otp1, otp2, otp3, otp4, } = this.state.signUpForm;
            const pin = `${otp1}${otp2}${otp3}${otp4}`;
            this.props.saveNewPin(pin);
            this.props.history.push("/get-started/confirm-new-pin");
            // const signUpForm = {
            //     phoneNumber: this.state.signUpForm.phoneNumber,
            //     activationPin: this.state.signUpForm.otp,
            //     pin: this.state.signUpForm.pin,
            //     pin_confirmation: this.state.signUpForm.confirmPin,
            // }
            // console.log(signUpForm);
            // this.props.setLoginPin(signUpForm);
        }
    }


    resendActivationPin = () => {
        toast("Resending OTP");
        this.props.sendActivationPin(this.props.signUpUser.phoneNumber);
        
        this.unsubscribe = store.subscribe(() => {
            if (this.props.sendActivationPinSuccess === true){
                toast.success("A new OTP has been sent");
                this.props.history.push("/get-started/verify-phone");
                this.props.resetSendActivationPin();
            }
        });
        this.setState({storeUnsubscribe: this.unsubscribe});
    }
    
    
    componentDidMount() {
        logger("employeeweb-accept-invite", this.props.user);
        
        if (this.props.signingUp === true){

            if (this.props.signUpPinCreated === true){
                this.props.history.push("/get-started/personal-details");
            }
            
            const newSignUpForm = {...this.state.signUpForm, phoneNumber: this.props.signUpUser.phoneNumber};
            this.setState({signUpForm: newSignUpForm});

            this.unsubscribe = store.subscribe(() => {
                if (this.props.signUpPinCreated === true){
                    this.unsubscribe();
                    logger("employeeweb-accept-invite-success", this.props.user);
                    this.props.history.push("/get-started/personal-details");
                }
            });
            this.setState({storeUnsubscribe: this.unsubscribe});

        }
        else{
            this.props.history.push("/get-started");
        }
    }


    componentWillUnmount() {
        const storeUnsubscribe = this.state.storeUnsubscribe;
        if ((storeUnsubscribe !== "") && (storeUnsubscribe !== undefined) && (storeUnsubscribe !== null)){
            storeUnsubscribe();
        }
    }


    render() { 
        const {signUpForm} = this.state;

        return (
            <div className="w-full p-8 sm:p-14 md:my-auto">
                <div className="pb-14 lg:pl-4 lg:pr-4">
                    
                        <div className=" my-8 h-8 sm:h-10 flex justify-center animate hover:scale-110 ">
                            <img src={logo} alt="EarniPay logo" className="h-full object-contain" />
                        </div>
                        <div className='max-w-lg mx-auto pb-10'>
                            <Steps />
                            <StepLabel />
                        </div>

                    <div className='w-full max-w-lg mx-auto py-8 bg-white'>
                        <form onSubmit={this.submitForm} className='w-5/6 mx-auto'>
                            <div className='mb-3 pb-5'>
                                <div className="font-recoleta font-semibold text-2xl sm:text-3xl text-center md:text-left">
                                Create your Access Pin
                                </div>
                           </div>
                            <div className=" ">
                                 <div className="form-group">
                                        <div className='flex justify-between flex-wrap'>
                                            <input type="password" name="pin1" className="form-input text-center bg-new-ep-grey" id='pin-input1' value={signUpForm.pin1}  onChange={this.formChange}/>
                                            <input type="password" name="pin2" className="form-input text-center bg-new-ep-grey" id='pin-input2' value={signUpForm.pin2} onChange={this.formChange} />
                                            <input type="password" name="pin3" className="form-input text-center bg-new-ep-grey" id='pin-input3' value={signUpForm.pin3} onChange={this.formChange} />
                                            <input type="password" name="pin4" className="form-input text-center bg-new-ep-grey" id='pin-input4' value={signUpForm.pin4} onChange={this.formChange} />
                                        </div>
                                    {/* <input type="text" name="otp" placeholder="" className="form-input" maxLength="4" value={signUpForm.otp} onChange={this.formChange} /> */}
                                </div>

                               

                                <button type="submit" className="btn btn-lg btn-block btn-ep-blue mt-6" disabled={this.props.loading}>
                                    <FontAwesomeIcon icon="spinner" spin className={"text-xl mr-2 " + (this.props.loading?"inline":"hidden")} />
                                    Create Pin
                                    <FontAwesomeIcon icon="angle-right" className="text-xl ml-2 inline" />
                                </button>
                               
                                <button type="button" onClick={this.resendActivationPin} className="btn btn-block mt-2" disabled={this.props.sendActivationPinLoading}>
                                    <FontAwesomeIcon icon="spinner" spin className={"text-xl mr-2 " + (this.props.sendActivationPinLoading?"inline":"hidden")} />
                                   <span>Not recieve OTP? </span>  <span className='text-ep-blue'>Resend OTP</span>
                                </button>

                            </div>

                        </form>
                    </div>

                </div>
            </div>
        )
    }
}



const mapStateToProps = (state) => ({
    loading: state.auth.user.loading,
    user: state.auth.userPersist.data,
    sendActivationPinLoading: state.auth.user.sendActivationPinLoading,
    sendActivationPinSuccess: state.auth.user.sendActivationPinSuccess,
    signingUp: state.auth.userPersist.signingUp,
    signUpUser: state.auth.userPersist.signUpUser,
    signUpPinCreated: state.auth.userPersist.signUpPinCreated,
});

const mapDispatchToProps = (dispatch) => ({
    setLoginPin: (signUpForm) => dispatch(auth.setLoginPin(signUpForm)),
    sendActivationPin: (phoneNumber) => dispatch(auth.sendActivationPin(phoneNumber)),
    resetSendActivationPin: () => dispatch(auth.resetSendActivationPin()),
    saveNewPin: (pin) => dispatch(auth.saveNewPin(pin)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GetStarted2);