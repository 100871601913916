import React, { useState } from 'react';
import Joi from "joi-browser";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from "assets/images/newDesignAssets/svgs/logo.svg";
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// actions 
import { saveActivationOtp, validateActivationPin } from 'store/auth/user/action';
// import { saveActivationOtp, validateActivationPin, sendActivationPin } from 'store/auth/user/action';
import { ProgressTracker, Steps } from 'utils/ProgressTracker';

const VerifySignUpPin = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [activatePinForm, setActivatePinForm] = useState({
        otp1: "",
        otp2: "",
        otp3: "",
        otp4: "",
    });
    const [bgCol, setBgCol] = useState({
        box1: "#F3F4F6",
        box2: "#F3F4F6",
        box3: "#F3F4F6",
        box4: "#F3F4F6",

    });

    // const  handleSetBgCol = () => {
    //     const { otp1, otp2, otp3, otp4 } = activatePinForm;
    //     const newBgCol = { ...bgCol };
    //     newBgCol.otp1 = otp1 ? "#000" : '';
    //     newBgCol.otp2 = otp2 ? "#000" : '';
    //     newBgCol.otp3 = otp3 ? "#000" : '';
    //     newBgCol.otp4 = otp4 ? "#000" : '';
    //     setBgCol({...newBgCol});
    // }
    const [, setErrors] = useState({});
    const current = 0
    // const { loading } = useSelector(state => state.auth.user);
    const { validateActivationPinLoading, validateActivationPinSuccess, validatedPinDetails } = useSelector(state => state.auth.user);
    const { signUpUser } = useSelector(state => state.auth.userPersist);

    const { phoneNumber } = signUpUser;

    const formChange = (e) => {
        setActivatePinForm({ ...activatePinForm, [e.currentTarget.name]: e.currentTarget.value });

    };
    const schema = {
        otp1: Joi.string().length(1).required().label("otp1").error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "any.empty":
                        err.message = "Please enter the 4-digit code sent to your phone number";
                        break;
                    case "string.length":
                        err.message = "Please enter the 4-digit code sent to your phone number";
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
        otp2: Joi.string().length(1).required().label("otp2").error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "any.empty":
                        err.message = "Please enter the 4-digit code sent to your phone number";
                        break;
                    case "string.length":
                        err.message = "Please enter the 4-digit code sent to your phone number";
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
        otp3: Joi.string().length(1).required().label("otp3").error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "any.empty":
                        err.message = "Please enter the 4-digit code sent to your phone number";
                        break;
                    case "string.length":
                        err.message = "Please enter the 4-digit code sent to your phone number";
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
        otp4: Joi.string().length(1).required().label("otp4").error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "any.empty":
                        err.message = "Please enter the 4-digit code sent to your phone number";
                        break;
                    case "string.length":
                        err.message = "Please enter the 4-digit code sent to your phone number";
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
    };

    const validate = () => {
        const options = { abortEarly: false };
        const result = Joi.validate(activatePinForm, schema, options);
        // console.log(result);
        const error = result.error;
        if (!error) return null;

        const errors = {};
        for (let item of result.error.details) {
            errors[item.path[0]] = item.message;
        };
        return errors;
    };

    const submitForm = (e) => {
        e.preventDefault();
        const { otp1, otp2, otp3, otp4, } = activatePinForm;
        const otp = `${otp1}${otp2}${otp3}${otp4}`;
        const params = {
            phoneNumber,
            activationPin: otp,
        }
        const errors = validate();
        setErrors({ errors: errors || {} });
        if (errors) {
            // console.log(errors);
            for (var x in errors) {
                toast.error(errors[x]);
            };
            return;
        }
        else {
            // alert('all good');
            dispatch(validateActivationPin(params));
            dispatch(saveActivationOtp(otp));
        };
    };

    if (validateActivationPinSuccess === true || validatedPinDetails !== undefined) {
        toast.success("pin is correct")
        history.push("/get-started/create-new-pin");
    };

    // const resendOTP = (e) => {
    //     e.preventDefault();
    //     const forgotPasswordForm = { phoneNumber: phoneNumber };
    //     dispatch(sendActivationPin(forgotPasswordForm));
    // };




    return (
        <div className="w-full p-8 sm:p-14 md:my-auto">
            <div className="pb-14 lg:pl-4 lg:pr-4">

                <div className="font-recoleta font-semibold text-2xl sm:text-3xl text-center">
                    <Link to="/login" className=" my-8 h-8 sm:h-10 flex justify-center animate hover:scale-110 ">
                        <img src={logo} alt="EarniPay logo" className="h-full object-contain" />
                    </Link>
                </div>
                <div className="font-sans">
                    <ProgressTracker current={current} />
                    <div className='py-2'>
                        <Steps current={current} />
                    </div>
                </div>
                <div>

                    <div className="mt-10 w-full max-w-lg mx-auto">

                        <div className="note mb-4 text-center hidden">
                            A password reset pin has been sent to your phone number 
                        </div>

                        <div className='bg-white w-full py-5'>
                            <div className=" mb-4 text-left w-5/6 mx-auto">
                                <div className='font-recoleta font-semibold py-2 text-lg lg:text-2xl '>Enter OTP</div>
                                <div className='font-sans text-sm pb-4'>We have sent it on your Phone Number <span className="font-semibold">{phoneNumber}</span> </div>
                            </div>
                            <form onSubmit={submitForm} className='w-5/6 mx-auto'>

                                <div className="form-group">

                                    {/* todo: reduce width and height on small screen */}
                                    <div className='flex justify-between flex-wrap'>                                        
                                        <input type="password" name="otp1" className={`form-input text-center text-white`} id='pin-input1' maxLength={1} value={activatePinForm.otp1} onChange={formChange} style={{ "backgroundColor": `${bgCol.box1}`, "color": "#fff" }} onKeyUp={() => setBgCol({ box1: "#000" })} />
                                        <input type="password" name="otp2" className={`form-input text-center text-white`} id='pin-input2' maxLength={1} value={activatePinForm.otp2} onChange={formChange} style={{ "backgroundColor": `${bgCol.box2}`, "color": "#fff" }} onKeyUp={() => setBgCol({ box2: "#000" })} />
                                        <input type="password" name="otp3" className={`form-input text-center text-white`} id='pin-input3' maxLength={1} value={activatePinForm.otp3} onChange={formChange} style={{ "backgroundColor": `${bgCol.box3}`, "color": "#fff" }} onKeyUp={() => setBgCol({ box3: "#000" })} />
                                        <input type="password" name="otp4" className={`form-input text-center text-white`} id='pin-input4' maxLength={1} value={activatePinForm.otp4} onChange={formChange} style={{ "backgroundColor": `${bgCol.box4}`, "color": "#fff" }} onKeyUp={() => setBgCol({ box4: "#000" })} />
                                    </div>
                                </div>

                                <button type="submit" className="btn btn-lg btn-block btn-ep-blue mt-8" disabled={validateActivationPinLoading}>
                                    <FontAwesomeIcon icon="spinner" spin className={"text-xl mr-2 " + (validateActivationPinLoading ? "inline" : "hidden")} />
                                    Verify OTP
                                </button>

                            </form>
                            {/* <form onSubmit={resendOTP} className='w-5/6 mx-auto'>
                                <button type="submit" className="btn btn-block border-2 border-gray-300 mt-3 hover:bg-gray-300" disabled={loading}>
                                    <FontAwesomeIcon icon="spinner" spin className={"text-xl mr-2 " + (loading ? "inline" : "hidden")} />
                                    Resend Code
                                </button>
                            </form> */}
                            <div className="mt-3 text-sm font-semibold text-gray-500 w-5/6 mx-auto text-center">
                                Wrong Number?  &nbsp;
                                <Link to="/forgot-password" className="text-ep-blue hover:underline">Click here to change</Link>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default VerifySignUpPin
