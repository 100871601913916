import React, { Component } from 'react';
import { connect } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as auth from "store/auth/user/action";
import logo from "assets/images/newDesignAssets/svgs/logo.svg";
// import thankYouIcon from "assets/images/icons/thankYouIcon@svg.svg";
import successIcon from "assets/images/newDesignAssets/svgs/referral-marketing.svg";
import { Link } from 'react-router-dom';

class GetStartedSuccess extends Component {
    state = {  }

    
    signUpComplete = () => {
        this.props.resetSignUp();
        this.props.history.push("/dashboard");
    }


    componentDidMount() {
        if (this.props.signingUp === true){
            if (this.props.signUpPinCreated !== true){
                // this.props.history.push("/get-started/create-pin");
            }
        }
        else{
            this.props.history.push("/get-started");
        }
    }

    render() { 
        return (
            <div className="w-full p-8 sm:p-14 md:my-auto ">
                <div className="pb-14 lg:pl-4 lg:pr-4">

                    <div to="#" className=" my-8 h-8 sm:h-10 flex justify-center animate hover:scale-110 ">
                        <img src={logo} alt="EarniPay logo" className="h-full object-contain" />
                    </div>
                
                    <div className="mt-8 text-center max-w-lg mx-auto">
                        <div className="h-48 sm:h-56">
                            <img src={successIcon} alt="EarniPay" className="h-full object-contain mx-auto" />
                        </div>
                        <div className="mt-8 font-recoleta font-semibold text-2xl sm:text-3xl">
                        Refer & Earn
                        </div>
                        <div className="mt-4 text-gray-500">
                        Copy your unique referral code and share with your friends and Earn for every referral
                        </div>
                        <div className='flex justify-center max-w-sm mx-auto mt-6'>
                            <input type="text" name="ref-code" disabled className='form-input w-3/5' />
                            <button className='btn btn-ep-blue text-sm flex-2 w-2/5 '><FontAwesomeIcon icon='copy' className='mr-2' /> Copy</button>
                        </div>
                        <div>
                            <Link to="/get-started/account-success" className="btn btn-lg btn-block btn-ep-blue mt-10">
                                Continue
                                {/* <FontAwesomeIcon icon="angle-right" className="text-xl ml-2 inline" /> */}
                            </Link>                            
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
 


const mapStateToProps = (state) => ({
    signingUp: state.auth.userPersist.signingUp,
    data: state.auth.userPersist.data,
    signUpPinCreated: state.auth.userPersist.signUpPinCreated,
});

const mapDispatchToProps = (dispatch) => ({
    resetSignUp: () => dispatch(auth.resetSignUp()),
});

export default connect(mapStateToProps, mapDispatchToProps)(GetStartedSuccess);