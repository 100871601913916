import React, { useEffect, useState } from "react";
import { Dialog } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import * as userActions from "store/auth/user/action";
import * as accountActions from "store/auth/account/action";

const PasswordChecker = () => {
    const dispatch = useDispatch();
    const {data: userInfo} = useSelector((s) => s.auth.userPersist);
    const { passwordSetLoading, passwordSet } = useSelector((s) => s.auth.user);
    
    const completeButtonRef = React.createRef();
    const [isOpenPrompt, setIsOpenPrompt] = useState(false);
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [form, setForm] = useState({
        password: "",
        confirmPassword: "",
    })

    const passwordRequirements = [
        "Be at least 8 characters in length",
        "Contain at least one lower case character",
        "Contain at least one upper case character",
        "Contain at least one special character",
        "Contain at least one number",
    ]

    const schema = {
		password: Joi.string().required().label("Password").error(errors => {
			errors.forEach(err => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Please enter your password";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
		confirmPassword: Joi.any().valid(Joi.ref('password')).label("Confirm Password").error(errors => {
			errors.forEach(err => {
				switch (err.type) {
					case "any.allowOnly":
						err.message = "Your passwords does not match";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	};

    const validate = () => {
		const options = { abortEarly: false };
		const result = Joi.validate( form, schema, options );
		// console.log(result);
		const error = result.error;
		if (!error) return null;

		const errors = {};
		for (let item of result.error.details) {
			errors[item.path[0]] = item.message;
		}
		return errors;
	};

    const submitForm = async (e) => {
        e.preventDefault();
        const errors = validate(form);
        if (errors){
            for (var x in errors) {
                toast.error(errors[x]);
            };
            return;
        }
        else{
            dispatch(userActions.setPassword(form));
        }
    }
    
    useEffect(() => {
        if (userInfo?.passwordSet === false){
            setIsOpenPrompt(true);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (passwordSet?.status === "success"){
            setIsOpenPrompt(false);
            dispatch(userActions.resetSetPassword());
            const params = {
                ...userInfo,
                passwordSet: true,
            }
            dispatch(accountActions.updateLoginProfile(params));
        }
        // eslint-disable-next-line
    }, [passwordSet]);

    return (
        <>
            <Dialog initialFocus={completeButtonRef} open={isOpenPrompt} onClose={() => {}} className="fixed z-10 inset-0 overflow-y-auto">
				<div className="min-h-screen flex items-center justify-center rounded shadow-md">
					<Dialog.Overlay className="fixed inset-0 bg-black bg-blur bg-opacity-50" />
					<div className="w-full max-w-sm mx-auto p-8 text-center relative bg-white rounded-lg shadow-md overflow-hidden">
                            
                        <div className="font-semibold text-xl">
							Set your password
						</div>
                        <div className="text-fade text-sm">
                            Please set your password to gain access to your account.
						</div>

                        <form onSubmit={submitForm}>
                            <div className="mt-8">
                                
                                <div className="form-group text-left">
                                    <label>
                                        Password
                                        <span className="form-input-required">*</span>
                                    </label>
                                    <div className="relative">
                                        <input type={showPassword1 ? "text" : "password"} className="form-input" value={form.password} onChange={(e) => setForm({...form, password: e.target.value})} />
                                        <div onClick={() => setShowPassword1(!showPassword1)} className="w-max h-full px-3 flex justify-center items-center absolute top-0 right-0 text-gray-500 cursor-pointer">
											<FontAwesomeIcon icon={showPassword1 ? "eye" : "eye-slash"} />
										</div>
                                    </div>
                                </div>

                                <div className="mb-4 text-left text-fade text-sm">
                                    {passwordRequirements.map((requirement, index) => (
                                        <div key={index} className="mb-1">
                                            <span className="mr-2">•</span>
                                            {requirement}
                                        </div>
                                    ))}
                                </div>
                                
                                <div className="form-group text-left">
                                    <label>
                                        Confirm Password
                                        <span className="form-input-required">*</span>
                                    </label>
                                    <div className="relative">
                                        <input type={showPassword2 ? "text" : "password"} className="form-input" value={form.confirmPassword} onChange={(e) => setForm({...form, confirmPassword: e.target.value})} />
                                        <div onClick={() => setShowPassword2(!showPassword2)} className="w-max h-full px-3 flex justify-center items-center absolute top-0 right-0 text-gray-500 cursor-pointer">
											<FontAwesomeIcon icon={showPassword2 ? "eye" : "eye-slash"} />
										</div>
                                    </div>
                                </div>

                                <button type="submit" disabled={passwordSetLoading} className="btn btn-lg btn-block btn-ep-blue mt-8">
                                    <FontAwesomeIcon icon="circle-notch" spin className={"text-xl mr-2 " + (passwordSetLoading?"inline":"hidden")} />
                                    Set Password
                                </button>

                            </div>
                        </form>

					</div>
				</div>
			</Dialog>
        </>
    )
}

export default PasswordChecker
