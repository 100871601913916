// Main
export const projectName = 'Earnipay';
export const repoName = 'earnipay-app';

// API - middleware
export const apiRequestStart = "API/REQUEST_START";
export const apiRequestSuccess = "API/REQUEST_SUCCESS";     // for logging purpose only
export const apiRequestFailed = "API/REQUEST_FAILED";       // for logging purpose only

// API - base url
export const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
export const apiFeesServiceBaseUrl = process.env.REACT_APP_API_FEES_SERVICE_BASE_URL;
export const apiFeesServiceToken = process.env.REACT_APP_API_FEES_SERVICE_TOKEN;
export const apiNotificationBaseUrl = process.env.REACT_APP_API_NOTIFICATION_BASE_URL;
export const apiWalletBaseUrl = process.env.REACT_APP_API_WALLET_SERVICE_BASE_URL;

// API - user auth
export const apiLoginUser = "/user-management/user/auth/login-with-password";
export const apiLoginUserWithPin = "/user-management/user/auth/login-with-pin";
export const apiForgotPassword = "/user-management/user/auth/initiate-password-reset";
export const apiResetPassword = "/user-management/user/auth/set-new-password";
export const apiSendActivationPin = "/user-management/employee/send-activation-pin";
export const apiValidateActivationPin = "/user-management/employee/validate-activation-pin";
export const apiValidateResetPin = "/user-management/employee/validate-reset-pin";
export const apiSetLoginPin = "/user-management/employee/set-login-pin";
export const apiUpdateUserProfile = "/user-management/user/auth/update-user-profile";
export const apiGetWithdrawalReasons = "/miscellaneous/withdrawal/reasons";

// autopayments 
// employee-operations/schedule-earnings
export const apiCreatePaymentSchedule = "/employee-operations/schedule-earnings";
export const apiGetPaymentSchedules = "/employee-operations/schedule-earnings";
export const apiOnboardAutopay = "/employee-operations/onboard-autopay";


// API - dashboard
export const apiGetAllDashboard = "/employee-operations/dashboard";
// export const apiNewWithdrawal = "/employee-operations/withdraw-earnings";
export const apiNewWithdrawal = "/wallet/fund-from-earnings";
export const apiGetWithdrawalFee = "/fee/calculate-fee";
export const apiGetWalletDetails = "/wallet/user/details";
export const apiGetUserInfo = "/user-management/user";

// API - earnings
export const apiGetAllEarnings = "/employee-operations/get-earnings";

// API - withdrawals
export const apiGetAllWithdrawals = "/employee-operations/get-withdrawals";
export const apiAutopayFee = "/fee/flex/calculate";
export const cancelAutopayFee = "/employee-operations/schedule-earnings"

// API - referrals
export const apiGetAllReferrals = "/referrals/employee";
export const apiGenerateReferralCode = "/referrals/generate-code";

// API - financial education posts
export const apiGetAllPosts = "/financial-education/posts";
export const apiGetPost = "/financial-education/posts/:id?populate=category";

// API - financial education posts categories
export const apiGetCategories = "/financial-education/categories";
export const apiGetCategory = "/financial-education/categories/:id";
// new financial education
export const apiGetOnboardingQuestions = '/questions?type=onboarding';
export const apiGetArticleQuestions = '/questions?type=article';
export const apiGetTermsOfTheDay = '/terms?isCurrent=true';
export const apiGetAllCourses = '/courses/portfolio';
export const apiGetPorfolioCourses = '/courses';
export const apiGetCourse = '/course';
export const apiGetArticleById = '/article';
export const apiGetPorfolioCourse = '/courses/:id';
export const apiGetArticlesByCourseId= '/articles';
export const apiGetArticle= '/articles/:id';
export const apiCardsByArticleId= '/articles/cards';
export const apiEnrollPortfolio = '/courses/portfolio';
export const apiGetAllCoursesInProgress = '/courses/progress';
export const apiGetArticleProgress = '/articles/progress';
export const apiPostCourseProgress = '/courses/progress';
export const apiGetPortfolioCourseProgress = '/courses/progress';
export const apiPostOnboardingTest = '/onboarding-test';
export const apiPostArticleTest = '/articles/assessments';
export const apiGetProfile = '/profile';
// NOTE: the next two apis are not yet valid
export const apiUpdateProfile = '/profile';
export const apiUpdateProfilePassword = '/profile/updatePassword'
// export const apiCreateProgress = '/courses/progress';
// like, unlike, bookmark and unbookmark
export const apilikeCourse = '/courses/:id/like';
export const apiUnlikeCourse = '/courses/:id/unlike';
export const apiBookmarkCourse = '/courses/:id/bookmark';
export const apiUnBookmarkCourse = '/courses/:id/unbookmark';
export const apilikeArticle = '/articles/:id/like';
export const apiUnlikeArticle = '/articles/:id/unlike';
export const apiBookmarkArticle = '/articles/:id/bookmark';
export const apiUnBookmarkArticle = '/articles/:id/unbookmark';
export const apiNotifications = '/notification';


// API - unsubscribe emails
export const apiUnsubscribeEmailEarnings = "/miscellaneous/employee/earnings-mail/disable/";

// API - unsubscribe emails
export const apiSendOtp = "/user-management/user/auth/send-otp";
export const apiVerifyOtp = "/user-management/user/auth/verify-otp";