import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import IdleChecker from "hooks/useIdleChecker";
import PasswordChecker from "components/common/PasswordChecker";
import Header from "components/common/header.jsx";
import LeftSidebar from "components/common/sidebarNavigation.jsx";
import RightSidebar from "components/common/sidebarAccount.jsx";

const EmployeeAdminLayout = (props) => {
	const { name, email, _id } = useSelector(
		(state) => state.auth.userPersist.data
	);

	const app_id = process.env.REACT_APP_SIMPU_APP_ID;
	const public_key = process.env.REACT_APP_SIMPU_PUBLIC_KEY;

	useEffect(() => {
		var widget = window.Simpu.default.init({
			app_id,
			public_key,
		});
		widget.render({
			name: name,
			email: email,
			user_id: _id,
		});
		//eslint-disable-next-line
	}, []);

	return (
		<>
			<IdleChecker />

			<PasswordChecker />

			<div className="lg:flex min-h-screen">
				<div className="lg:flex-shrink-0 hidden lg:block overscroll-y-none">
					<LeftSidebar />
					<div className="w-24 h-screen flex-none hidden lg:block"></div>
				</div>

				<div className="lg:flex-grow">
					<div className="block xl:hidden">
						<Header />
					</div>
					<div className="flex-grow p-6 pb-6 sm:p-10 sm:pb-2 overscroll-y-none">
						{props.children}
					</div>
				</div>

				<div className="lg:flex-shrink-0 hidden xl:block">
					<RightSidebar />
					<div className="w-96 h-screen flex-none hidden xl:block"></div>
				</div>
			</div>
		</>
	);
};

export default EmployeeAdminLayout;
