import React from 'react';
import IdleChecker from "hooks/useIdleChecker";

const NullLayout = (props) => {
    return ( 
        <>
            <IdleChecker />

            <div>
                {props.children}
            </div>
        </>
     );
}
 
export default NullLayout;