
const Maybe = (props) => {
    return (
        <>
            {/* {(props.condition) && (props.condition === true) &&
                props.children
            } */}
            
            {props.condition && !!props.condition &&
                props.children
            }
        </>
    )
}

export default Maybe
