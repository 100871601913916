import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class GetStarted2 extends Component {
    
    goBack = () => {
        this.props.history.goBack();
    }

    render() { 
        
        return (
            <div className="w-full p-8 sm:p-14 md:my-auto">
                <div className="pb-14 md:pt-20 lg:pl-4 lg:pr-4">

                    <div className="mt-8 text-center max-w-md mx-auto">
                        <div className="h-24 flex">
                            <FontAwesomeIcon icon="exclamation-circle" className="text-ep-yellow text-8xl m-auto" />
                            {/* <FontAwesomeIcon icon="frown" className="text-ep-yellow text-8xl m-auto" /> */}
                        </div>
                        <div className="mt-8 font-boing font-semibold">
                            <div className="text-2xl sm:text-3xl">
                                Oops!
                            </div>
                            <div className="text-xl sm:text-2xl">
                                Your account was not found.
                            </div>
                        </div>
                        <div className="mt-4 text-gray-500">
                            You can refer your CEO, HR or Finance Manager to sign up on Earnipay and add you as a user.
                        </div>

                        <div className="max-w-xs mx-auto">
                            <a href="https://earnipay.com/refer-employer" target="_blank" rel="noreferrer" className="btn btn-lg btn-block btn-ep-blue mt-8">Refer my employer</a>
                            <button type="button" onClick={this.goBack} className="btn btn-lg btn-block btn-transparent-black mt-2">Go Back</button>
                        </div>

                    </div>

                </div>
            </div>
        )
    }
}


export default GetStarted2;