import React, { Component } from "react";
import store from "store/store";
import Joi from "joi-browser";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as auth from "store/auth/user/action";
import logo from "assets/images/icons/grn-logo.svg";
import DynamicHeadTag from "components/common/DynamicHeadTag";

class ForgotPassword extends Component {
	state = {
		forgotPasswordForm: {
			email: "",
		},
		errors: {},
	};

	formChange = (e) => {
		const formValue = { ...this.state.forgotPasswordForm };
		formValue[e.currentTarget.name] = e.currentTarget.value;
		this.setState({ forgotPasswordForm: formValue });
	};

	schema = {
		email: Joi.string().email().required().label("Email Address").error(errors => {
			errors.forEach(err => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Please enter your email address";
						break;
					case "string.email":
						err.message = "Please enter a valid email address";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	};

	validate = () => {
		const options = { abortEarly: false };
		const result = Joi.validate(
			this.state.forgotPasswordForm,
			this.schema,
			options
		);
		// console.log(result);
		const error = result.error;
		if (!error) return null;

		const errors = {};
		for (let item of result.error.details) {
			errors[item.path[0]] = item.message;
		}
		return errors;
	};

	submitForm = (e) => {
		e.preventDefault();
		const errors = this.validate();
		this.setState({ errors: errors || {} });
		if (errors) {
			// console.log(errors);
			for (var x in errors) {
				toast.error(errors[x]);
			}
			return;
		} else {
			// alert('all good');
			// console.log(this.state.forgotPasswordForm);
			this.props.forgotPassword(this.state.forgotPasswordForm);
			this.props.saveForgotPasswordForm(this.state.forgotPasswordForm);
		}
	};

	componentDidMount() {
		this.unsubscribe = store.subscribe(() => {
			if (store.getState().auth.user.forgotPassword === true) {
				this.props.history.push("/reset-password");
				this.props.resetForgotPassword();
			}
		});
	}

	componentWillUnmount() {
		this.unsubscribe();
	}

	render() {
		const { forgotPasswordForm } = this.state;

		return (
			<>
				<DynamicHeadTag
					headerText="Forgot Password"
					headerDescription="We’ll send an OTP to your email address"
				/>

				<div className="w-full p-8 sm:px-14 md:py-2  md:my-auto ">
					<div className="pb-14 md:pt-0 lg:pl-4 lg:pr-4">
						<div className="text-center">
							<Link to="/" className=" my-8 h-8 sm:h-10 flex justify-center animate hover:scale-110 ">
								<img src={logo} alt="EarniPay logo" className="h-full object-contain" />
							</Link>
							<div className="font-recoleta font-semibold text-2xl sm:text-3xl text-center ">
								Forgot Password
							</div>
							<div className="mt-2 text-base">
								We’ll send an OTP to your email address
							</div>
						</div>

						<div className="bg-white max-w-sm mx-auto sm:px-6 rounded-md">
							<form onSubmit={this.submitForm}>
								<div className="py-5 mt-10 rounded-md">
									<div className="mt-2 w-full max-w-md mx-auto lg:w-92">
										<div className="form-group">
											<label>
												Email Address
												<span className="form-input-required">*</span>
											</label>
											<input
												type="email"
												name="email"
												className="form-input"
												value={forgotPasswordForm.email}
												onChange={this.formChange}
											/>
										</div>

										<button
											type="submit"
											className="btn btn-lg btn-block btn-ep-blue mt-8"
											disabled={this.props.loading}>
											<FontAwesomeIcon
												icon="spinner"
												spin
												className={
													"text-xl mr-2 " +
													(this.props.loading ? "inline" : "hidden")
												}
											/>
											Reset Password
										</button>

										<div className="mt-3 text-sm font-semibold text-gray-500">
											Remember your password? &nbsp;
											<Link to="/login" className="text-ep-blue hover:underline">
												Login
											</Link>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	loading: state.auth.user.loading,
	forgotPassword: state.auth.user.forgotPassword,
});

const mapDispatchToProps = (dispatch) => ({
	forgotPassword: (params) => dispatch(auth.forgotPassword(params)),
	saveForgotPasswordForm: (params) =>
		dispatch(auth.saveForgotPasswordForm(params)),
	resetForgotPassword: () => dispatch(auth.resetForgotPassword()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
