export const GET_NOTIFICATIONS_START = "GET_NOTIFICATIONS_START";
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const GET_NOTIFICATIONS_FAILED = "GET_NOTIFICATIONS_FAILED";

export const GET_NOTIFICATION_START = "GET_NOTIFICATION_START";
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_FAILED = "GET_NOTIFICATION_FAILED";

export const BULK_UPDATE_NOTIFICATION_START = "BULK_UPDATE_NOTIFICATION_START";
export const BULK_UPDATE_NOTIFICATION_SUCCESS = "BULK_UPDATE_NOTIFICATION_SUCCESS";
export const BULK_UPDATE_NOTIFICATION_FAILED = "BULK_UPDATE_NOTIFICATION_FAILED";

export const UPDATE_NOTIFICATION_START = "UPDATE_NOTIFICATION_START";
export const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS";
export const UPDATE_NOTIFICATION_FAILED = "UPDATE_NOTIFICATION_FAILED";

export const DELETE_NOTIFICATION_START = "DELETE_NOTIFICATION_START";
export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS";
export const DELETE_NOTIFICATION_FAILED = "DELETE_NOTIFICATION_FAILED";