import * as actions from "./actionTypes";
import * as config from "config";

export function getDashboard() {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetAllDashboard,
            method: "get",
            data: {},
            onStart: actions.GET_ALL_DASHBOARD_START,
            onSuccess: actions.GET_ALL_DASHBOARD,
            onError: actions.GET_ALL_DASHBOARD_FAILED,
            // useLoginJwt: false,
        },
    }
}

export function getWithdrawalFee(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiFeesServiceBaseUrl,
            url: config.apiGetWithdrawalFee,
            method: "post",
            data: params,
            onStart: actions.GET_WITHDRAWAL_FEE_START,
            onSuccess: actions.GET_WITHDRAWAL_FEE,
            onError: actions.GET_WITHDRAWAL_FEE_FAILED,
            customToken: config.apiFeesServiceToken,
        },
    }
};
export function getAutopayFee(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiFeesServiceBaseUrl,
            url: config.apiAutopayFee,
            method: "post",
            data: params,
            onStart: actions.GET_AUTOPAY_FEE_START,
            onSuccess: actions.GET_AUTOPAY_FEE,
            onError: actions.GET_AUTOPAY_FEE_FAILED,
            customToken: config.apiFeesServiceToken,
        },
    }
};

export function newWithdrawal(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiWalletBaseUrl,
            url: config.apiNewWithdrawal,
            method: "post",
            data: params,
            onStart: actions.NEW_WITHDRAWAL_START,
            onSuccess: actions.NEW_WITHDRAWAL,
            onError: actions.NEW_WITHDRAWAL_FAILED,
        },
    }
};

export function getReasonsForWithdraw() {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetWithdrawalReasons,
            method: "get",
            data: {},
            onStart: actions.GET_WITHDRAWAL_REASON_START,
            onSuccess: actions.GET_WITHDRAWAL_REASON,
            onError: actions.GET_WITHDRAWAL_REASON_FAILED
        },
    }
}
export function resetNewWithdrawal() {
    return {
        type: actions.NEW_WITHDRAWAL_RESET,
        payload: {},
    }
}
