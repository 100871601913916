import * as actions from "./actionTypes";
import { toast } from 'react-toastify';

const initialState = {
    loading: false,
    allReferrals: {},
    reloadPage: false,
};


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.GET_ALL_REFERRALS:
            state = {
                ...state,
                loading: false,
                allReferrals: { ...action.payload.referrals }
            }
            return state;


        case actions.GET_ALL_REFERRALS_START:
            state = {
                ...state,
                loading: true,
            }
            return state;


        case actions.GET_ALL_REFERRALS_FAILED:
            toast.error(action.payload.message);
            state = {
                ...state,
                loading: false,
                allReferrals: {},
            }
            return state;


        case actions.RELOAD_PAGE_RESET:
            state = {
                ...state,
                reloadPage: false
            }
            return state;


        default:
            return state;
    }
}