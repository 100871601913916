 const dayObject = [
    {
        name: 'Monday',
        symbol: 'Mo',
        dayId: 1
    },
    {
        name: 'Tuesday',
        symbol: 'Tu',
        dayId: 2,
    },
    {
        name: 'Wednesday',
        symbol: 'We',
        dayId: 3,
    },
    {
        name: 'Thursday',
        symbol: 'Th',
        dayId: 4,
    },
    {
        name: 'Friday',
        symbol: 'Fr',
        dayId: 5,
    },
    {
        name: 'Saturday',
        symbol: 'Sa',
        dayId: 6,
    },
    {
        name: 'Sunday',
        symbol: 'Su',
        dayId: 7,
    },

 ];
export default dayObject;