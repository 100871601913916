import * as actions from "./actionTypes";
import * as config from "config";

export function loginUser(email, password, channel) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiLoginUser,
            method: "post",
            data: {email, password, channel},
            onStart: actions.LOGIN_USER_START,
            onSuccess: actions.LOGIN_USER,
            onError: actions.LOGIN_USER_FAILED,
            useEncryption: true,
        },
    }
}

export function loginUserWithPin(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiLoginUserWithPin,
            method: "post",
            data: params,
            onStart: actions.LOGIN_USER_START,
            onSuccess: actions.LOGIN_USER,
            onError: actions.LOGIN_USER_FAILED,
            useEncryption: true,
        },
    }
}

export function sendActivationPin(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiSendActivationPin,
            method: "post",
            data: params,
            onStart: actions.SEND_ACTIVATION_PIN_START,
            onSuccess: actions.SEND_ACTIVATION_PIN,
            onError: actions.SEND_ACTIVATION_PIN_FAILED,
        }
    }
}

export function validateActivationPin(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiValidateActivationPin,
            method: "post",
            data: params,
            onStart: actions.VALIDATE_ACTIVATION_PIN_START,
            onSuccess: actions.VALIDATE_ACTIVATION_PIN_SUCCESS,
            onError: actions.VALIDATE_ACTIVATION_PIN_FAILED,
        }
    }
}

export function validateResetPin(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiValidateResetPin,
            method: "post",
            data: params,
            onStart: actions.VALIDATE_ACTIVATION_PIN_START,
            onSuccess: actions.VALIDATE_ACTIVATION_PIN_SUCCESS,
            onError: actions.VALIDATE_ACTIVATION_PIN_FAILED,
        }
    }
}
export function saveSignUpDetails(phoneNumber) {
    return {
        type: actions.SAVE_SIGNUP_DETAILS,
        payload: {
            phoneNumber: phoneNumber,
        },
    }
}

export function saveActivationOtp(otp) {
    return {
        type: actions.SAVE_ACTIVATION_PIN ,
        payload: {
            otp: otp,
        },
    }
}

export function savePin(pin) {
    return {
        type: actions.SAVE_NEW_PIN,
        payload: {
            pin: pin,
        },
    }
}

export function resetSendActivationPin() {
    return {
        type: actions.SEND_ACTIVATION_PIN_RESET,
        payload: {},
    }
}

export function setLoginPin(signUpForm) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiSetLoginPin,
            method: "post",
            data: signUpForm,
            onStart: actions.SET_LOGIN_PIN_START,
            onSuccess: actions.SET_LOGIN_PIN,
            onError: actions.SET_LOGIN_PIN_FAILED,
        }
    }
}

export function resetSignUp() {
    return {
        type: actions.SIGNUP_COMPLETE,
        payload: {},
    }
}


export function forgotPassword(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiForgotPassword,
            method: "post",
            data: params,
            onStart: actions.FORGOT_PASSWORD_START,
            onSuccess: actions.FORGOT_PASSWORD,
            onError: actions.FORGOT_PASSWORD_FAILED
        }
    }
}

export function saveForgotPasswordForm(params) {
    return {
        type: actions.SAVE_FORGOT_PASSWORD_FORM,
        payload: params,
    }
}

// save verify token
export function saveResetToken(params) {
    return {
        type: actions.SAVE_NEW_PIN_DETAILS,
        payload: params,
    }
}
// save created pin
export function saveNewPin(params) {
    return {
        type: actions.SAVE_NEW_PIN_DETAILS,
        payload: params,
    }
}


export function resetForgotPassword() {
    return {
        type: actions.FORGOT_PASSWORD_RESET,
        payload: {},
    }
}

export function resetPassword(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiResetPassword,
            method: "patch",
            data: params,
            onStart: actions.RESET_PASSWORD_START,
            onSuccess: actions.RESET_PASSWORD,
            onError: actions.RESET_PASSWORD_FAILED
        }
    }
}

export function resetResetPassword() {
    return {
        type: actions.RESET_PASSWORD_RESET,
        payload: {},
    }
}

export function unsubscribeEarningsEmail(email) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiUnsubscribeEmailEarnings + email,
            method: "get",
            data: {},
            onStart: actions.UNSUBSCRIBE_EMAIL_START,
            onSuccess: actions.UNSUBSCRIBE_EMAIL,
            onError: actions.UNSUBSCRIBE_EMAIL_FAILED
        }
    }
}

export function unsubscribeEarningsEmailTempppp(email) {
    return {
        type: actions.UNSUBSCRIBE_EMAIL,
        payload: {},
    }
}

export function setPassword(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiUpdateUserProfile,
            method: "patch",
            data: params,
            onStart: actions.SET_PASSWORD_START,
            onSuccess: actions.SET_PASSWORD,
            onError: actions.SET_PASSWORD_FAILED
        }
    }
}

export function resetSetPassword() {
    return {
        type: actions.SET_PASSWORD_RESET,
        payload: {},
    }
}

export function emailSendOtp(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiSendOtp,
            method: "post",
            data: params,
            onStart: actions.EMAIL_SEND_OTP_START,
            onSuccess: actions.EMAIL_SEND_OTP,
            onError: actions.EMAIL_SEND_OTP_FAILED,
            useEncryption: true
        }
    }
}

export function resetEmailSendOtp() {
    return {
        type: actions.EMAIL_SEND_OTP_RESET,
        payload: {},
    }
}

export function emailVerifyOtp(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiVerifyOtp,
            method: "post",
            data: params,
            onStart: actions.EMAIL_VERIFY_OTP_START,
            onSuccess: actions.EMAIL_VERIFY_OTP,
            onError: actions.EMAIL_VERIFY_OTP_FAILED,
            useEncryption: true
        }
    }
}

export function resetEmailVerifyOtp() {
    return {
        type: actions.EMAIL_VERIFY_OTP_RESET,
        payload: {},
    }
}

export function updateEmail(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiUpdateUserProfile,
            method: "patch",
            data: params,
            onStart: actions.UPDATE_EMAIL_START,
            onSuccess: actions.UPDATE_EMAIL,
            onError: actions.UPDATE_EMAIL_FAILED
        }
    }
}

export function resetUpdateEmail() {
    return {
        type: actions.UPDATE_EMAIL_RESET,
        payload: {},
    }
}

export function softLogout() {
    return {
        type: actions.SOFT_LOGOUT,
        payload: {},
    }
}

export function logoutUser() {
    return {
        type: actions.LOGOUT_USER,
        payload: {}
    }
}
