import React, { Component } from 'react';
import store from "store/store";
import Joi from "joi-browser";
import logger from "utils/logger";
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as auth from "store/auth/user/action";
import logo from "assets/images/newDesignAssets/svgs/logo.svg";

// components
import{ Steps, StepLabel } from './Step';
class GetStarted2 extends Component {
    state = { 
        signUpForm: {
            phoneNumber: "",
            otp: "",
            pin: "",
            confirmPin: "",
        },
        errors: {},
        storeUnsubscribe: "",
    }
    
    formChange = (e) => {
        const formValue = {...this.state.signUpForm};
        formValue[e.currentTarget.name] = e.currentTarget.value;
        this.setState({signUpForm: formValue});
    }
    
    schema = {
        phoneNumber: Joi.string().min(11).max(11).required().label("Phone Number").error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "any.empty":
                        err.message = "Please enter your phone number";
                        break;
                    case "string.min":
                        err.message = `Please enter 11 digits for your phone number`;
                        break;
                    case "string.max":
                        err.message = `Please enter 11 digits for your phone number`;
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
        otp: Joi.string().length(4).required().label("OTP").error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "any.empty":
                        err.message = "Please enter the 4-digit code sent to your phone number";
                        break;
                    case "string.length":
                        err.message = "Please enter the 4-digit code sent to your phone number";
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
        pin: Joi.string().length(4).required().label("PIN").error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "any.empty":
                        err.message = "Please enter your PIN";
                        break;
                    case "string.length":
                        err.message = "Please enter your PIN";
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
        confirmPin: Joi.any().valid(Joi.ref('pin')).label("Confirm Pin").error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "any.allowOnly":
                        err.message = "Your PIN does not match";
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
    }


    validate = () => {
        const options = { abortEarly: false };
        const result = Joi.validate(this.state.signUpForm, this.schema, options);
        // console.log(result);
        const error = result.error;
        if (!error) return null;
    
        const errors = {};
        for (let item of result.error.details){
            errors[item.path[0]] = item.message;
        }
        return errors;
    };


    submitForm = (e) => {
        e.preventDefault();
        const errors = this.validate();
        this.setState({errors: errors || {}});
        if (errors){
            // console.log(errors);
            for (var x in errors) {
                toast.error(errors[x]);
            };
            return;
        }
        else{
            // alert('all good');
            // console.log(this.state.signUpForm);
            const signUpForm = {
                phoneNumber: this.state.signUpForm.phoneNumber,
                activationPin: this.state.signUpForm.otp,
                pin: this.state.signUpForm.pin,
                pin_confirmation: this.state.signUpForm.confirmPin,
            }
            // console.log(signUpForm);
            this.props.setLoginPin(signUpForm);
        }
    }


    resendActivationPin = () => {
        toast("Resending OTP");
        this.props.sendActivationPin(this.props.signUpUser.phoneNumber);
        
        this.unsubscribe = store.subscribe(() => {
            if (this.props.sendActivationPinSuccess === true){
                toast.success("A new OTP has been sent");
                this.props.resetSendActivationPin();
            }
        });
        this.setState({storeUnsubscribe: this.unsubscribe});
    }
    
    
    componentDidMount() {
        logger("employeeweb-accept-invite", this.props.user);
        
        if (this.props.signingUp === true){

            if (this.props.signUpPinCreated === true){
                this.props.history.push("/get-started/personal-details");
            }
            
            const newSignUpForm = {...this.state.signUpForm, phoneNumber: this.props.signUpUser.phoneNumber};
            this.setState({signUpForm: newSignUpForm});

            this.unsubscribe = store.subscribe(() => {
                if (this.props.signUpPinCreated === true){
                    this.unsubscribe();
                    logger("employeeweb-accept-invite-success", this.props.user);
                    this.props.history.push("/get-started/personal-details");
                }
            });
            this.setState({storeUnsubscribe: this.unsubscribe});

        }
        else{
            this.props.history.push("/get-started");
        }
    }


    componentWillUnmount() {
        const storeUnsubscribe = this.state.storeUnsubscribe;
        if ((storeUnsubscribe !== "") && (storeUnsubscribe !== undefined) && (storeUnsubscribe !== null)){
            storeUnsubscribe();
        }
    }


    render() { 
        // const {signUpForm} = this.state;

        return (
            <div className="w-full p-8 sm:p-14 md:my-auto">
                <div className="pb-14 lg:pl-4 lg:pr-4">
                    
                        <div className=" my-8 h-8 sm:h-10 flex justify-center animate hover:scale-110 ">
                            <img src={logo} alt="EarniPay logo" className="h-full object-contain" />
                        </div>
                        <div className='max-w-lg mx-auto pb-10'>
                            <Steps />
                            <StepLabel />
                        </div>

                    <div className='w-full max-w-lg mx-auto py-8 bg-white'>
                        <form onSubmit={this.submitForm} className='w-5/6 mx-auto'>
                            <div className='mb-3 pb-5'>
                                <div className="font-recoleta font-semibold text-2xl sm:text-3xl text-center md:text-left">
                                    Enter OTP
                                </div>
                                <div className="">
                                    We have sent it on your Phone Number 090111536767
                                </div>
                           </div>
                            <div className=" ">
                                 <div className="form-group">
                                        <div className='flex justify-between flex-wrap'>
                                            <input type="password" name="otp" className="form-input text-center bg-new-ep-grey" id='pin-input1' onChange={this.formChange}/>
                                            <input type="password" name="otp" className="form-input text-center bg-new-ep-grey" id='pin-input2' onChange={this.formChange} />
                                            <input type="password" name="otp" className="form-input text-center bg-new-ep-grey" id='pin-input3' onChange={this.formChange} />
                                            <input type="password" name="otp" className="form-input text-center bg-new-ep-grey" id='pin-input4' onChange={this.formChange} />
                                        </div>
                                    {/* <input type="text" name="otp" placeholder="" className="form-input" maxLength="4" value={signUpForm.otp} onChange={this.formChange} /> */}
                                </div>

                                <button type="submit" className="btn btn-lg btn-block btn-ep-blue mt-6" disabled={this.props.loading}>
                                    <FontAwesomeIcon icon="spinner" spin className={"text-xl mr-2 " + (this.props.loading?"inline":"hidden")} />
                                    Verify Phone
                                    <FontAwesomeIcon icon="angle-right" className="text-xl ml-2 inline" />
                                </button>
                               
                                <button type="button" onClick={this.resendActivationPin} className="btn btn-block mt-2" disabled={this.props.sendActivationPinLoading}>
                                    <FontAwesomeIcon icon="spinner" spin className={"text-xl mr-2 " + (this.props.sendActivationPinLoading?"inline":"hidden")} />
                                   <span>Not recieve OTP? </span>  <span className='text-ep-blue'>Resend OTP</span>
                                </button>


                            </div>

                        </form>
                    </div>

                </div>
            </div>
        )
    }
}



const mapStateToProps = (state) => ({
    loading: state.auth.user.loading,
    user: state.auth.userPersist.data,
    sendActivationPinLoading: state.auth.user.sendActivationPinLoading,
    sendActivationPinSuccess: state.auth.user.sendActivationPinSuccess,
    signingUp: state.auth.userPersist.signingUp,
    signUpUser: state.auth.userPersist.signUpUser,
    signUpPinCreated: state.auth.userPersist.signUpPinCreated,
});

const mapDispatchToProps = (dispatch) => ({
    setLoginPin: (signUpForm) => dispatch(auth.setLoginPin(signUpForm)),
    sendActivationPin: (phoneNumber) => dispatch(auth.sendActivationPin(phoneNumber)),
    resetSendActivationPin: () => dispatch(auth.resetSendActivationPin()),
});

export default connect(mapStateToProps, mapDispatchToProps)(GetStarted2);