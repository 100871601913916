import React, { Component } from "react";
import store from "store/store";
import Joi from "joi-browser";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as auth from "store/auth/user/action";
import logo from "assets/images/icons/grn-logo.svg";
import DynamicHeadTag from "components/common/DynamicHeadTag";

class ResetPassword extends Component {
	state = {
		resetPasswordForm: {
			otp: "",
			password: "",
			confirmPassword: "",
		},
		errors: {},
	};

	formChange = (e) => {
		const formValue = { ...this.state.resetPasswordForm };
		formValue[e.currentTarget.name] = e.currentTarget.value;
		this.setState({ resetPasswordForm: formValue });
	};

	schema = {
		otp: Joi.number().integer().required().label("OTP").error(errors => {
			errors.forEach(err => {
				switch (err.type) {
					case "number.base":
						err.message = `Please enter the OTP, sent to your email address`;
						break;
					case "number.integer":
						err.message = `Please enter a valid OTP, sent to your email address`;
						break;
					default:
						break;
				}
			});
			return errors;
		}),
		password: Joi.string().required().label("Password").error(errors => {
			errors.forEach(err => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Please enter your password";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
		confirmPassword: Joi.any().valid(Joi.ref('password')).label("Confirm Password").error(errors => {
			errors.forEach(err => {
				switch (err.type) {
					case "any.allowOnly":
						err.message = "Your passwords does not match";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	};

	validate = () => {
		const options = { abortEarly: false };
		const result = Joi.validate( this.state.resetPasswordForm, this.schema, options );
		// console.log(result);
		const error = result.error;
		if (!error) return null;

		const errors = {};
		for (let item of result.error.details) {
			errors[item.path[0]] = item.message;
		}
		return errors;
	};

	submitForm = (e) => {
		e.preventDefault();
		const errors = this.validate();
		this.setState({ errors: errors || {} });
		if (errors) {
			// console.log(errors);
			for (var x in errors) {
				toast.error(errors[x]);
			}
			return;
		} else {
			// alert('all good');
			// console.log(this.state.resetPasswordForm);
			this.props.resetPassword({
				...this.state.resetPasswordForm,
				email: this.props.forgotPasswordForm.email,
			});
		}
	};

	componentDidMount() {
		if (this.props.forgotPasswordForm?.email === undefined){
			this.props.history.push("/forgot-password");	
		}

		this.unsubscribe = store.subscribe(() => {
			if (store.getState().auth.user.resetPassword === true) {
				this.props.history.push("/reset-password/success");
				this.props.resetResetPassword();
			}
		});
	}

	componentWillUnmount() {
		this.unsubscribe();
	}

	render() {
		const { resetPasswordForm } = this.state;

		return (
			<>
				<DynamicHeadTag
					headerText="Reset Password"
					headerDescription="Set a new password to gain access to your account"
				/>

				<div className="w-full p-8 sm:px-14 md:py-2  md:my-auto ">
					<div className="pb-14 md:pt-0 lg:pl-4 lg:pr-4">
						<div className="text-center">
							<Link to="/" className=" my-8 h-8 sm:h-10 flex justify-center animate hover:scale-110 ">
								<img src={logo} alt="EarniPay logo" className="h-full object-contain" />
							</Link>
							<div className="font-recoleta font-semibold text-2xl sm:text-3xl text-center ">
								Reset Password
							</div>
							<div className="mt-2 text-base">
								Set a new password to gain access to your account
							</div>
						</div>

						<div className="bg-white max-w-sm mx-auto sm:px-6 rounded-md">
							<form onSubmit={this.submitForm}>
								<div className="py-5 mt-10 rounded-md">
									<div className="mt-2 w-full max-w-md mx-auto lg:w-92">
										<div className="form-group">
											<label>
												Email Address
												<span className="form-input-required">*</span>
											</label>
											<input
												type="email"
												className="form-input"
												value={this.props.forgotPasswordForm?.email}
												readOnly={true}
												disabled={true}
											/>
										</div>

										<div className="form-group">
											<label>
												OTP
												<span className="form-input-required">*</span>
											</label>
											<input
												type="number"
												name="otp"
												className="form-input"
												value={resetPasswordForm.otp}
												onChange={this.formChange}
											/>
										</div>

										<div className="form-group">
											<label>
												Password
												<span className="form-input-required">*</span>
											</label>
											<input
												type="password"
												name="password"
												className="form-input"
												value={resetPasswordForm.password}
												onChange={this.formChange}
											/>
										</div>

										<div className="form-group">
											<label>
												Confirm Password
												<span className="form-input-required">*</span>
											</label>
											<input
												type="password"
												name="confirmPassword"
												className="form-input"
												value={resetPasswordForm.confirmPassword}
												onChange={this.formChange}
											/>
										</div>

										<button
											type="submit"
											className="btn btn-lg btn-block btn-ep-blue mt-8"
											disabled={this.props.loading}>
											<FontAwesomeIcon
												icon="spinner"
												spin
												className={
													"text-xl mr-2 " +
													(this.props.loading ? "inline" : "hidden")
												}
											/>
											Reset Password
										</button>

										<div className="mt-3 text-sm font-semibold text-gray-500">
											Remember your password? &nbsp;
											<Link to="/login" className="text-ep-blue hover:underline">
												Login
											</Link>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	forgotPasswordForm: state.auth.user.forgotPasswordForm,
	loading: state.auth.user.resetPasswordLoading,
	resetPassword: state.auth.user.resetPassword,
});

const mapDispatchToProps = (dispatch) => ({
	resetPassword: (params) => dispatch(auth.resetPassword(params)),
	resetResetPassword: () => dispatch(auth.resetResetPassword()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
