import React from 'react';
import { Dialog } from '@headlessui/react';



const FilterTransactionModal = ({ open, handleOpen, filterTransaction, filterForm, handleFilterFormChange }) => {

    const { startDate, endDate } = filterForm;
    const disabled = startDate === '' && endDate === '' ;
    const inputColorSetter = (label) => label !== "" ? "" : "#9CA3AF"
  
    const completeButtonRef = React.createRef(null);

    return (
        <Dialog
            open={open} initialFocus={completeButtonRef}
            onClose={() => handleOpen(false)}
            className="fixed z-10 inset-0 overflow-y-auto "
        >

            <div className="w-full my-auto">
                <Dialog.Overlay className="fixed inset-0 bg-white bg-blur bg-opacity-50" />

                <div className="pb-14 md:pt-5 lg:pl-4 lg:pr-4 modal-box2 mt-8" ref={completeButtonRef}>
                    <div className='mx-auto py-3 rounded-md'>
                        <div className=' px-5 mx-auto'>
                            <div className='font-recoleta font-semibold text-xl pb-4'>Filter Transaction</div>

                            <div>
                                <form >
                                    <div className="">
                                        <div className='flex justify-between space-x-2'>
                                            <div className="form-group w-full">
                                                <label htmlFor="start date">From</label>
                                                <input type="date" name="startDate" className='form-input'
                                                    value={filterForm.startDate}
                                                    onChange={handleFilterFormChange}
                                                    style={{ "color": `${inputColorSetter(filterForm.startDate)}` }}

                                                />
                                            </div>
                                            <div className="form-group w-full">
                                                <label htmlFor="end date">To</label>
                                                <input type="date" name="endDate"
                                                    className='form-input' value={filterForm.endDate}
                                                    onChange={handleFilterFormChange}
                                                    style={{ "color": `${inputColorSetter(filterForm.endDate)}` }}

                                                />
                                            </div>
                                        </div>
                                        {/* <div className='flex justify-between space-x-2'>
                                            <div className="form-group w-full ">
                                                <label htmlFor="action" className='text-sm'>Type</label>

                                                <select
                                                    name="action"
                                                    className='form-input' placeholder='Select'
                                                    value={filterForm.action}
                                                    style={{ "color": `${inputColorSetter(filterForm.action)}` }}
                                                    onChange={handleFilterFormChange}
                                                >
                                                    <option value="">Type...</option>
                                                    <option value="withdrawal">Withdrawal</option>
                                                    <option value="flexx">Flexx</option>
                                                    <option value="deposit">Deposit</option>
                                                    <option value="referral">Referral</option>
                                                    <option value="earning">Earning</option>
                                                </select>
                                            </div>
                                            <div className="form-group w-full ">
                                                <label htmlFor="status" className='text-sm'>Status</label>
                                                <select
                                                    name="type"
                                                    className='form-input' placeholder='Select'
                                                    value={filterForm.type}
                                                    onChange={handleFilterFormChange}
                                                    style={{ "color": `${inputColorSetter(filterForm.type)}` }}

                                                >
                                                    <option value=" ">Status...</option>
                                                    <option value="success">Success</option>
                                                    <option value="failed">Failed</option>
                                                </select>
                                            </div>
                                        </div> */}
                                    </div>

                                    <div className='mt-4' onClick={() => {
                                        filterTransaction()
                                    }}>
                                        <button className='btn btn-block bg-new-ep-blue text-white' type='submit' disabled={disabled}> Apply</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </Dialog>
    );
};

export default FilterTransactionModal;