import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import thankYouIcon from "assets/images/newDesignAssets/svgs/success.svg";
import logo from "assets/images/newDesignAssets/svgs/logo.svg";


class ActivateAccountSuccess extends Component {

    render() { 
        return (
            <div className="w-full p-8 sm:p-14 md:my-auto">
                <div className="pb-14 md:pt-20 lg:pl-4 lg:pr-4">
                    <div className="font-recoleta font-semibold text-2xl sm:text-3xl text-center">
                        <Link to="/" className=" my-8 h-8 sm:h-10 flex justify-center animate hover:scale-110 ">
                            <img src={logo} alt="EarniPay logo" className="h-full object-contain" />
                        </Link>
                    </div>
                    <div className="mt-8 text-center max-w-md mx-auto">
                        <div className="h-48 sm:h-56">
                            <img src={thankYouIcon} alt="EarniPay" className="h-full object-contain mx-auto" />
                        </div>
                        <div className="mt-8 font-recoleta font-semibold text-2xl sm:text-3xl">
                            Success
                        </div>
                        <div className="mt-2 text-gray-500">
                            Welcome to Earnipay
                        </div>

                        <Link to="/dashboard" className="btn btn-lg btn-block btn-ep-blue mt-8">Go To Dashboard</Link>

                    </div>

                </div>
            </div>
        )
    }
}
 
export default ActivateAccountSuccess;