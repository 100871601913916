import { combineReducers } from "redux";
import dashboardReducer from "./dashboard/reducer";
import earningsReducer from "./earnings/reducer";
import withdrawalsReducer from "./withdrawals/reducer";
import referralsReducer from "./referrals/reducer";
import educationReducer from "./educations/reducer";
import notificationReducer from "./notifications/reducer";

export default combineReducers({
    dashboard: dashboardReducer,
    earnings: earningsReducer,
    withdrawals: withdrawalsReducer,
    referrals: referralsReducer,
    educations: educationReducer,
    notifications: notificationReducer,
});