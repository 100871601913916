import React from 'react'

export const ProgressTracker = ({current}) => {
  return (
    <div className="my-rounded-wrapper mb-5 max-w-md mx-auto">
        <div className="rounded-outer" style={{"border": "1px solid #05083F"}}>
            <div className="w-2 h-2 rounded-full progress-bar-bg-black"></div>
        </div>
        <hr style={{"backgroundColor": `${current > 0 && "#05083F"}`}} />
        <div className="rounded-outer" style={{"border": `${current >= 1 && "1px solid #05083F"}`}}>
            <div className={`w-2 h-2 rounded-full ${current >= 1 ? 'progress-bar-bg-black' : "new-slider-grey"}`}></div>
        </div>
        <hr style={{"backgroundColor": `${current >= 2 && "#05083F"}`}}/>
        <div className="rounded-outer" style={{"border": `${current >= 2 && "1px solid #05083F"}`}}>
            <div className={`w-2 h-2 rounded-full ${current >= 2 ? 'progress-bar-bg-black' : "new-slider-grey"}`}></div>
        </div>
        <hr style={{"backgroundColor": `${current >= 3 && "#05083F"}`}}/>
        <div className="rounded-outer" style={{"border": `${current >= 3 && "1px solid #05083F"}`}}>
            <div className={`w-2 h-2 rounded-full ${current >= 3 ? 'progress-bar-bg-black' : "new-slider-grey"}`}></div>
        </div>
    </div>
  )
}

export const Steps = ({ current }) => {
    return (
        <div className="flex flex-row justify-between max-w-md mx-auto">
            <div className={`${current === 1 ? 'step-dark-grey' : 'step-light-grey'}`}>Verify Number</div>
            <div className={`${current === 2 ? 'step-dark-grey' : 'step-light-grey'}`}>Create Pin</div>
            <div className={`${current === 2 ? 'step-dark-grey' : 'step-light-grey'}`}>Review</div>
            <div className={`${current === 3 ? 'step-dark-grey' : 'step-light-grey'}`}>Bank Details</div>
        </div>
    )
};