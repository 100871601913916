import * as actions from "./actionTypes";
import { toast } from 'react-toastify';

const initialState = {
    loading: false,
    loginUser: {},
    // loggedIn: false,     // moved to persist
    // data: {},            // moved to persist
    // signingUp: false,     // moved to persist
    // signupUser: {},      // moved to persist
    userPin: {},
    activationOTP: {},
    sendActivationPinLoading: false,
    sendActivationPinSuccess: false,
    sendActivationPinResponse: {},
    savePasswordResetPin: false,
    forgotPassword: false,
    forgotPasswordForm: {},
    resetPasswordLoading: false,
    resetPassword: false,
    validateActivationPinLoading: false,
    validateActivationPinSuccess: false,
    validateActivationPinResponse: {},
    unsubscribeAccountLoading: false,
    unsubscribeAccount: false,

    passwordSetLoading: false,
    passwordSet: {},

    emailOtpSendLoading: false,
    emailOtpSend: {},
    emailOtpValidationLoading: false,
    emailOtpValidation: {},
    emailUpdateLoading: false,
    emailUpdate: {},
};


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.LOGIN_USER:
            state = {
                ...state,
                loading: false,
                loginUser: action.payload,
            }
            return state;


        case actions.LOGIN_USER_START:
            state = {
                ...state,
                loading: true,
                loginUser: {},
            }
            return state;


        case actions.LOGIN_USER_FAILED:
            if (action.payload.message === "Pin does not match") {
                toast.error("Invalid Phone Number or PIN");
            }
            else if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loading: false,
                loginUser: {},
            }
            return state;


        case actions.LOGOUT_USER:
            state = {
                ...state,
            }
            return state;


        case actions.SEND_ACTIVATION_PIN:
            toast.success(action.payload.message);
            state = {
                ...state,
                sendActivationPinLoading: false,
                sendActivationPinSuccess: true,
                sendActivationPinResponse: { ...action.payload },
            }
            return state;


        case actions.SEND_ACTIVATION_PIN_START:
            state = {
                ...state,
                sendActivationPinLoading: true,
                sendActivationPinSuccess: false,
                sendActivationPinResponse: {},
            }
            return state;


        case actions.SEND_ACTIVATION_PIN_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                sendActivationPinLoading: false,
                sendActivationPinSuccess: false,
                sendActivationPinResponse: { ...action.payload },
            }
            return state;
        // validate activation pin
        case actions.VALIDATE_ACTIVATION_PIN_SUCCESS:
            state = {
                ...state,
                validateActivationPinLoading: false,
                validateActivationPinSuccess: true,
                validatedPinDetails: { ...action.payload },
            }
            return state;


        case actions.VALIDATE_ACTIVATION_PIN_START:
            state = {
                ...state,
                validateActivationPinLoading: true,
                validateActivationPinSuccess: false,
                validateActivationPinResponse: {},
            }
            return state;


        case actions.VALIDATE_ACTIVATION_PIN_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                validateActivationPinLoading: false,
                activateActivationPinSuccess: false,
                validateActivationPinResponse: { ...action.payload },
            }
            return state;


        case actions.SET_LOGIN_PIN:
            toast.success("Your account is now active");
            state = {
                ...state,
                loading: false,
                
            }
            return state;


        case actions.SET_LOGIN_PIN_START:
            state = {
                ...state,
                loading: true,
            }
            return state;


        case actions.SET_LOGIN_PIN_FAILED:
            if (action.payload.message === "Activation Pin does not match") {
                toast.error("Incorrect PIN. Please recheck and try again.");
            }
            else if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loading: false,
            }
            return state;


        case actions.SAVE_ACTIVATION_PIN:
            state = {
                ...state,
                activationOTP: {
                    otp: action.payload.otp,
                },
            }
            return state;
        
        case actions.SAVE_NEW_PIN:
            state = {
                ...state,
                userPin: {
                    pin: action.payload.pin,
                },
            }
            return state;

        


        case actions.FORGOT_PASSWORD:
            toast.success(action.payload.message);
            state = {
                ...state,
                loading: false,
                forgotPassword: true,
            }
            return state;


        case actions.FORGOT_PASSWORD_START:
            state = {
                ...state,
                loading: true,
                forgotPassword: false,
            }
            return state;


        case actions.FORGOT_PASSWORD_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loading: false,
                forgotPassword: false,
            }
            return state;


        case actions.FORGOT_PASSWORD_RESET:
            state = {
                ...state,
                forgotPassword: false,
            }
            return state;


        case actions.SAVE_FORGOT_PASSWORD_FORM:
            state = {
                ...state,
                forgotPasswordForm: action.payload,
            }
            return state;

        case actions.RESET_PASSWORD:
            toast.success("Password reset successful");
            state = {
                ...state,
                resetPasswordLoading: false,
                resetPassword: true,
            }
            return state;


        case actions.RESET_PASSWORD_START:
            state = {
                ...state,
                resetPasswordLoading: true,
                resetPassword: false,
            }
            return state;


        case actions.RESET_PASSWORD_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                resetPasswordLoading: false,
                resetPassword: false,
            }
            return state;


        case actions.RESET_PASSWORD_RESET:
            state = {
                ...state,
                resetPassword: false,
            }
            return state;



        case actions.SET_PASSWORD:
            toast.success(action.payload.message);
            state = {
                ...state,
                passwordSetLoading: false,
                passwordSet: action.payload,
            }
            return state;


        case actions.SET_PASSWORD_START:
            state = {
                ...state,
                passwordSetLoading: true,
                passwordSet: {},
            }
            return state;


        case actions.SET_PASSWORD_FAILED:
            toast.error(action.payload.message);
            state = {
                ...state,
                passwordSetLoading: false,
                passwordSet: action.payload,
            }
            return state;


        case actions.SET_PASSWORD_RESET:
            state = {
                ...state,
                passwordSetLoading: false,
                passwordSet: {},
            }
            return state;


        case actions.EMAIL_SEND_OTP:
            state = {
                ...state,
                emailOtpSendLoading: false,
                emailOtpSend: action.payload,
            }
            return state;


        case actions.EMAIL_SEND_OTP_START:
            state = {
                ...state,
                emailOtpSendLoading: true,
                emailOtpSend: {},
            }
            return state;


        case actions.EMAIL_SEND_OTP_FAILED:
            toast.error(action.payload.message);
            state = {
                ...state,
                emailOtpSendLoading: false,
                emailOtpSend: action.payload,
            }
            return state;


        case actions.EMAIL_SEND_OTP_RESET:
            state = {
                ...state,
                emailOtpSendLoading: false,
                emailOtpSend: {},
            }
            return state;


        case actions.EMAIL_VERIFY_OTP:
            state = {
                ...state,
                emailOtpValidationLoading: false,
                emailOtpValidation: action.payload,
            }
            return state;


        case actions.EMAIL_VERIFY_OTP_START:
            state = {
                ...state,
                emailOtpValidationLoading: true,
                emailOtpValidation: {},
            }
            return state;


        case actions.EMAIL_VERIFY_OTP_FAILED:
            toast.error(action.payload.message);
            state = {
                ...state,
                emailOtpValidationLoading: false,
                emailOtpValidation: action.payload,
            }
            return state;


        case actions.EMAIL_VERIFY_OTP_RESET:
            state = {
                ...state,
                emailOtpValidationLoading: false,
                emailOtpValidation: {},
            }
            return state;


        case actions.UPDATE_EMAIL:
            state = {
                ...state,
                emailUpdateLoading: false,
                emailUpdate: action.payload,
            }
            return state;


        case actions.UPDATE_EMAIL_START:
            state = {
                ...state,
                emailUpdateLoading: true,
                emailUpdate: {},
            }
            return state;


        case actions.UPDATE_EMAIL_FAILED:
            toast.error(action.payload.message);
            state = {
                ...state,
                emailUpdateLoading: false,
                emailUpdate: action.payload,
            }
            return state;


        case actions.UPDATE_EMAIL_RESET:
            state = {
                ...state,
                emailUpdateLoading: false,
                emailUpdate: {},
            }
            return state;




        case actions.UNSUBSCRIBE_EMAIL:
            state = {
                ...state,
                unsubscribeAccountLoading: false,
                unsubscribeAccount: true,
            }
            return state;


        case actions.UNSUBSCRIBE_EMAIL_START:
            state = {
                ...state,
                unsubscribeAccountLoading: true,
                unsubscribeAccount: false,
            }
            return state;


        case actions.UNSUBSCRIBE_EMAIL_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                unsubscribeAccountLoading: false,
                unsubscribeAccount: false,
            }
            return state;



        default:
            return state;
    }
}