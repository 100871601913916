import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const UnsecurePageAlert = () => {
    return (
        <div className="w-screen min-h-screen flex bg-white border-t-8 border-red-500">
            <div className="m-auto px-8 text-center max-w-lg">

                <div className="my-6">
                    <div>
                        <FontAwesomeIcon icon="exclamation-circle" className="text-9xl text-red-500" />
                    </div>
                    <div className="mt-8 font-boing font-bold text-2xl sm:text-3xl">
                        FAKE &nbsp; WEBSITE &nbsp; DETECTED
                    </div>
                    <div className="mt-4 text-gray-500">
                        If you are seeing this page, the website you are currently on is <span className="font-bold px-1.5 py-px bg-red-500 text-white">FAKE</span>.
                        The real website is <span className="font-bold text-black">https://app.earnipay.com</span>
                        
                        <div className="mt-8 text-left">
                            What to do:
                            <ol className="mt-2 ml-4 list-decimal">
                                <li>
                                    Do not click any buttons or links on this website
                                </li>
                                <li>
                                    Do not fill or submit any of your information
                                </li>
                                <li>
                                    Report this fake website to us via email <span className="font-bold text-black">hello@earnipay.com</span>
                                </li>
                                <li>
                                    Leave this fake website immediately
                                </li>
                                <li>
                                    Visit the real website: <span className="font-bold text-black">https://app.earnipay.com</span>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default UnsecurePageAlert;