import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as auth from "store/auth/user/action";

class Unsubscribe extends Component {
    state = { 
        pageAccess: "",
        email: "",
    }


    componentDidMount() {
        let pageAccess = false;
        if (("type" in this.props.match.params) && ("encodedEmail" in this.props.match.params)){
            const {type, encodedEmail} = this.props.match.params;
            try{
                const email = window.atob(encodedEmail);
                // console.log("email", email);
                this.setState({email});

                if ((type !== "") && (email !== "")) {   // do in_array check for type also
                    pageAccess = true;
                    if (type === "earnings"){
                        // alert("all good");
                        this.props.unsubscribeEarningsEmail(encodedEmail);
                    }
                }
            }
            catch (e){}
        }
        this.setState({pageAccess});
    }


    render() { 

        const { email } = this.state;

        return (
            <div className="w-full p-8 sm:p-14 md:my-auto">
                <div className="pb-14 md:pt-20 lg:pl-4 lg:pr-4">

                    {(this.state.pageAccess === false) &&
                        <div className="mt-8 mx-auto text-center max-w-xs">
                            <div className="w-24 h-24 md:w-32 md:h-32 rounded-full bg-red-500 flex mx-auto">
                                <FontAwesomeIcon icon="exclamation-circle" className="text-7xl text-white m-auto" />
                            </div>
                            <div className="mt-8 font-boing font-semibold text-2xl sm:text-3xl">
                                Link Broken
                            </div>
                            <div className="mt-2">
                                Sorry, this link is broken.&nbsp;
                                <br className="hidden md:block" />
                                Please recheck the url and try again later, or contact our support team.
                            </div>
                            <Link to="/" className="btn btn-lg btn-block btn-ep-blue mt-8">
                                Dismiss
                            </Link>
                        </div>
                    }

                    {(this.state.pageAccess === true) &&
                        <>
                            {(this.props.unsubscribeAccountLoading === true) &&
                                <div className="w-96 mx-auto md:py-24 md:mt-6">
                                    <div className="table-info">
                                        <FontAwesomeIcon icon="spinner" spin style={{"fontSize":"4.5rem"}} />
                                        <div className="mt-2 font-bold text-2xl">
                                            Unsubscribing...
                                        </div>
                                        <div className="hidden mt-2">
                                            Please wait while we unsubscribing your email address.
                                        </div>
                                    </div>
                                </div>
                            }

                            {(this.props.unsubscribeAccountLoading === false) && (this.props.accountUnsubscribed === false) &&
                                <div className="mt-8 mx-auto text-center max-w-xs">
                                    <div className="w-32 h-32 rounded-full bg-red-500 flex mx-auto">
                                        <FontAwesomeIcon icon="exclamation-circle" className="text-7xl text-white m-auto" />
                                    </div>
                                    <div className="mt-8 font-boing font-semibold text-2xl sm:text-3xl">
                                        Unable to Unsubscribe
                                    </div>
                                    <div className="mt-2">
                                        An error occurred while unsubscribing your email address.
                                        Please try again later, or contact our support team.
                                    </div>
                                    <Link to="/" className="btn btn-lg btn-block btn-ep-blue mt-8">
                                        Dismiss
                                    </Link>
                                </div>
                            }

                            {(this.props.unsubscribeAccountLoading === false) && (this.props.accountUnsubscribed === true) &&
                                <div className="mt-8 mx-auto text-center max-w-xs">
                                    <div className="w-32 h-32 rounded-full bg-ep-yellow flex mx-auto">
                                        <FontAwesomeIcon icon="check" className="text-7xl text-white m-auto" />
                                    </div>
                                    <div className="mt-8 font-boing font-semibold text-2xl sm:text-3xl text-ep-yellow">
                                        Email Unsubscribed
                                    </div>
                                    <div className="mt-2">
                                        Your email&nbsp;
                                        <span>{email}</span>
                                        &nbsp;has been unsubscribed from daily earnings email notification.
                                    </div>
                                    <Link to="/" className="btn btn-lg btn-block btn-ep-blue mt-8">
                                        Dismiss
                                    </Link>
                                </div>
                            }
                        </>
                    }

                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    unsubscribeAccountLoading: state.auth.user.unsubscribeAccountLoading,
    accountUnsubscribed: state.auth.user.unsubscribeAccount,
});

const mapDispatchToProps = (dispatch) => ({
    unsubscribeEarningsEmail: (email) => dispatch(auth.unsubscribeEarningsEmail(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Unsubscribe);