export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_START = "UPDATE_PROFILE_START";
export const UPDATE_PROFILE_FAILED = "UPDATE_PROFILE_FAILED";
export const UPDATE_PROFILE_RESET = "UPDATE_PROFILE_RESET";

export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const UPDATE_PASSWORD_START = "UPDATE_PASSWORD_START";
export const UPDATE_PASSWORD_FAILED = "UPDATE_PASSWORD_FAILED";
export const UPDATE_PASSWORD_RESET = "UPDATE_PASSWORD_RESET";

export const GET_WALLET_DETAILS = "GET_WALLET_DETAILS";
export const GET_WALLET_DETAILS_START = "GET_WALLET_DETAILS_START";
export const GET_WALLET_DETAILS_FAILED = "GET_WALLET_DETAILS_FAILED";

export const GET_USER_INFO = "GET_USER_INFO";
export const GET_USER_INFO_START = "GET_USER_INFO_START";
export const GET_USER_INFO_FAILED = "GET_USER_INFO_FAILED";
