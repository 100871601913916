import store from "store/store";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "./components/layouts/authLayout";
import EmployeeLayout from "./components/layouts/employeeLayout";
// import EmployeeTestLayout from "components/layouts/employeeTestLayout";
import NullLayout from "./components/layouts/nullLayout";
import Error401 from "./components/misc/error401";
import Error404 from "./components/misc/error404";
import EmailUnsubscribe from "./components/pages/emails/unsubscribe";
import GetStarted from "./components/pages/auth/getStarted";
import GetStartedNull from "./components/pages/auth/getStartedNull";
import GetStartedOTPCreatePin from "./components/pages/auth/getStartedOTPCreatePin";
import GetStartedConfirmPin from "components/pages/auth/GetStartedConfirmPin";
import GetStartedPersonalDetails from "./components/pages/auth/getStartedPersonalDetails";
import GetStartedBankDetails from "./components/pages/auth/getStartedBankDetails";
import GetStartedSuccess from "./components/pages/auth/getStartedSuccess";
import GetStartedCreatePin from "components/pages/auth/getStartedCreatePin";
import GetStartedReferral from "components/pages/auth/GetStartedReferral";
import VerifySignUpPin from "components/pages/auth/VerifySignUpPin";
import Success from "components/pages/auth/Success";
import Login from "./components/pages/auth/login";
import LoginWithPin from "./components/pages/auth/LoginWithPin";
import ForgotPassword from "components/pages/auth/forgotPassword";
import ResetPassword from "components/pages/auth/resetPassword";
import CreateNewPin from "components/pages/auth/CreateNewPin";
import ConfirmNewPin from "components/pages/auth/ConfirmPin";
// import ChangePassword from "components/pages/auth/ChangePassword";
import ConfirmChangePassword from "components/pages/auth/ConfirmChangePassword";
import VerifyOtp from "components/pages/auth/VerifyOtpForgotPassword";
import ResetPasswordSuccess from "components/pages/auth/resetPasswordSuccess";
import dashboard from "./components/pages/employee/dashboard/index";
import withdrawalsList from "./components/pages/employee/withdrawals/list";

/*
import earningsList from "./components/pages/employee/earnings/list";
import referralsList from "./components/pages/employee/referrals/list";
import eduarticles from "./components/pages/employee/educations/index";
import Article from "./components/pages/employee/educations/ArticlePage";
import OnboardingScreen from "./components/pages/employee/newEducation/Onboarding";
import Education from "./components/pages/employee/newEducation/Education";
import EduQuiz from "./components/pages/employee/newEducation/OnboardingQuiz";
import NewArticle from "./components/pages/employee/newEducation/Article";
import Test from "./components/pages/employee/newEducation/Test";
import PortfolioCourses from "./components/pages/employee/newEducation/newEducation/PortfolioCourses";
import ArticleList from "./components/pages/employee/newEducation/newEducation/ArticleList";
import RewardList from "components/pages/employee/rewards/RewardList";
import profile from "components/pages/employee/account/profile";
import Notifications from "components/pages/employee/notifications/Notifications";
import Notification from "components/pages/employee/notifications/Notification";
import Earnipay from "components/pages/employee/earnipay";
*/

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
// import QuizSuccess from "components/pages/employee/newEducation/QuizSuccess";

library.add(fas);

const RouteLayout = ({ component: Component, layout: Layout, authUser, authRole, allowInactiveUser, ...rest }) => (
	<Route {...rest} 	render={(props) => {
		const user = store.getState().auth.userPersist;
		const user_role = "employee";
		const user_active = true;

		if (user.loggedIn) {
			// Freshdesk contact-us form widget
			/*
            const enrolledMemo = useMemo(() => (courseSubscribed?.docs || []), [courseSubscribed]);

            const setEnrolledToTrue = () => {
                if (enrolledMemo.length === 1) setEnrolled(true);
            };
            const FreshworksWidgetPrefill = `
                FreshworksWidget('identify', 'ticketForm', {
                    name: '` + user.data.name + `',
                    email: '` + user.data.email + `',
                })
            `;
            window.location.replace("javascript:void(" + FreshworksWidgetPrefill + ")");
            */
				// Freshdesk chat support widget
				/*
            if (user) {
                // To set unique user id in your system when it is available
                window.fcWidget.setExternalId(user.data._id);
                // To set user name
                window.fcWidget.user.setFirstName(user.data.name);
                // To set user email
                window.fcWidget.user.setEmail(user.data.email);
            }
            */
			}

			if (authUser) {
				if (authUser === "loggedIn") {
					if (user.loggedIn === true) {
						if (user_active === true) {
							if (authRole) {
								if (authRole.includes(user_role)) {
									return (
										<Layout>
											<Component {...props} />
										</Layout>
									);
								} else {
									// alert("Access denied due to user role");
									return <Redirect to="/401" />;
								}
							} else {
								// alert("no roles required, you only need to be logged in");
								return (
									<Layout>
										<Component {...props} />
									</Layout>
								);
							}
						} else {
							if (allowInactiveUser) {
								// alert("allow inactive user");
								return (
									<Layout>
										<Component {...props} />
									</Layout>
								);
							} else {
								// alert("not yet approved by admin");
								return <Redirect to="/get-started/info/legal" />;
							}
						}
					} else {
						// alert("need to be logged in");
						return <Redirect to="/login" />;
					}
				} else if (authUser === "loggedOut") {
					if (user.loggedIn === false) {
						return (
							<Layout>
								<Component {...props} />
							</Layout>
						);
					} else {
						// alert("already logged in; need to be logged out to access");
						return <Redirect to="/dashboard" />;
					}
				}
			} else {
				// public page, no auth-yes or auth-no required
				return (
					<Layout>
						<Component {...props} />
					</Layout>
				);
			}
		}}
	/>
);

function App() {
	return (
		<BrowserRouter>
			<Switch>
				<RouteLayout exact path="/account/emails/unsubscribe/:type/:encodedEmail" layout={AuthLayout} component={EmailUnsubscribe} />

				<RouteLayout exact authUser="loggedOut" path="/login" layout={AuthLayout} component={Login} />
				<RouteLayout exact authUser="loggedOut" path="/login/pin" layout={AuthLayout} component={LoginWithPin} />
				<RouteLayout exact authUser="loggedOut" path="/get-started" layout={AuthLayout} component={GetStarted} />
				<RouteLayout exact authUser="loggedOut" path="/get-started/null" layout={AuthLayout} component={GetStartedNull} />
				<RouteLayout exact authUser="loggedOut" path="/get-started/referral/:referralCode" layout={AuthLayout} component={GetStarted} />
				<RouteLayout exact authUser="loggedOut" path="/get-started/verify-token" layout={AuthLayout} component={GetStartedOTPCreatePin} />
				<RouteLayout exact authUser="loggedOut" path="/get-started/create-pin" layout={AuthLayout} component={GetStartedCreatePin} />
				<RouteLayout exact authUser="loggedOut" path="/get-started/confirm-pin" layout={AuthLayout} component={GetStartedConfirmPin} />
				<RouteLayout exact authUser="loggedOut" path="/get-started/personal-details" layout={AuthLayout} component={GetStartedPersonalDetails} />
				<RouteLayout exact authUser="loggedOut" path="/get-started/bank-details" layout={AuthLayout} component={GetStartedBankDetails} />
				<RouteLayout exact authUser="loggedOut" path="/get-started/referral-code" layout={NullLayout} component={GetStartedReferral} />
				<RouteLayout exact authUser="loggedOut" path="/get-started/success" layout={NullLayout} component={GetStartedSuccess} />
				<RouteLayout exact authUser="loggedOut" path="/get-started/account-success" layout={NullLayout} component={Success} />

				<RouteLayout exact authUser="loggedOut" path="/forgot-password" layout={AuthLayout} component={ForgotPassword} />
				<RouteLayout exact authUser="loggedOut" path="/reset-password" layout={AuthLayout} component={ResetPassword} />
				<RouteLayout exact authUser="loggedOut" path="/reset-password/success" layout={NullLayout} component={ResetPasswordSuccess} />
				<RouteLayout exact authUser="loggedOut" path="/confirm-reset-password" layout={AuthLayout} component={ConfirmChangePassword} />
				<RouteLayout exact authUser="loggedOut" path="/verify-otp" layout={AuthLayout} component={VerifyOtp} />
				<RouteLayout exact authUser="loggedOut" path="/get-started/verify-phone" layout={AuthLayout} component={VerifySignUpPin} />
				<RouteLayout exact authUser="loggedOut" path="/get-started/create-new-pin" layout={AuthLayout} component={CreateNewPin} />
				<RouteLayout exact authUser="loggedOut" path="/get-started/confirm-new-pin" layout={AuthLayout} component={ConfirmNewPin} />
				{/* <RouteLayout exact authUser="loggedOut" path="/get-started/reset-password" layout={AuthLayout} component={ResetPassword} /> */}

				<RouteLayout exact authUser="loggedIn" authRole={["employee"]} path="/dashboard" layout={EmployeeLayout} component={dashboard} />
				<RouteLayout exact authUser="loggedIn" authRole={["employee"]} path="/withdrawals" layout={EmployeeLayout} component={withdrawalsList} />
				{/* <RouteLayout exact authUser="loggedIn" authRole={["employee"]} path="/earnings" layout={EmployeeLayout} component={earningsList} /> */}
				{/* <RouteLayout exact authUser="loggedIn" authRole={["employee"]} path="/rewards" layout={EmployeeLayout} component={RewardList} /> */}
				{/* <RouteLayout exact authUser="loggedIn" authRole={["employee"]} path="/referrals" layout={EmployeeLayout} component={referralsList} /> */}
				{/* <RouteLayout exact authUser="loggedIn" authRole={["employee"]} path="/account" layout={EmployeeLayout} component={profile} /> */}
				{/* <RouteLayout exact authUser="loggedIn" authRole={["employee"]} path="/earnipay" layout={EmployeeLayout} component={Earnipay} /> */}
				
				{/* New Education */}
				{/* 
				<RouteLayout exact authUser="loggedIn" authRole={["employee"]} path="/mint" layout={EmployeeLayout} component={OnboardingScreen} />
				<RouteLayout exact authUser="loggedIn" authRole={["employee"]} path="/mint/quiz" layout={EmployeeTestLayout} component={EduQuiz} />
				<RouteLayout exact authUser="loggedIn" authRole={["employee"]} path="/mint/quiz/success" layout={EmployeeTestLayout} component={QuizSuccess} />
				<RouteLayout exact authUser="loggedIn" authRole={["employee"]} path="/mint/courses/articles/:id" layout={EmployeeLayout} component={NewArticle} />
				<RouteLayout exact authUser="loggedIn" authRole={["employee"]} path="/mint/courses" layout={EmployeeLayout} component={Education} />
				<RouteLayout exact authUser="loggedIn" authRole={["employee"]} path="/mint/portfolio/:id/courses" layout={EmployeeLayout} component={PortfolioCourses} />
				<RouteLayout exact authUser="loggedIn" authRole={["employee"]} path="/mint/courses/:id" layout={EmployeeLayout} component={ArticleList} />
				<RouteLayout exact authUser="loggedIn" authRole={["employee"]} path="/mint/courses/article/:id/test" layout={EmployeeTestLayout} component={Test} />
				 */}

				{/* old financial education */}
				{/* 
				<RouteLayout exact authUser="loggedIn" authRole={["employee"]} path="/educations/:category" layout={EmployeeLayout} component={eduarticles} />
				<RouteLayout exact authUser="loggedIn" authRole={["employee"]} path="/educations/" layout={EmployeeLayout} component={eduarticles} />
				<RouteLayout exact authUser="loggedIn" authRole={["employee"]} path="/educations/search/:keyword" layout={EmployeeLayout} component={eduarticles} />
				<RouteLayout exact authUser="loggedIn" authRole={["employee"]} path="/educations/articles/:id" layout={EmployeeLayout} component={Article} />
				<RouteLayout exact authUser="loggedIn" authRole={["employee"]} path="/notifications" layout={EmployeeLayout} component={Notifications} />
				<RouteLayout exact authUser="loggedIn" authRole={["employee"]} path="/notifications/:id" layout={EmployeeLayout} component={Notification} />
				 */}

				<RouteLayout exact path="/401" layout={NullLayout} component={Error401} />
				<RouteLayout exact path="/404" layout={NullLayout} component={Error404} />
				<RouteLayout exact authUser="loggedIn" authRole={["employee"]} path="/" layout={EmployeeLayout} component={dashboard} />
				
				<Redirect from="/referrals" to="/" />
				
				<Redirect from="/mint" to="/" />
				<Redirect from="/account" to="/" />
				<Redirect from="/educations" to="/" />
				<Redirect from="/notifications" to="/" />

				<Redirect to="/404" />
			</Switch>
		</BrowserRouter>
	);
}

export default App;
