import * as actions from "./actionTypes";
import { toast } from 'react-toastify';

const initialState = {
    loading: false,
    loadingMarkAllRead: false,
    notifications: [],
    notification: {},
    markAllAsRead: false,
    markAsRead: false,
    loadingDelete: false,
    deleted: false,
};


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.GET_NOTIFICATIONS_SUCCESS:
            state = {
                ...state,
                loading: false,
                notifications: action.payload.data.docs,
            }
            return state;


        case actions.GET_NOTIFICATIONS_START:
            state = {
                ...state,
                loading: true,
            }
            return state;


        case actions.GET_NOTIFICATIONS_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loading: false,
                notifications: {},

            }
            return state;


        case actions.GET_NOTIFICATION_SUCCESS:
            state = {
                ...state,
                loading: false,
                notification: { ...action.payload.data }
            }
            return state;


        case actions.GET_NOTIFICATION_START:
            state = {
                ...state,
                loading: true,
            }
            return state;


        case actions.GET_NOTIFICATION_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loading: false,
                notification: {},
            }
            return state;

        
        case actions.BULK_UPDATE_NOTIFICATION_SUCCESS:
        state = {
            ...state,
            loadingMarkAllRead: false,
            // notification: { ...action.payload }
            markAllAsRead: true,
        }
        return state;

        case actions.BULK_UPDATE_NOTIFICATION_START:
            state = {
                ...state,
                loadingMarkAllRead: true,
            }
            return state;

        case actions.BULK_UPDATE_NOTIFICATION_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loadingMarkAllRead: false,
                markAllAsRead: false,
                notification: {},
            }
            return state;
        
        case actions.UPDATE_NOTIFICATION_SUCCESS:
            state = {
                ...state,
                loading: false,
                markAsRead: true,
            }
            return state;
    
        case actions.UPDATE_NOTIFICATION_START:
            state = {
                ...state,
                loading: true,
            }
            return state;

        case actions.UPDATE_NOTIFICATION_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loading: false,
                markAsRead: false,
                notification: {},
            }
            return state;
        
        case actions.DELETE_NOTIFICATION_SUCCESS:
            state = {
                ...state,
                loadingDelete: false,
                deleted: true,
            }
            return state;
    
        case actions.DELETE_NOTIFICATION_START:
            state = {
                ...state,
                loadingDelete: true,
            }
            return state;

        case actions.DELETE_NOTIFICATION_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loadingDelete: false,
                deleted: false,
            }
            return state;
        
        default:
            return state;
    }
};