// import http, {useLoginJwt, dontUseLoginJwt} from "services/httpService"
import axios from "axios";
import * as config from "config";
import { toast } from "react-toastify";
import crypto from "crypto";
import { AES, enc, lib, SHA256 } from "crypto-js";
import * as userActions from "store/auth/user/action";

const api = store => next => async action => {


    const httpService = (useLoginJwt, token) => {
        let http;
        if ((typeof useLoginJwt !== "undefined") && (useLoginJwt === false)) {
            http = axios.create();
        }
        else {
            http = axios.create({
                headers: {
                    Authorization: token,
                }
            });
            // console.log(token);
        }
        return http;
    }


    const getBaseUrl = (baseUrl) => {
        let apiBaseUrl;
        if (typeof baseUrl !== "undefined") {
            // console.log("use custom", baseUrl);
            apiBaseUrl = baseUrl;
        }
        else {
            // console.log("use default", config.apiBaseUrl);
            apiBaseUrl = config.apiBaseUrl;
        }
        return apiBaseUrl;
    }


    const getToken = (customToken) => {
        let apiToken;
        if (typeof customToken !== "undefined") {
            // console.log("use custom", customToken);
            apiToken = customToken;
        }
        else {
            const loginJwt = store.getState().auth.userPersist.data.token;
            // console.log("use loginJwt", loginJwt);
            apiToken = loginJwt;
        }
        return apiToken;
    }


    const isUserTokenActive = () => {
        const tokenExpiryValue = store.getState().auth.userPersist.data.tokenExpiry;
        if (tokenExpiryValue !== undefined && tokenExpiryValue !== null){
            const tokenExpiryTime = (parseInt(tokenExpiryValue) * 1000);
            const currentTime = Date.now();
            
            if (currentTime > tokenExpiryTime){
                toast.error("Expired Session, Please Login Again", {
                    toastId: "expired",
                });
                setTimeout(() => {
                    store.dispatch({ type: userActions.logoutUser });
                    return false;
                }, 1000)
            }
            else{
                return true;
            }
        }
        // console.log("user isn't loggedin yet");
        return true;
    }


    let encryptedUrls = [];
    const getEncryptedPayload = (data, url, useEncryption) => {
        let params = data;
        if (useEncryption && useEncryption === true){
            // console.log("encryption here");
            encryptedUrls.push(url);
            const encryptedData = encryptPayload(data);
            return encryptedData;
        }
        return params;
    }

    
    const getDecryptedResponse = (response) => {
        const {config, data} = response;
        const endpointUrl = config.baseURL.toString() + config.url.toString();
        const endpointUrlIndex = encryptedUrls.indexOf(endpointUrl);
        if (endpointUrlIndex > -1){
            // console.log("decryption here");
            encryptedUrls.splice(endpointUrlIndex, 1);
            const decryptedData = decryptPayload(data);
            return JSON.parse(decryptedData) ?? {};
        }
        else{
            return data;
        }
    }

    
    const encryptPayload = (data) => {
        const secret = process.env.REACT_APP_API_DECRYPT_KEY;
        const iv = lib.WordArray.random(16);
        const key = SHA256(secret);
        const value = enc.Utf8.parse(JSON.stringify(data));
        const encryptedHash = AES.encrypt(value, key, { iv }).toString();
        const encryptedValue = enc.Base64.parse(encryptedHash).toString(enc.HEX);
      
        return { iv: iv.toString(), encryptedValue };
    };
    
    
    const decryptPayload = (payload) => {
        const secret = process.env.REACT_APP_API_DECRYPT_KEY;
        const { encryptedValue, iv } = payload;
        const IV = Buffer.from(iv, 'hex');
        const encryptedPayload = Buffer.from(encryptedValue, 'hex');
        
        const key = crypto
          .createHash('sha256')
          .update(secret)
          .digest();
      
        const decipher = crypto.createDecipheriv('aes-256-cbc', key, IV);
        let decryptedValue = decipher.update(encryptedPayload);
      
        decryptedValue = Buffer.concat([decryptedValue, decipher.final()]);
        if (decryptedValue.toString() === 'undefined') return undefined;
        return JSON.parse(decryptedValue.toString());
    };


    if (action.type !== config.apiRequestStart) {
        next(action);
    }
    else {

        if (isUserTokenActive() === true){

        const { baseUrl, url, method, data, onStart, onSuccess, onError, customToken, useLoginJwt, useEncryption } = action.payload;
        if (onStart) {
            store.dispatch({ type: onStart });
        }

        const apiBaseUrl = getBaseUrl(baseUrl);
        // const finEduAPIBaseUrl = getFinEduBaseUrl(finEduBaseUrl);
        const token = getToken(customToken);
        const params = getEncryptedPayload(data, (apiBaseUrl.toString() + url.toString()), useEncryption);
        const http = httpService(useLoginJwt, token);


        next(action);   
    // for debugger logging purpose (in chrome Redux dev tools)

        await http.request({
            baseURL: apiBaseUrl ,
            url,
            method,
            data: params,
        }).then((response) => {
            store.dispatch({ type: config.apiRequestSuccess, payload: response }); 
            if (onSuccess) {
                // store.dispatch({ type: onSuccess, payload: response.data });
                store.dispatch({ type: onSuccess, payload: getDecryptedResponse(response) });
            }
        }).catch((error) => {
            // for debugger logging purpose
            store.dispatch({ type: config.apiRequestFailed, payload: error });                 
            if (onError) {
                if (error.response) {
                    // store.dispatch({ type: onError, payload: error.response.data });
                    store.dispatch({ type: onError, payload: getDecryptedResponse(error.response) });
                }
                else if (error.request) {
                    if (navigator.onLine) {
                        store.dispatch({
                            type: onError,
                            payload: {
                                message: "Error: Invalid request. Please try again later."
                            }
                        });
                    }
                    else {
                        store.dispatch({
                            type: onError,
                            payload: {
                                message: "No Internet Connection"
                            }
                        });
                    }
                }
                else {
                    store.dispatch({
                        type: onError,
                        payload: {
                            message: "An error occurred. Please try again later."
                        }
                    });
                }
            }
        });

        }
    }

}

export default api;