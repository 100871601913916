export const GET_ALL_POSTS_SUCCESS = "GET_ALL_POSTS_SUCCESS";
export const GET_ALL_POSTS_START = "GET_ALL_POSTS_START";
export const GET_ALL_POSTS_FAILED = "GET_ALL_POSTS_FAILED";

// get all categories
export const GET_ALL_CATEGORIES_SUCCESS = "GET_ALL_CATEGORIES_SUCCESS";
export const GET_ALL_CATEGORIES_START = "GET_ALL_CATEGORIES_START";
export const GET_ALL_CATEGORIES_FAILED = "GET_ALL_CATEGORIES_FAILED";

// get all categories
export const GET_POST_SUCCESS = "GET_POST_SUCCESS";
export const GET_POST_START = "GET_POST_START";
export const GET_POST_FAILED = "GET_POST_FAILED";
export const RELOAD_PAGE_RESET = "RELOAD_PAGE_RESET";

// new financial education services
//FES/get-onboarding-questions
export const GET_ONBOARDING_QUESTION_SUCCESS = "GET_ONBOARDING_QUESTION_SUCCESS";
export const GET_ONBOARDING_QUESTION_START = "GET_ONBOARDING_QUESTION_START";
export const GET_ONBOARDING_QUESTION_FAILED = "GET_ONBOARDING_QUESTION_FAILED";

//FES/get-article-test-questions
export const GET_ARTICLE_QUESTION_SUCCESS = "GET_ARTICLE_QUESTION_SUCCESS";
export const GET_ARTICLE_QUESTION_START = "GET_ARTICLE_QUESTION_START";
export const GET_ARTICLE_QUESTION_FAILED = "GET_ARTICLE_QUESTION_FAILED";

//FES/record article test
export const RECORD_ARTICLE_TEST_SUCCESS = "RECORD_ARTICLE_TEST_SUCCESS";
export const RECORD_ARTICLE_TEST_START = "RECORD_ARTICLE_TEST_START";
export const RECORD_ARTICLE_TEST_FAILED = "RECORD_ARTICLE_TEST_FAILED";

// FES/terms-of-the-day
export const GET_TERMS_OF_THE_DAY_SUCCESS = "GET_TERMS_OF_THE_DAY_SUCCESS";
export const GET_TERMS_OF_THE_DAY_START = "GET_TERMS_OF_THE_DAY_START";
export const GET_TERMS_OF_THE_DAY_FAILED = "GET_TERMS_OF_THE_DAY_FAILED";

// FES/all-courses
export const GET_ALL_COURSES_SUCCESS = "GET_ALL_COURSES_SUCCESS";
export const GET_ALL_COURSES_START = "GET_ALL_COURSES_START";
export const GET_ALL_COURSES_FAILED = "GET_ALL_COURSES_FAILED";

// FES/get course
export const GET_COURSE_SUCCESS = "GET_COURSE_SUCCESS";
export const GET_COURSE_START = "GET_COURSE_START";
export const GET_COURSE_FAILED = "GET_COURSE_FAILED";

// FES/all--enrolled-courses
export const GET_ALL_ENROLLED_COURSES_SUCCESS = "GET_ALL_ENROLLED_COURSES_SUCCESS";
export const GET_ALL_ENROLLED_COURSES_START = "GET_ALL_ENROLLED_COURSES_START";
export const GET_ALL_ENROLLED_COURSES_FAILED = "GET_ALL_ENROLLED_COURSES_FAILED";

// FES/courses-progress
export const GET_COURSES_PROGRESS_SUCCESS = "GET_COURSES_PROGRESS_SUCCESS";
export const GET_COURSES_PROGRESS_START = "GET_COURSES_PROGRESS_START";
export const GET_COURSES_PROGRESS_FAILED = "GET_COURSES_PROGRESS_FAILED";

// FES/post-onboarding-quiz
export const POST_ONBOARDING_QUIZ_SUCCESS = "POST_ONBOARDING_QUIZ_SUCCESS";
export const POST_ONBOARDING_QUIZ_START = "POST_ONBOARDING_QUIZ_START";
export const POST_ONBOARDING_QUIZ_FAILED = "POST_ONBOARDING_QUIZ_FAILED";

// FES/post course-progress
export const POST_COURSE_PROGRESS_SUCCESS = "POST_COURSE_PROGRESS_SUCCESS";
export const POST_COURSE_PROGRESS_START = "POST_COURSE_PROGRESS_START";
export const POST_COURSE_PROGRESS_FAILED = "POST_COURSE_PROGRESS_FAILED";

// FES/get-courses-in-portfolio
export const GET_PORTFOLIO_COURSES_SUCCESS = "GET_PORTFOLIO_COURSES_SUCCESS";
export const GET_PORTFOLIO_COURSES_START = "GET_PORTFOLIO_COURSES_START";
export const GET_PORTFOLIO_COURSES_FAILED = "GET_PORTFOLIO_COURSES_FAILED";

// FES/get-articles-in-a-courses
export const GET_ARTICLES_BY_COURSEID_SUCCESS = "GET_ARTICLES_BY_COURSEID_SUCCESS";
export const GET_ARTICLES_BY_COURSEID_START = "GET_ARTICLES_BY_COURSEID_START";
export const GET_ARTICLES_BY_COURSEID_FAILED = "GET_ARTICLES_BY_COURSEID_FAILED";

// FES/get-article-details
export const GET_ARTICLE_SUCCESS = "GET_ARTICLE_SUCCESS";
export const GET_ARTICLE_START = "GET_ARTICLE_START";
export const GET_ARTICLE_FAILED = "GET_ARTICLE_FAILED";

// FES/get-cards-by-article-id
export const GET_CARDS_SUCCESS = "GET_CARDS_SUCCESS";
export const GET_CARDS_START = "GET_CARDS_START";
export const GET_CARDS_FAILED = "GET_CARDS_FAILED";

// FES/get-portfolio by id
export const GET_PORTFOLIO_SUCCESS = "GET_PORTFOLIO_SUCCESS";
export const GET_PORTFOLIO_START = "GET_PORTFOLIO_START";
export const GET_PORTFOLIO_FAILED = "GET_PORTFOLIO_FAILED";

// FES/like-a-course by id
export const LIKE_SUCCESS = "LIKE_SUCCESS";
export const LIKE_START = "LIKE_START";
export const LIKE_FAILED = "LIKE_FAILED";

// like an article
export const LIKE_ARTICLE_SUCCESS = "LIKE_ARTICLE_SUCCESS";
export const LIKE_ARTICLE_START = "LIKE_ARTICLE_START";
export const LIKE_ARTICLE_FAILED = "LIKE_ARTICLE_FAILED";

// UNlike an article
export const UNLIKE_ARTICLE_SUCCESS = "UNLIKE_ARTICLE_SUCCESS";
export const UNLIKE_ARTICLE_START = "UNLIKE_ARTICLE_START";
export const UNLIKE_ARTICLE_FAILED = "UNLIKE_ARTICLE_FAILED";

// post article progress
export const ARTICLE_PROGRESS_SUCCESS = "ARTICLE_PROGRESS_SUCCESS";
export const ARTICLE_PROGRESS_START = "ARTICLE_PROGRESS_START";
export const ARTICLE_PROGRESS_FAILED = "ARTICLE_PROGRESS_FAILED";

// FES/view-course by id
export const VIEW_SUCCESS = "VIEW_SUCCESS";
export const VIEW_START = "VIEW_START";
export const VIEW_FAILED = "VIEW_FAILED";

// FES unlike-a-course
export const UNLIKE_SUCCESS = "UNLIKE_SUCCESS";
export const UNLIKE_START = "UNLIKE_START";
export const UNLIKE_FAILED = "UNLIKE_FAILED";

// FES/like article card
export const LIKE_CARD_SUCCESS = "LIKE_CARD_SUCCESS";
export const LIKE_CARD_START = "LIKE_CARD_START";
export const LIKE_CARD_FAILED = "LIKE_CARD_FAILED";

// FES/uUNLIKE article card
export const UNLIKE_CARD_SUCCESS = "UNLIKE_CARD_SUCCESS";
export const UNLIKE_CARD_START = "UNLIKE_CARD_START";
export const UNLIKE_CARD_FAILED = "UNLIKE_CARDE_FAILED";

// bookmark a card
export const BOOKMARK_CARD_SUCCESS = "BOOKMARK_CARD_SUCCESS";
export const BOOKMARK_CARD_START = "BOOKMARK_CARD_START";
export const BOOKMARK_CARD_FAILED = "BOOKMARK_CARD_FAILED";

// FES/uUNBOOKMARK article card
export const UNBOOKMARK_CARD_SUCCESS = "UNBOOKMARK_CARD_SUCCESS";
export const UNBOOKMARK_CARD_START = "UNBOOKMARK_CARD_START";
export const UNBOOKMARK_CARD_FAILED = "UNBOOKMARK_CARDE_FAILED";

// FES/updatew-course by id
export const BOOKMARK_SUCCESS = "BOOKMARK_SUCCESS";
export const BOOKMARK_START = "BOOKMARK_START";
export const BOOKMARK_FAILED = "BOOKMARK_FAILED";

// unbookmark
export const UNBOOKMARK_SUCCESS = "UNBOOKMARK_SUCCESS";
export const UNBOOKMARK_START = "UNBOOKMARK_START";
export const UNBOOKMARK_FAILED = "UNBOOKMARK_FAILED";

// get user course profile
export const GET_COURSE_STATUS_SUCCESS = "GET_COURSE_STATUS_SUCCESS";
export const GET_COURSE_STATUS_START = "GET_COURSE_STATUS_START";
export const GET_COURSE_STATUS_FAILED = "GET_COURSE_STATUS_FAILED";

// get update user course profile to onboarded
export const UPDATE_COURSE_ONBOARDING_STATUS_SUCCESS = "UPDATE_COURSE_ONBOARDING_STATUS_SUCCESS";
export const UPDATE_COURSE_ONBOARDING_STATUS_START = "UPDATE_COURSE_ONBOARDING_STATUS_START";
export const UPDATE_COURSE_ONBOARDING_STATUS_FAILED = "UPDATE_COURSE_ONBOARDING_STATUS_FAILED";

// view article
export const VIEW_ARTICLE_SUCCESS = "VIEW_ARTICLE_SUCCESS";
export const VIEW_ARTICLE_START = "VIEW_ARTICLE_START";
export const VIEW_ARTICLE_FAILED = "VIEW_ARTICLE_FAILED";

// enroll in  a portfolio
export const ENROLL_PORTFOLIO_SUCCESS = "ENROLL_PORTFOLIO_SUCCESS";
export const ENROLL_PORTFOLIO_START = "ENROLL_PORTFOLIO_START";
export const ENROLL_PORTFOLIO_FAILED = "ENROLL_PORTFOLIO_FAILED";

// enroll in  a course
export const ENROLL_COURSE_SUCCESS = "ENROLL_COURSE_SUCCESS";
export const ENROLL_COURSE_START = "ENROLL_COURSE_START";
export const ENROLL_COURSE_FAILED = "ENROLL_COURSE_FAILED";

// get courses with progress
export const GET_COURSES_WITH_PROGRESS_SUCCESS = "GET_COURSES_WITH_PROGRESS_SUCCESS";
export const GET_COURSES_WITH_PROGRESS_START = "GET_COURSES_WITH_PROGRESS_START";
export const GET_COURSES_WITH_PROGRESS_FAILED = "GET_COURSES_WITH_PROGRESS_FAILED";

// get articles PROGRESS OF A COURSE
export const GET_ARTICLE_PROGRESS_IN_COURSE_SUCCESS = "GET_ARTICLE_PROGRESS_IN_COURSE_SUCCESS";
export const GET_ARTICLE_PROGRESS_IN_COURSE_START = "GET_ARTICLE_PROGRESS_IN_COURSE_START";
export const GET_ARTICLE_PROGRESS_IN_COURSE_FAILED = "GET_ARTICLE_PROGRESS_IN_COURSE_FAILED";

// get all my courses
export const GET_MY_COURSE_SUCCESS = "GET_MY_COURSE_SUCCESS";
export const GET_MY_COURSE_START = "GET_MY_COURSE_START";
export const GET_MY_COURSE_FAILED = "GET_MY_COURSE_FAILED";

// get courses progress
export const GET_COURSE_PROGRESS_SUCCESS = "GET_COURSE_PROGRESS_SUCCESS";
export const GET_COURSE_PROGRESS_START = "GET_COURSE_PROGRESS_START";
export const GET_COURSE_PROGRESS_FAILED = "GET_COURSE_PROGRESS_FAILED";
