export const Steps = ({ current }) => (
    <div className="flex justify-between steps mb-5">
        <div className="rounded-outer" style={{ "border": "1px solid #05083F" }}>
            <div className="w-2 h-2 rounded-full progress-bar-bg-black"></div>
        </div>
        <hr style={{ "backgroundColor": `${current > 0 && "#05083F"}`, "marginTop": "5px" }} />
        <div className="rounded-outer" style={{ "border": `${current >= 1 && "1px solid #05083F"}` }}>
            <div className={`w-2 h-2 rounded-full ${current >= 1 ? 'progress-bar-bg-black' : "new-slider-grey"}`}></div>
        </div>
        <hr style={{ "backgroundColor": `${current >= 2 && "#05083F"}`, "marginTop": "5px" }} />
        <div className="rounded-outer" style={{ "border": `${current >= 2 && "1px solid #05083F"}` }}>
            <div className={`w-2 h-2 rounded-full ${current >= 2 ? 'progress-bar-bg-black' : "new-slider-grey"}`}></div>
        </div>
        <hr style={{ "backgroundColor": `${current >= 3 && "#05083F"}`, "marginTop": "5px" }} />
        <div className="rounded-outer" style={{ "border": `${current >= 3 && "1px solid #05083F"}` }}>
            <div className={`w-2 h-2 rounded-full ${current >= 3 ? 'progress-bar-bg-black' : "new-slider-grey"}`}></div>
        </div>
    </div>
);

export const StepLabel = ({ current }) => (
    <div className="flex justify-between steps mb-5 text-sm" current={current}>
        <div>
        Verify Number
        </div>
       
        <div>
        Create Pin
        </div>
        <div>
        Review
        </div>
        <div>
        Bank Details
        </div>
    </div>
);