import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Joi from "joi-browser";
import Maybe from "./Maybe";
import PinInput from "react-pin-input";
import { Dialog } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { Countdown } from "components/common/Countdown";
import ContentLoading from "./ContentLoading";
import successGif from "assets/images/icons/project-icons/success-green.gif";
import * as userActions from "store/auth/user/action";


const EmailUpdate = (props) => {
    const {isOpen, setIsOpen} = props;
    const dispatch = useDispatch();
    let elementRef = useRef();
    const completeButtonRef = React.createRef();
    
    const userInfo = useSelector((s) => s.auth.userPersist.data);
    const { emailOtpSendLoading, emailOtpSend } = useSelector((s) => s.auth.user);
    const { emailOtpValidationLoading, emailOtpValidation } = useSelector((s) => s.auth.user);
    const { emailUpdateLoading, emailUpdate } = useSelector((s) => s.auth.user);

    /** views => emails, otp, success */
    const [view, setView] = useState("emails");
    const [timerShown, setTimerShown] = useState(true);
    const [form, setForm] = useState({
        email: "",
        confirmEmail: "",
        otp: "",
    })

    const schema = {
		email: Joi.string().email().required().label("Email Address").error(errors => {
			errors.forEach(err => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Please enter your new email address";
						break;
					case "string.email":
						err.message = "Please enter a valid email address";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
		confirmEmail: Joi.any().valid(Joi.ref('email')).label("Confirm Email Address").error(errors => {
			errors.forEach(err => {
				switch (err.type) {
					case "any.allowOnly":
						err.message = "Your email addresses do not match";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	};

    const validate = (data) => {
		const options = { abortEarly: false };
		const result = Joi.validate( data, schema, options );
		const error = result.error;
		if (!error) return null;

		const errors = {};
		for (let item of result.error.details) {
			errors[item.path[0]] = item.message;
		}
		return errors;
	};

    const getDisabledStatus = () => {
        if (emailOtpSendLoading === true || form.otp.length < 6 || emailOtpValidationLoading === true || emailUpdateLoading === true){
            return true;
        }
        return false;
    }

    const submitFormEmail = async (e) => {
        e.preventDefault();
        const {email, confirmEmail} = form;
        const errors = validate({email, confirmEmail});
        if (errors){
            for (var x in errors) {
                toast.error(errors[x]);
            };
            return;
        }
        else{
            if (userInfo.email !== form.email){
                dispatch(userActions.emailSendOtp({email: form.email}));
            }
            else{
                toast.error("Your current and new email address cannot be the same");
            }
        }
    }

    const submitFormOtp = async (e) => {
        e.preventDefault();
        const {email, otp} = form;
        dispatch(userActions.emailVerifyOtp({email, otp}));
    }

    const resendOtp =() => {
        dispatch(userActions.emailSendOtp({email: form.email}));
    }
    
    const resetPin =() => {
        if (elementRef.currentIndex !== undefined){
            elementRef.clear();
            setForm({...form, otp: ""});
        }
    }

    const resetForm =() => {
        setForm({...form, email: "", confirmEmail: "", otp: ""});
    }

    const closeDialogBox =() => {
        if (view !== "success"){
            setIsOpen(false);
            setView("emails");
            resetForm();
        }
    }

    useEffect(() => {
        if (emailOtpSend?.status === "success"){
            setView("otp");
            dispatch(userActions.resetEmailSendOtp());
        }
        // eslint-disable-next-line
    }, [emailOtpSend])

    useEffect(() => {
        if (emailOtpValidation?.status === "success"){
            dispatch(userActions.resetEmailVerifyOtp());
            if (form.email !== ""){
                dispatch(userActions.updateEmail({email: form.email}));
            }
        }
        // eslint-disable-next-line
    }, [emailOtpValidation])

    useEffect(() => {
        if (emailUpdate?.status === "success"){
            setView("success");
            resetForm();
            dispatch(userActions.resetUpdateEmail());
            dispatch(userActions.softLogout());
        }
        // eslint-disable-next-line
    }, [emailUpdate])

    return (
        <>
           <Dialog initialFocus={completeButtonRef} open={isOpen} onClose={() => closeDialogBox()} className="fixed z-10 inset-0 overflow-y-auto">
				<div className="min-h-screen flex items-center justify-center rounded shadow-md">
					<Dialog.Overlay className="fixed inset-0 bg-black bg-blur bg-opacity-50" />
					<div className="w-full max-w-sm mx-auto p-8 text-center relative bg-white rounded-lg shadow-md overflow-hidden">
                            

                        <Maybe condition={view !== "success"}>
                            <div className="font-semibold text-xl">
                                Update email address
                            </div>
                            <div className="text-fade text-sm">
                                Please submit and verify your new email address
                            </div>
						</Maybe>


                        <Maybe condition={view === "emails"}>
                            <form onSubmit={submitFormEmail}>
                                <div className="mt-8">
                                    
                                    <div className="form-group text-left">
                                        <label>
                                            Current Email Address
                                            <span className="form-input-required">*</span>
                                        </label>
                                        <input type="email" className="form-input" value={userInfo.email} disabled={true} readOnly={true} />
                                    </div>

                                    <div className="form-group text-left">
                                        <label>
                                            New Email Address
                                            <span className="form-input-required">*</span>
                                        </label>
                                        <input type="email" className="form-input" value={form.email} onChange={(e) => setForm({...form, email: e.target.value})} />
                                    </div>

                                    <div className="form-group text-left">
                                        <label>
                                            Confirm Email Address
                                            <span className="form-input-required">*</span>
                                        </label>
                                        <input type="email" className="form-input" value={form.confirmEmail} onChange={(e) => setForm({...form, confirmEmail: e.target.value})} />
                                    </div>

                                    <button type="submit" disabled={emailOtpSendLoading} className="btn btn-lg btn-block btn-ep-blue mt-8">
                                        <FontAwesomeIcon icon="circle-notch" spin className={"text-xl mr-2 " + (emailOtpSendLoading?"inline":"hidden")} />
                                        Update
                                    </button>

                                </div>
                            </form>
                        </Maybe>


                        <Maybe condition={view === "otp"}>
                            <form onSubmit={submitFormOtp}>

                                <div className="mt-20 flex justify-center">
                                    <PinInput
                                        length={6}
                                        initialValue={form.otp}
                                        onChange={(e) => {setForm({...form, otp: e}); getDisabledStatus()}}
                                        inputStyle={{width: "45px", border: "none", borderBottom: "1px solid #ADB5BD", fontSize: "30px", fontWeight: "bold", paddingBottom: "6px"}}
                                        inputFocusStyle={{borderBottom: "1px solid #ADB5BD"}}
                                        onComplete={(value, index) => {}}
                                        autoSelect={true}
                                        focus={true}
                                        secret={false}
                                        ref={(n) => (elementRef = n)}
                                    />
                                </div>
                                
                                <div className="flex space-x-4">
                                    <button type="submit" disabled={getDisabledStatus() === true} className="btn btn-lg btn-block btn-ep-blue mt-8">
                                        <FontAwesomeIcon icon="circle-notch" spin className={"text-xl mr-2 " + (emailOtpValidationLoading?"inline":"hidden")} />
                                        Verify
                                    </button>
                                </div>
                                
                                <div className="mt-4 flex justify-center font-medium text-sm">
                                    <Maybe condition={timerShown === true}>
                                        <div className="font-medium text-fade cursor-not-allowed">Resend OTP</div>
                                        <div>&nbsp;in&nbsp;</div>
                                        <Countdown seconds={60} onExpiry={() => setTimerShown(false)} />
                                    </Maybe>
                                    
                                    <Maybe condition={timerShown === false}>
                                        <div>Didn't receive OTP? &nbsp;</div>
                                        <Maybe condition={emailOtpSendLoading === false}>
                                            <button type="button" onClick={resendOtp} className="font-medium text-ep-primary hover:underline">
                                                Resend OTP
                                            </button>
                                        </Maybe>
                                        <Maybe condition={emailOtpSendLoading === true}>
                                            <span className="font-medium text-fade">
                                                <ContentLoading loading={true} />
                                                <span className="ml-1">Resend OTP</span>
                                            </span>
                                        </Maybe>
                                    </Maybe>
                                </div>

                                <div className="mt-6 text-center">
                                    <span className="cursor-pointer hover:underline" onClick={() => {setView("emails"); resetPin()}}>
                                        Back to email address
                                    </span>
                                </div>

                            </form>
                        </Maybe>


                        <Maybe condition={view === "success"}>
                            <div>
                                <div className="h-48">
                                    <img src={successGif} alt="success" className="h-full object-contain mx-auto" />
                                </div>
                                <div className="mt-0 font-semibold text-xl">
                                    Email Address Updated
                                </div>
                                <div className="mt-4 text-gray-500">
                                    You can now log into to your account using your new email address.
                                </div>
                                <button type="button" onClick={() => dispatch(userActions.logoutUser())} className="btn btn-lg btn-block btn-ep-blue mt-8">
                                    Log in with new email
                                </button>
                            </div>
                        </Maybe>


					</div>
				</div>
			</Dialog>
        </>
    )
}


export default EmailUpdate;