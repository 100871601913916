import * as actions from "./actionTypes";
import * as actionsUser from "../user/actionTypes";
import * as config from "config";

export function updateProfile(params){
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiUpdateProfile,
            method: "post",
            data: params,
            onStart: actions.UPDATE_PROFILE_START,
            onSuccess: actions.UPDATE_PROFILE,
            onError: actions.UPDATE_PROFILE_FAILED,
        },
    }
}

export function resetUpdateProfile(){
    return {
        type: actions.UPDATE_PROFILE_RESET,
        payload: {},
    }
}

export function updateLoggedInPersistedStorage(params){
    return {
        type: actionsUser.UPDATE_LOGIN_USER,
        payload: {
            data: params,
        },
    }
}

export function updateLoginProfile(params){
    return {
        type: actionsUser.UPDATE_LOGIN_PROFILE,
        payload: params,
    }
}

export function updatePassword(params){
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiUpdateProfilePassword,
            method: "post",
            data: params,
            onStart: actions.UPDATE_PASSWORD_START,
            onSuccess: actions.UPDATE_PASSWORD,
            onError: actions.UPDATE_PASSWORD_FAILED,
        },
    }
}

export function resetUpdatePassword(){
    return {
        type: actions.UPDATE_PASSWORD_RESET,
        payload: {},
    }
}

export function getWalletDetails(){
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiWalletBaseUrl,
            url: config.apiGetWalletDetails,
            method: "get",
            data: {},
            onStart: actions.GET_WALLET_DETAILS_START,
            onSuccess: actions.GET_WALLET_DETAILS,
            onError: actions.GET_WALLET_DETAILS_FAILED,
            useEncryption: true,
        },
    }
}

export function getUserInfo(){
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetUserInfo,
            method: "get",
            data: {},
            onStart: actions.GET_USER_INFO_START,
            onSuccess: actions.GET_USER_INFO,
            onError: actions.GET_USER_INFO_FAILED,
            useEncryption: true,
        },
    }
}
