/*
export const CurrencyFormat = (amount) => {

  if (!isNaN(amount)) {

    const formatter = new Intl.NumberFormat('en-US', {
      // style: 'currency',
      // currency: 'USD',
      // currency: '₦',
    });

    return ("₦" + formatter.format(amount_rounded)); // ₦2,500.00

  }

};
*/





export const CurrencyFormat = (amount, kobo = '', symbol = '') => {

  if (!isNaN(amount)) {

    if (amount === "") {
      amount = 0;
    }

    // const amount_rounded = Math.round((amount + Number.EPSILON) * 100) / 100; // trucates x.70 to x.7
    let amount_rounded = 0;
    if (kobo === "no-kobo") {
      amount_rounded = parseFloat(amount);
    }
    else {
      amount_rounded = parseFloat(amount).toFixed(2);
    }

    if (symbol === "") {
      symbol = "₦";
    }
    else if (symbol === "none") {
      symbol = "";
    }

    // const formatter = new Intl.NumberFormat('en-US', {});
    // return ("₦" + formatter.format(amount_rounded)); // trucates x.70 to x.7
    // return ("₦" + Number(amount_rounded).toLocaleString()); // trucates x.70 to x.7
    return (symbol + (amount_rounded).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));

  }
  else {
    return (amount);
  }

};

export const CurrencyFormat2 = (amount, kobo = '', symbol = '') => {

  if (!isNaN(amount)) {

    if (amount === "") {
      amount = 0;
    }

    // const amount_rounded = Math.round((amount + Number.EPSILON) * 100) / 100; // trucates x.70 to x.7
    let amount_rounded = 0;
    if (kobo === "no-kobo") {
      amount_rounded = parseFloat(amount);
    }
    else {
      amount_rounded = parseFloat(amount).toFixed(0);
    }

    if (symbol === "") {
      symbol = "₦";
    }
    else if (symbol === "none") {
      symbol = "";
    }

    // const formatter = new Intl.NumberFormat('en-US', {});
    // return ("₦" + formatter.format(amount_rounded)); // trucates x.70 to x.7
    // return ("₦" + Number(amount_rounded).toLocaleString()); // trucates x.70 to x.7
    return (symbol + (amount_rounded).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));

  }
  else {
    return (amount);
  }

};
