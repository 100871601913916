export const GET_ALL_DASHBOARD = "GET_ALL_DASHBOARD";
export const GET_ALL_DASHBOARD_START = "GET_ALL_DASHBOARD_START";
export const GET_ALL_DASHBOARD_FAILED = "GET_ALL_DASHBOARD_FAILED";

export const NEW_WITHDRAWAL = "NEW_WITHDRAWAL";
export const NEW_WITHDRAWAL_START = "NEW_WITHDRAWAL_START";
export const NEW_WITHDRAWAL_FAILED = "NEW_WITHDRAWAL_FAILED";

export const GET_WITHDRAWAL_FEE = "GET_WITHDRAWAL_FEE";
export const GET_WITHDRAWAL_FEE_START = "GET_WITHDRAWAL_FEE_START";
export const GET_WITHDRAWAL_FEE_FAILED = "GET_WITHDRAWAL_FEE_FAILED";

export const GET_AUTOPAY_FEE = "GET_AUTOPAY_FEE";
export const GET_AUTOPAY_FEE_START = "GET_AUTOPAY_FEE_START";
export const GET_AUTOPAY_FEE_FAILED = "GET_AUTOPAY_FEE_FAILED";

export const GET_WITHDRAWAL_REASON = "GET_WITHDRAWAL_REASON";
export const GET_WITHDRAWAL_REASON_START = "GET_WITHDRAWAL_REASON_START";
export const GET_WITHDRAWAL_REASON_FAILED = "GET_WITHDRAWAL_REASON_FAILED";

export const NEW_WITHDRAWAL_RESET = "NEW_WITHDRAWAL_RESET";
