import React, { useEffect, useState } from 'react';
// import * as auth from "store/auth/user/action";
import { useSelector } from 'react-redux';
import logo from "assets/images/newDesignAssets/svgs/logo.svg";
import successIcon from "assets/images/newDesignAssets/svgs/helloIllustration.svg";
import { Link, useHistory } from 'react-router-dom';
import logger from 'utils/logger';


const GetStartedSuccess = () => {
    const [checked, setIsChecked] = useState(false)
    const history = useHistory()
    // const dispatch = useDispatch()
    const handleCheck = () => setIsChecked(!checked);
    const user = useSelector((s) => s.auth.userPersist.data);

    // const { signinUp, data, signUpPinCreated } = useSelector(s => s.auth.userPersist.signUpPinCreated);

    const loadBankDetailsPage = () => history.push("/get-started/personal-details");
    useEffect(() => {
        logger("employeeweb-accept-invite-success", user);
        // dispatch(auth.resetSignUp());

        //eslint-disable-next-line
    }, []);

    return (
        <div className="w-full p-8 sm:p-14 md:my-auto">
            <div className="pb-14 md:pt-20 lg:pl-4 lg:pr-4">

                <div to="#" className=" my-8 h-8 sm:h-10 flex justify-center animate hover:scale-110 ">
                    <img src={logo} alt="EarniPay logo" className="h-full object-contain" />
                </div>

                <div className="mt-8 text-center max-w-md mx-auto">
                    <div className="h-48 sm:h-56">
                        <img src={successIcon} alt="EarniPay" className="h-full object-contain mx-auto" />
                    </div>
                    <div className="mt-8 font-recoleta font-semibold text-2xl sm:text-3xl">
                        Welcome to Earnipay
                    </div>
                    <div className="mt-4 text-gray-500">
                        Earnipay is a financial technology solution that allows employers to offer employees flexible access to their accrued salaries instantly at any time of the month without the need for salary advances.
                    </div>

                    <button disabled={checked === false} className='btn btn-lg btn-block btn-ep-blue mt-8' onClick={() => loadBankDetailsPage()}>
                        {/* <Link to="/get-started/personal-details" className="btn btn-lg btn-block btn-ep-blue mt-8">Continue</Link> */}
                        Continue
                    </button>
                    <div className='mt-3'>
                        <form >
                            <input type="checkbox" name="" id="" className='rounded-sm border border-gray-400' onChange={handleCheck} defaultChecked={checked} required />
                            <span className='pl-3'>I agree with <Link to='https://earnipay.com/terms' className='text-new-ep-blue'>terms &amp; conditions</Link></span>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
};
export default GetStartedSuccess;