export const GET_ALL_WITHDRAWALS = "GET_ALL_WITHDRAWALS";
export const GET_ALL_WITHDRAWALS_START = "GET_ALL_WITHDRAWALS_START";
export const GET_ALL_WITHDRAWALS_FAILED = "GET_ALL_WITHDRAWALS_FAILED";

export const GET_ALL_WITHDRAWALSIDEBAR = "GET_ALL_WITHDRAWALSIDEBAR";
export const GET_ALL_WITHDRAWALSIDEBAR_START = "GET_ALL_WITHDRAWALSIDEBAR_START";
export const GET_ALL_WITHDRAWALSIDEBAR_FAILED = "GET_ALL_WITHDRAWALSIDEBAR_FAILED";
export const NEXT = "NEXT";
export const PREV = "PREV";
export const ZERO = "ZERO";
export const RESET_WITHDRAWAL = "RESET_WITHDRAWAL";

export const RELOAD_PAGE_RESET = "RELOAD_PAGE_RESET";

// autopayment schedule
export const CREATE_PAYMENT_SCHEDULE_START = "CREATE_PAYMENT_SCHEDULE_START";
export const CREATE_PAYMENT_SCHEDULE = "CREATE_PAYMENT_SCHEDULE";
export const CREATE_PAYMENT_SCHEDULE_FAILED = "CREATE_PAYMENT_SCHEDULE_FAILED";

// CANCEL_AUTOPAY
export const CANCEL_AUTOPAY_START = "CANCEL_AUTOPAY_START";
export const CANCEL_AUTOPAY = "CANCEL_AUTOPAY";
export const CANCEL_AUTOPAY_FAILED = "CANCEL_AUTOPAY_FAILED";

export const GET_ALL_PAYMENT_SCHEDULE_START = "GET_ALL_PAYMENT_SCHEDULE_START";
export const GET_ALL_PAYMENT_SCHEDULE = "GET_ALL_PAYMENT_SCHEDULE";
export const GET_ALL_PAYMENT_SCHEDULE_FAILED = "GET_ALL_PAYMENT_SCHEDULE_FAILED";

export const GET_PAYMENT_SCHEDULE_START = "GET_PAYMENT_SCHEDULE_START";
export const GET_PAYMENT_SCHEDULE = "GET_PAYMENT_SCHEDULE";
export const GET_PAYMENT_SCHEDULE_FAILED = "GET_PAYMENT_SCHEDULE_FAILED";

export const UPDATE_PAYMENT_SCHEDULE_START = "UPDATE_PAYMENT_SCHEDULE_START";
export const UPDATE_PAYMENT_SCHEDULE = "UPDATE_PAYMENT_SCHEDULE";
export const UPDATE_PAYMENT_SCHEDULE_FAILED = "UPDATE_PAYMENT_SCHEDULE_FAILED";

export const DELETE_PAYMENT_SCHEDULE_START = "DELETE_PAYMENT_SCHEDULE_START";
export const DELETE_PAYMENT_SCHEDULE = "DELETE_PAYMENT_SCHEDULE";
export const DELETE_PAYMENT_SCHEDULE_FAILED = "DELETE_PAYMENT_SCHEDULE_FAILED";

export const ACTIVATE_AUTOPAY_START = "ACTIVATE_AUTOPAY_START";
export const ACTIVATE_AUTOPAY = "ACTIVATE_AUTOPAY";
export const ACTIVATE_AUTOPAY_FAILED = "ACTIVATE_AUTOPAY_FAILED";