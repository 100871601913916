import React, { Component } from "react";
import { connect } from "react-redux";
import { nameInitials } from "utils/nameInitials.jsx";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LeftSidebar from "components/common/sidebarNavigation.jsx";
import RightSidebar from "components/common/sidebarAccount.jsx";
import logo from "assets/images/icons/logo@svg.svg";

class Header extends Component {
	state = {
		showLeftSidebarMobile: false,
		showRightSidebarMobile: false,
	};

	toggleLeftSidebarMobile = () => {
		const showLeftSidebarMobile = this.state.showLeftSidebarMobile;
		this.setState({ showLeftSidebarMobile: !showLeftSidebarMobile });
	};

	closeLeftSidebarMobile = () => {
		this.setState({ showLeftSidebarMobile: false });
	};

	toggleRightSidebarMobile = () => {
		const showRightSidebarMobile = this.state.showRightSidebarMobile;
		this.setState({ showRightSidebarMobile: !showRightSidebarMobile });
		this.closeLeftSidebarMobile();
	};

	render() {
		const { showLeftSidebarMobile, showRightSidebarMobile } = this.state;

		return (
			<>
				<div
					className={
						"absolute z-50 top-0 left-0 bottom-0 block xl:hidden " +
						(showLeftSidebarMobile ? "block" : "hidden")
					}>
					<LeftSidebar
						nesttoggleLeftSidebarMobile={this.toggleLeftSidebarMobile}
					/>
				</div>

				<div
					className={
						"absolute z-50 top-0 left-0 bottom-0 block xl:hidden " +
						(showRightSidebarMobile ? "block" : "hidden")
					}>
					<RightSidebar
						nesttoggleRightSidebarMobile={this.toggleRightSidebarMobile}
					/>
				</div>
				<div
					onClick={this.toggleRightSidebarMobile}
					className={
						"fixed z-10 inset-0 bg-black bg-opacity-80 animate " +
						(showRightSidebarMobile ? "block" : "hidden")
					}></div>

				<div className="shadow-sm">
					<header className="h-16 bg-white px-6 hidden lg:flex">
						<div className="w-full my-auto relative flex space-x-5 justify-end">
							<div className="w-px h-12 my-auto bg-gray-200">&nbsp;</div>

							<div className="relative">
								<div
									onClick={this.toggleRightSidebarMobile}
									className="h-12 relative px-2 flex cursor-pointer hover:bg-gray-200 rounded">
									<div className="w-10 h-10 my-auto mr-3 bg-gray-500 flex text-lg font-bold rounded-full overflow-hidden">
										<span className="m-auto text-sm text-white">
											{nameInitials(this.props.user.name)}
										</span>
									</div>
									<div className="text-left my-auto mr-3">
										<div className="text-ep-blue text-sm font-semibold capitalize">
											{this.props.user.name}
										</div>
										<div className="text-xs capitalize">
											{this.props.user.phoneNumber}
										</div>
									</div>
								</div>
							</div>
						</div>
					</header>

					<header className="w-full bg-ep-blue sidebar-bg-ep-pattern relative z-50 lg:hidden">
						<div className="w-full h-14 px-2 sm:px-6 flex items-center justify-between">
							<div className="flex-shrink-0">
								<button
									className="w-10 h-10 text-white group hover:bg-ep-blue-deeper flex rounded cursor-pointer"
									onClick={this.toggleLeftSidebarMobile}>
									<FontAwesomeIcon icon="bars" className="text-xl m-auto" />
								</button>
							</div>
							<div className="flex-grow flex">
								<Link
									to="/dashboard"
									onClick={this.closeLeftSidebarMobile}
									className="h-7 mx-auto inline-block">
									<img
										src={logo}
										alt="EarniPay logo"
										className="w-full h-full my-auto object-contain"
									/>
								</Link>
							</div>
							<div className="flex-shrink-0 flex w-10 h-10">
								<div
									onClick={this.toggleRightSidebarMobile}
									className="w-8 h-8 my-auto bg-white flex text-lg font-bold rounded-full overflow-hidden cursor-pointer">
									<span className="m-auto text-sm text-ep-blue">
										{nameInitials(this.props.user.name)}
									</span>
								</div>
							</div>
						</div>
					</header>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	loggedIn: state.auth.userPersist.loggedIn,
	user: state.auth.userPersist.data,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
