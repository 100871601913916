import React from "react";
import { Dialog } from "@headlessui/react";
// import { ReactComponent as Exclamation } from "assets/images/newDesignAssets/svgs/Exclame.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ComingSoonModal = ({ open, toggleOpen }) => {
	const completeButtonRef = React.createRef();

	return (
		<Dialog
			initialFocus={completeButtonRef}
			open={open}
			onClose={toggleOpen}
			className="fixed z-10 inset-0 overflow-y-auto">
			<div
				className="flex items-center justify-center"
				style={{ height: "600px" }}>
				<Dialog.Overlay className="fixed inset-0 bg-white bg-blur bg-opacity-50" />
				<div className=" modal-box-coming-soon mt-5">
					<div className="text-white pl-4 pr-16" ref={completeButtonRef}>
						<div className="w-full modal-btn flex justify-between pb-2">
							<FontAwesomeIcon
								icon="exclamation-circle"
								className="cursor-pointer"
							/>
							{/* <Exclamation className="" /> */}
							{/* <FontAwesomeIcon icon='times' className="cursor-pointer" /> */}
						</div>
						{/* <div> This Feature is coming soon. Stay tuned!!!</div> */}
						<div> This feature is now available on the Earnipay Mobile App </div>
					</div>
				</div>
			</div>
		</Dialog>
	);
};

export default ComingSoonModal;
