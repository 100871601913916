const BannerAlert = (props) => {
    return (
        <>
            <div className={`px-6 py-4 w-full bg-yellow-200 flex flex-wrap items-center justify-between overflow-auto rounded`}>
                <div className="flex items-center">
                    <div className="w-5 mr-3">ℹ️</div>
                    <span className="font-semibold hidden sm:inline">
                        {props.title ? props.title + ":" : "Notice:"} &nbsp; &nbsp; &nbsp;
                    </span>
                    {props.content}
                </div>
                {/* 
                <div>
                    <div className={`inline-block text-ep-primary border-b border-ep-primary`}>
                        <span className="hidden sm:inline">Download App</span>
                        <span className="sm:hidden">
                            <FontAwesomeIcon icon="angle-right" className="text-xl" />
                        </span>
                    </div>
                </div>
                 */}
            </div>
        </>
    )
}

export default BannerAlert
