import React, { useEffect, useMemo, useState } from "react";
import { CurrencyFormat } from "utils/currencyFormat";
import { DateTimeMiniFormat } from "utils/dateFormat";
// import { TimeFormat, MonthName, DateFormatTable } from "utils/dateFormat";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as IconArrowDown } from "assets/images/icons/project-icons/Arrowdown.svg";

// import { getDashboard } from "store/entities/dashboard/action";
import { getAllWithdrawalsSidebar } from "store/entities/withdrawals/action";
import { ReactComponent as Xclose } from "assets/images/icons/project-icons/xclose.svg";
import { nameInitials } from "utils/nameInitials";
import { logoutUser } from "store/auth/user/action";
import EmailUpdate from "./EmailUpdate";

const List = (props) => {
	const dispatch = useDispatch();

	const allWithdrawalParams = {
		page: 1,
		limit: 10,
		type: "withdrawal",
	};

	const user = useSelector((s) => s.auth.userPersist.data);
	const { allWithdrawalsSidebar, loading: loadingWithdrawals } = useSelector(
		(s) => s.entities.withdrawals
	);

	const [isOpenEmailUpdate, setIsOpenEmailUpdate] = useState(false);

	const updateEmailAddress = () => {
		setIsOpenEmailUpdate(true);
		props.nesttoggleRightSidebarMobile();
	}

	useEffect(() => {
		dispatch(getAllWithdrawalsSidebar(allWithdrawalParams));

		//eslint-disable-next-line
	}, [dispatch, props]);

	const memorizedWithdrawals = useMemo(
		() => allWithdrawalsSidebar?.withdrawals?.docs || [],
		[allWithdrawalsSidebar.withdrawals]
	);

	return (
		<>
			<EmailUpdate
				isOpen={isOpenEmailUpdate}
				setIsOpen={setIsOpenEmailUpdate}
			/>

		<div
			className="w-full sm:w-96 h-screen p-6 fixed top-0 bottom-0 right-0 overflow-auto"
			style={{ backgroundColor: "#F7FAFC" }}>
			<div className="pb-16">
				<div className="flex xl:hidden justify-between">
					<div></div>
					<div onClick={() => props.nesttoggleRightSidebarMobile()} className="w-8 h-8 flex border group hover:bg-red-400 hover:border-red-400 border-gray-700 rounded-lg cursor-pointer">
						<Xclose className="customizeSVG group-hover:text-white m-auto" />
					</div>
				</div>

				<div className="text-center mt-4">
					<div className="w-20 h-20 bg-light-blue rounded-full mx-auto flex">
						<span className="text-new-ep-blue text-2xl font-semibold m-auto">
							{nameInitials(user.name)}
						</span>
					</div>
					<div className="mt-3 text-2xl font-semibold text-new-ep-blue capitalize">
						{user.name}
					</div>
					<div className="text-gray-500 font-semibold">{user.email}</div>
					<div className="text-gray-500">{user.phoneNumber}</div>
					<div className="mt-2">
						<button type="button" onClick={updateEmailAddress} className="btn btn-transparent-black btn-sm mx-auto" style={{ fontWeight: "normal" }}>
							Update email address
						</button>
						<button
							type="button"
							className="mt-1 btn btn-sm mx-auto hover:underline"
							style={{ fontWeight: "normal" }}
							onClick={() => dispatch(logoutUser())}>
							Logout
						</button>
					</div>
				</div>

				{/* 
				<div className="mt-10">
					<div className="font-medium tracking-wide">
						Salary Account Details
					</div>
					<div className="mt-4 p-4 border border-gray-100 shadow-md rounded-lg">
						<div className="form-group">
							<label style={{ fontSize: "12px" }}>
								Bank Name
								<span className="form-input-required">*</span>
							</label>
							<input
								type="text"
								className="form-input"
								value={user.bankName}
								readOnly
								disabled
								style={{ backgroundColor: "#F9F9FA", borderColor: "#eee" }}
							/>
						</div>
						<div className="form-group">
							<label style={{ fontSize: "12px" }}>
								Account Number
								<span className="form-input-required">*</span>
							</label>
							<input
								type="text"
								className="form-input"
								value={user.bankAccountNumber}
								readOnly
								disabled
								style={{ backgroundColor: "#F9F9FA", borderColor: "#eee" }}
							/>
						</div>
					</div>
				</div>
				 */}

				<div className="mt-10">
					<div className="flex justify-between">
						<div className="font-medium tracking-wide">On-Demand Pay History</div>
						{loadingWithdrawals === false &&
							allWithdrawalsSidebar &&
							memorizedWithdrawals.length > 0 && (
								<div className="">
									<Link
										to="/withdrawals"
										className="btn btn-transparent-black btn-sm mx-auto"
										style={{ fontWeight: "normal" }}>
										View All
									</Link>
								</div>
							)}
					</div>

					<div className="mt-4 space-y-4">
						{loadingWithdrawals === true && (
							<div className="table-info border border-gray-200">
								<FontAwesomeIcon icon="spinner" spin />
								<div className="font-medium uppercase">Loading</div>
							</div>
						)}

						{loadingWithdrawals === false && !allWithdrawalsSidebar && (
							<div className="table-info border border-gray-200">
								<FontAwesomeIcon icon="unlink" />
								<div className="font-medium uppercase">
									An error occurred
									<br />
									Please try again later.
								</div>
							</div>
						)}

						{loadingWithdrawals === false &&
							allWithdrawalsSidebar &&
							memorizedWithdrawals && (
								<div>
									{memorizedWithdrawals.length === 0 && (
										<div className="table-info border border-gray-200">
											<FontAwesomeIcon icon="list" />
											<div className="font-medium uppercase">
												No withdrawals yet
											</div>
										</div>
									)}
								</div>
							)}

						{loadingWithdrawals === false &&
							allWithdrawalsSidebar &&
							memorizedWithdrawals.length > 0 &&
							memorizedWithdrawals.slice(0, 5).map((withdrawal, index) => (
								<div
									key={index}
									className="p-4 flex space-x-3 bg-white border border-gray-100 shadow-sm rounded-lg">
									<div
										className="w-10 h-10 flex flex-shrink-0 rounded-xl"
										style={{ backgroundColor: "#FFDDE9" }}>
										<IconArrowDown className="customizeSVG text-white m-auto w-6 h-6" />
									</div>
									<div className="flex-grow flex justify-between ">
										<div>
											<div>
												<div> Earnipay</div>
												<div className="text-fade text-xs">
													{" "}
													{withdrawal?.reason ? withdrawal?.reason : "-"}
												</div>
											</div>
											<div className="text-fade text-xs">
												{withdrawal.description}
											</div>
										</div>
										<div className="text-right">
											<div className="font-bold" style={{ color: "#C61E1E" }}>
												-{CurrencyFormat(withdrawal.amountRequested)}
											</div>
											<div className="text-fade text-xs">
												{DateTimeMiniFormat(withdrawal.createdAt)}
											</div>
										</div>
									</div>
								</div>
							))}
					</div>
				</div>
			</div>
		</div>
		</>
	);
};

export default List;
