import * as actions from "./actionTypes";
import { toast } from 'react-toastify';

const initialState = {
    loading: false,
    allEarnings: {},
    loadingSidebar: false,
    allEarningsSidebar: {},
    reloadPage: false,
};


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.GET_ALL_EARNINGS:
            state = {
                ...state,
                loading: false,
                allEarnings: { ...action.payload }
            }
            return state;


        case actions.GET_ALL_EARNINGS_START:
            state = {
                ...state,
                loading: true,
            }
            return state;


        case actions.GET_ALL_EARNINGS_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loading: false,
                allEarnings: {},
            }
            return state;


        case actions.GET_ALL_EARNINGS_SIDEBAR:
            state = {
                ...state,
                loadingSidebar: false,
                allEarningsSidebar: { ...action.payload }
            }
            return state;


        case actions.GET_ALL_EARNINGS_START_SIDEBAR:
            state = {
                ...state,
                loadingSidebar: true,
            }
            return state;


        case actions.GET_ALL_EARNINGS_FAILED_SIDEBAR:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loadingSidebar: false,
                allEarningsSidebar: {},
            }
            return state;


        case actions.RELOAD_PAGE_RESET:
            state = {
                ...state,
                reloadPage: false
            }
            return state;


        default:
            return state;
    }
}