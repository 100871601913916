import * as actions from "./actionTypes";
import * as config from "config";
import ObjectToQueryString from 'utils/ObjectToQueryString';

export function getAllPosts(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetAllPosts + ObjectToQueryString(params),
            method: "get",
            data: params,
            onStart: actions.GET_ALL_POSTS_START,
            onSuccess: actions.GET_ALL_POSTS_SUCCESS,
            onError: actions.GET_ALL_POSTS_FAILED,
        },
    }
};

export function getAllCategories(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetCategories,
            method: "get",
            data: params,
            onStart: actions.GET_ALL_CATEGORIES_START,
            onSuccess: actions.GET_ALL_CATEGORIES_SUCCESS,
            onError: actions.GET_ALL_CATEGORIES_FAILED,
        },
    }
};

export function getPost(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetAllPosts + "/" + id + "?populate=category",
            method: "get",
            post: {},
            onStart: actions.GET_POST_START,
            onSuccess: actions.GET_POST_SUCCESS,
            onError: actions.GET_POST_FAILED,
        },
    }
}

// NEW FINANCIAL EDUCATION SERVICES
// GET ONBOARDING QUESTIONS
export const getOnboardingQuestions = () => {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetOnboardingQuestions ,
            baseUrl: process.env.REACT_APP_FINEDU_API_BASE_URL,
            method: "get",
            questions: {},
            onStart: actions.GET_ONBOARDING_QUESTION_START,
            onSuccess: actions.GET_ONBOARDING_QUESTION_SUCCESS,
            onError: actions.GET_ONBOARDING_QUESTION_FAILED,
        },
    }
}

// GET ARTICLE QUESTIONS
export const getArticleQuestions = (id) => {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiGetArticleQuestions}&article=${id}`,
            baseUrl: process.env.REACT_APP_FINEDU_API_BASE_URL,
            method: "get",
            questions: {},
            onStart: actions.GET_ARTICLE_QUESTION_START,
            onSuccess: actions.GET_ARTICLE_QUESTION_SUCCESS,
            onError: actions.GET_ARTICLE_QUESTION_FAILED,
        },
    }
}
// GET terms of the day
export const getTermsOfTheDay = () => {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetTermsOfTheDay ,
            baseUrl: process.env.REACT_APP_FINEDU_API_BASE_URL,
            method: "get",
            terms: {},
            onStart: actions.GET_TERMS_OF_THE_DAY_START,
            onSuccess: actions.GET_TERMS_OF_THE_DAY_SUCCESS,
            onError: actions.GET_TERMS_OF_THE_DAY_FAILED,
        },
    }
}

// GET courses
export const getAllCourses = () => {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetAllCourses,
            baseUrl: process.env.REACT_APP_FINEDU_API_BASE_URL,
            method: "get",
            courses: {},
            onStart: actions.GET_ALL_COURSES_START,
            onSuccess: actions.GET_ALL_COURSES_SUCCESS,
            onError: actions.GET_ALL_COURSES_FAILED,
        },
    }
}

// GET all-enrolled-courses
export const getAllEnrolledCourses = (id) => {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiGetAllCourses}/${id}/enroll`,
            baseUrl: process.env.REACT_APP_FINEDU_API_BASE_URL,
            method: "get",
            courses: {},
            onStart: actions.GET_ALL_ENROLLED_COURSES_START,
            onSuccess: actions.GET_ALL_ENROLLED_COURSES_SUCCESS,
            onError: actions.GET_ALL_ENROLLED_COURSES_FAILED,
        },
    }
}

// GET all-portfolio-courses
export const getPortfolioCourses = (params) => {
    return {
        type: config.apiRequestStart,
        payload: {
            // url: config.apiGetPorfolioCourses + ObjectToQueryString(params),
            url: `${config.apiGetPorfolioCourses}?populate=portfolio&${ObjectToQueryString(params, '')}`,
            baseUrl: process.env.REACT_APP_FINEDU_API_BASE_URL,
            method: "get",
            courses: {},
            onStart: actions.GET_PORTFOLIO_COURSES_START,
            onSuccess: actions.GET_PORTFOLIO_COURSES_SUCCESS,
            onError: actions.GET_PORTFOLIO_COURSES_FAILED,
        },
    }
}
// GET -courses-by-portfolio-id
export const getCoursesByPortfolioId = (id, params) => {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiGetPorfolioCourses}?populate=portfolio&portfolio=${id}&${ObjectToQueryString(params, '')}`,
            baseUrl: process.env.REACT_APP_FINEDU_API_BASE_URL,
            method: "get",
            courses: {},
            onStart: actions.GET_PORTFOLIO_COURSES_START,
            onSuccess: actions.GET_PORTFOLIO_COURSES_SUCCESS,
            onError: actions.GET_PORTFOLIO_COURSES_FAILED,
        },
    }
}

// this should set progress in a portfolio to 0 since user has already subscribed to that portfolio
export const getPortfolioCourseProgress = (porfolioId, params) => {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiGetPortfolioCourseProgress}?portfolio=${porfolioId}&${ObjectToQueryString(params, '')}`,
            baseUrl: process.env.REACT_APP_FINEDU_API_BASE_URL,
            method: "get",
            coursesWithProgress: {},
            onStart: actions.GET_COURSES_WITH_PROGRESS_START,
            onSuccess: actions.GET_COURSES_WITH_PROGRESS_SUCCESS,
            onError: actions.GET_COURSES_WITH_PROGRESS_FAILED
        },
    }
};

// get my courses
export const getMyCourses = () => {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiGetPortfolioCourseProgress}`,
            baseUrl: process.env.REACT_APP_FINEDU_API_BASE_URL,
            method: "get",
            myCourses: {},
            onStart: actions.GET_MY_COURSE_START,
            onSuccess: actions.GET_MY_COURSE_SUCCESS,
            onError: actions.GET_MY_COURSE_FAILED
        },
    }
};

// get my courses
export const getCourseProgress = (id) => {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiGetAllCoursesInProgress}?course=${id}`,
            baseUrl: process.env.REACT_APP_FINEDU_API_BASE_URL,
            method: "get",
            courseSubscribed: {},
            onStart: actions.GET_COURSE_PROGRESS_START,
            onSuccess: actions.GET_COURSE_PROGRESS_SUCCESS,
            onError: actions.GET_COURSE_PROGRESS_FAILED
        },
    }
};
// GET all--courses-by-id
export const getCourse = (id) => {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiGetPorfolioCourses}/${id}`,
            baseUrl: process.env.REACT_APP_FINEDU_API_BASE_URL,
            method: "get",
            course: {},
            onStart: actions.GET_COURSE_START,
            onSuccess: actions.GET_COURSE_SUCCESS,
            onError: actions.GET_COURSE_FAILED,
        },
    }
}

// GET all-enrolled-courses
export const getAllCoursesInProgress = () => {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetAllCoursesInProgress,
            baseUrl: process.env.REACT_APP_FINEDU_API_BASE_URL,
            method: "get",
            courses: {},
            onStart: actions.GET_COURSES_PROGRESS_START,
            onSuccess: actions.GET_COURSES_PROGRESS_SUCCESS,
            onError: actions.GET_COURSES_PROGRESS_FAILED,
        },
    }
}

// get articles by course id
export const getArticlesByCourseId = (id) => {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiGetArticlesByCourseId}?course=${id}`,
            baseUrl: process.env.REACT_APP_FINEDU_API_BASE_URL,
            method: "get",
            courses: {},
            onStart: actions.GET_ARTICLES_BY_COURSEID_START,
            onSuccess: actions.GET_ARTICLES_BY_COURSEID_SUCCESS,
            onError: actions.GET_ARTICLES_BY_COURSEID_FAILED,
        },
    }
}

// GET ARTICLE BY ID
export const getArticleById = (id) => {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiGetArticlesByCourseId}/${id}`,
            baseUrl: process.env.REACT_APP_FINEDU_API_BASE_URL,
            method: "get",
            courses: {},
            onStart: actions.GET_ARTICLE_START,
            onSuccess: actions.GET_ARTICLE_SUCCESS,
            onError: actions.GET_ARTICLE_FAILED,
        },
    }
}


// GET CARDS BY ARTICLE ID
export const getCards = (id) => {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiCardsByArticleId}?article=${id}`,
            baseUrl: process.env.REACT_APP_FINEDU_API_BASE_URL,
            method: "get",
            courses: {},
            onStart: actions.GET_CARDS_START,
            onSuccess: actions.GET_CARDS_SUCCESS,
            onError: actions.GET_CARDS_FAILED,
        },
    }
}

// GET portfolio by id
export const getPortfolioById = (id) => {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiGetAllCourses}/${id}`,
            baseUrl: process.env.REACT_APP_FINEDU_API_BASE_URL,
            method: "get",
            portfolio: {},
            onStart: actions.GET_PORTFOLIO_START,
            onSuccess: actions.GET_PORTFOLIO_SUCCESS,
            onError: actions.GET_PORTFOLIO_FAILED,
        },
    }
}
// POST ONBOARDING TEST RESULT
export function postOnboardingTest (testResult) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiPostOnboardingTest,
            baseUrl: process.env.REACT_APP_FINEDU_API_BASE_URL,
            method: "post",
            data: testResult,
            onStart: actions.POST_ONBOARDING_QUIZ_START,
            onSuccess: actions.POST_ONBOARDING_QUIZ_SUCCESS,
            onError: actions.POST_ONBOARDING_QUIZ_FAILED,
        }
    }
}

// POST ONBOARDING TEST RESULT
export function postArticleTest (testResult) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiPostArticleTest,
            baseUrl: process.env.REACT_APP_FINEDU_API_BASE_URL,
            method: "post",
            data: testResult,
            onStart: actions.RECORD_ARTICLE_TEST_START,
            onSuccess: actions.RECORD_ARTICLE_TEST_SUCCESS,
            onError: actions.RECORD_ARTICLE_TEST_FAILED,
        }
    }
}

// POST COURSE PROGRESS
export function postCourseProgress (params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiPostCourseProgress,
            baseUrl: process.env.REACT_APP_FINEDU_API_BASE_URL,
            method: "post",
            data: params,
            onStart: actions.POST_COURSE_PROGRESS_START,
            onSuccess: actions.POST_COURSE_PROGRESS_SUCCESS,
            onError: actions.POST_COURSE_PROGRESS_FAILED,
        }
    }
}

// like a course
export const likeCourse = (courseId) => {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiGetPorfolioCourses}/${courseId}/like`,
            baseUrl: process.env.REACT_APP_FINEDU_API_BASE_URL,
            method: "post",
            // headerConfig,
            course: {},
            onStart: actions.LIKE_START,
            onSuccess: actions.LIKE_SUCCESS,
            onError: actions.LIKE_FAILED,
        },
    }
}

// // unlike a course
export const unlikeCourse = (courseId) => {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiGetPorfolioCourses}/${courseId}/unlike`,
            baseUrl: process.env.REACT_APP_FINEDU_API_BASE_URL,
            method: "post",
            course: {},
            onStart: actions.UNLIKE_START,
            onSuccess: actions.UNLIKE_SUCCESS,
            onError: actions.UNLIKE_FAILED,
        },
    }
}

export const viewCourse = (courseId) => {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiGetCourse}/${courseId}/view`,
            baseUrl: process.env.REACT_APP_FINEDU_API_BASE_URL,
            method: "post",
            // headerConfig,
            course: {},
            onStart: actions.VIEW_START,
            onSuccess: actions.VIEW_SUCCESS,
            onError: actions.VIEW_FAILED,
        },
    }
}


// // like a card
export const likeCard = (cardId) => {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiCardsByArticleId}/${cardId}/like`,
            baseUrl: process.env.REACT_APP_FINEDU_API_BASE_URL,
            method: "post",
            likedCard: {},
            onStart: actions.LIKE_CARD_START,
            onSuccess: actions.LIKE_CARD_SUCCESS,
            onError: actions.LIKE_CARD_FAILED,
        },
    }
}


// // unlike a card
export const unLikeCard = (cardId) => {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiCardsByArticleId}/${cardId}/unlike`,
            baseUrl: process.env.REACT_APP_FINEDU_API_BASE_URL,
            method: "post",
            unLikeCard: {},
            onStart: actions.UNLIKE_CARD_START,
            onSuccess: actions.UNLIKE_CARD_SUCCESS,
            onError: actions.UNLIKE_CARD_FAILED,
        },
    }
}


// // unbookmark a card
export const unBookmarkCard = (cardId) => {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiCardsByArticleId}/${cardId}/unbookmark`,
            baseUrl: process.env.REACT_APP_FINEDU_API_BASE_URL,
            method: "post",
            unBookmarkCard: {},
            onStart: actions.UNBOOKMARK_CARD_START,
            onSuccess: actions.UNBOOKMARK_CARD_SUCCESS,
            onError: actions.UNBOOKMARK_CARD_FAILED,
        },
    }
};
// // BOOKMARK A COURSE
export const bookMarkCard = (cardId) => {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiCardsByArticleId}/${cardId}/bookmark`,
            baseUrl: process.env.REACT_APP_FINEDU_API_BASE_URL,
            method: "post",
            bookMarkCard: {},
            onStart: actions.BOOKMARK_CARD_START,
            onSuccess: actions.BOOKMARK_CARD_SUCCESS,
            onError: actions.BOOKMARK_CARD_FAILED,
        },
    }
};

// // LIKE AN ARTICLE
export const likeArticle = (articleId) => {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiGetArticlesByCourseId}/${articleId}/like`,
            baseUrl: process.env.REACT_APP_FINEDU_API_BASE_URL,
            method: "post",
            likedArticle: {},
            onStart: actions.LIKE_ARTICLE_START,
            onSuccess: actions.LIKE_ARTICLE_SUCCESS,
            onError: actions.LIKE_ARTICLE_FAILED,
        },
    }
}

// // unlike a article
export const unlikeArticle = (articleId) => {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiGetArticlesByCourseId}/${articleId}/unlike`,
            baseUrl: process.env.REACT_APP_FINEDU_API_BASE_URL,
            method: "post",
            likedArticle: {},
            onStart: actions.UNLIKE_ARTICLE_START,
            onSuccess: actions.UNLIKE_ARTICLE_SUCCESS,
            onError: actions.UNLIKE_ARTICLE_FAILED,
        },
    }
}

export const viewArticle = (articleId) => {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiGetArticleById}/${articleId}/view`,
            baseUrl: process.env.REACT_APP_FINEDU_API_BASE_URL,
            method: "post",
            viewedArticle: {},
            onStart: actions.VIEW_ARTICLE_START,
            onSuccess: actions.VIEW_ARTICLE_SUCCESS,
            onError: actions.VIEW_ARTICLE_FAILED,
        },
    }
};

// GET COURSE STATUS
export const getCourseStatus = () => {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiGetProfile}`,
            baseUrl: process.env.REACT_APP_FINEDU_API_BASE_URL,
            method: "get",
            profileCourse: {},
            onStart: actions.GET_COURSE_STATUS_START,
            onSuccess: actions.GET_COURSE_STATUS_SUCCESS,
            onError: actions.GET_COURSE_STATUS_FAILED
        },
    }
};

// UPDATE COURSE STATUS (increase ther)
export const updateCourseStatus = () => {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiGetProfile}`,
            baseUrl: process.env.REACT_APP_FINEDU_API_BASE_URL,
            method: "put",
            data: {
                isOnboarded: true
            },
            profileCourseUpdate: {},
            onStart: actions.UPDATE_COURSE_ONBOARDING_STATUS_START,
            onSuccess: actions.UPDATE_COURSE_ONBOARDING_STATUS_SUCCESS,
            onError: actions.UPDATE_COURSE_ONBOARDING_STATUS_FAILED
        },
    }
};

// manage article progress
export const postArticleProgress = (params) => {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiGetArticleProgress}`,
            baseUrl: process.env.REACT_APP_FINEDU_API_BASE_URL,
            method: "post",
            data: params,
            progressedArticle: {},
            onStart: actions.ARTICLE_PROGRESS_START,
            onSuccess: actions.ARTICLE_PROGRESS_SUCCESS,
            onError: actions.ARTICLE_PROGRESS_FAILED
        },
    }
};

export const getArticleProgress = (id) => {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiGetArticleProgress}?article=${id}`,
            baseUrl: process.env.REACT_APP_FINEDU_API_BASE_URL,
            method: "get",
            progressedArticle: {},
            onStart: actions.ARTICLE_PROGRESS_START,
            onSuccess: actions.ARTICLE_PROGRESS_SUCCESS,
            onError: actions.ARTICLE_PROGRESS_FAILED
        },
    }
};

export const subscribeToPortfolio = (id) => {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiEnrollPortfolio}/${id}/enroll`,
            baseUrl: process.env.REACT_APP_FINEDU_API_BASE_URL,
            method: "get",
            portfolioEnrolled: {},
            onStart: actions.ENROLL_PORTFOLIO_START,
            onSuccess: actions.ENROLL_PORTFOLIO_SUCCESS,
            onError: actions.ENROLL_PORTFOLIO_FAILED
        },
    }
};

// enroll user to a course
export const subscribeToCourse = (id) => {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiGetPorfolioCourses}/${id}/enroll`,
            baseUrl: process.env.REACT_APP_FINEDU_API_BASE_URL,
            method: "post",
            courseEnrolled: {},
            onStart: actions.ENROLL_COURSE_START,
            onSuccess: actions.ENROLL_COURSE_SUCCESS,
            onError: actions.ENROLL_COURSE_FAILED
        },
    }
};


// get the progress of all articles in a course;
export const getProgressOfAllArticlesInCourse = (courseId) => {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiGetArticleProgress}?course=${courseId}&populate=article`,
            baseUrl: process.env.REACT_APP_FINEDU_API_BASE_URL,
            method: "get",
            articlesWithProgress: {},
            onStart: actions.GET_ARTICLE_PROGRESS_IN_COURSE_START,
            onSuccess: actions.GET_ARTICLE_PROGRESS_IN_COURSE_SUCCESS,
            onError: actions.GET_ARTICLE_PROGRESS_IN_COURSE_FAILED,
        },
    }
}

