import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { customizeSVG } from "utils/customizeSVG.jsx";
import logo from "assets/images/icons/grn-logo.svg";
import { ReactComponent as IconFacebook } from "assets/images/social/facebook.svg";
import { ReactComponent as IconInstagram } from "assets/images/social/instagram.svg";
import { ReactComponent as IconLinkedin } from "assets/images/social/linkedin.svg";
import { ReactComponent as IconDashboard } from "assets/images/icons/project-icons/VectorHome.svg";
import { ReactComponent as IconChartLines } from "assets/images/icons/project-icons/Chart-lines.svg";
// import { ReactComponent as IconGraph } from "assets/images/icons/project-icons/Graph.svg";
// import { ReactComponent as IconWallet } from "assets/images/icons/project-icons/Wallet.svg";
import { ReactComponent as IconLogout } from "assets/images/icons/project-icons/Logout.svg";
// import { ReactComponent as IconEdu } from "assets/images/icons/project-icons/edumenu.svg";
// import { ReactComponent as IconMint } from "assets/images/newEduAssets/svgs/mint.svg";
// import { ReactComponent as IconUserAccount } from "assets/images/newDesignAssets/svgs/Useraccount.svg";
// import { maxHeight } from 'tailwindcss/defaultTheme';
import * as auth from "store/auth/user/action";
import { getCourseStatus } from "store/entities/educations/action";

class Sidebar extends Component {
	state = {};

	componentDidMount() {
		// this.props.getCourseProfile();
	}
	render() {
		// const { profileCourse } = this.props;
		// const homeUrl = profileCourse?.data?.isOnboarded === false ? "/mint" : "/mint/courses";
		customizeSVG();

		return (
			<>
				<ReactTooltip place={"right"} />

				<div className="w-24 h-screen hidden lg:block flex-none fixed top-0 bottom-0 left-0 bg-new-ep-blue shadow-lg">
					<div
						className="w-full h-full flex flex-col sidebar-bg-ep-pattern bg-cover bg-no-repeat"
						style={{ backgroundPosition: "0 20px" }}>
						<div className="w-full mt-6 flex-shrink-0">
							<Link to="/dashboard" className="h-10 flex">
								<img
									src={logo}
									alt="EarniPay logo"
									className="h-full mx-auto object-contain"
								/>
							</Link>
						</div>

						<div className="flex-grow flex overflow-auto sidebar-short">
							<div className="mx-auto mt-14 space-y-4">
								<NavLink
									to="/dashboard"
									className="sidebar-links group"
									data-tip="Dashboard">
									<IconDashboard className="customizeSVG sidebar-links-icon" />
								</NavLink>

								<NavLink
									to="/withdrawals"
									className="sidebar-links group"
									data-tip="Withdrawals">
									<IconChartLines className="customizeSVG sidebar-links-icon" />
								</NavLink>

								{/* <NavLink to="/earnings" className="sidebar-links group" data-tip="Earnings">
                                    <IconGraph className="customizeSVG sidebar-links-icon" />
                                </NavLink> */}

								{/* 
								<NavLink
									to="/referrals"
									className="sidebar-links group"
									data-tip="Referrals">
									<IconWallet className="customizeSVG sidebar-links-icon" />
								</NavLink>
								 */}

								{/* 
								<NavLink
									to={homeUrl}
									className="sidebar-links group"
									data-tip="Mint">
									<IconMint className="customizeSVG sidebar-links-icon" />
								</NavLink>
								 

								<NavLink
									to="/account"
									className="sidebar-links group"
									data-tip="Account">
									<IconUserAccount className="customizeSVG sidebar-links-icon" />
								</NavLink>
								*/}

								<div className="h-px bg-white opacity-30 sidebar-footer-secondary"></div>

								<div
									className="sidebar-footer-secondary pb-28"
									data-tip="Logout">
									<div
										onClick={this.props.logout}
										className="sidebar-links group"
										data-tip="Logout">
										<IconLogout className="customizeSVG sidebar-links-icon" />
									</div>
								</div>
							</div>
						</div>

						<div className="w-full sidebar-footer flex-shrink-0 mb-6">
							<div
								onClick={this.props.logout}
								className="sidebar-links group"
								data-tip="Logout">
								<IconLogout className="customizeSVG sidebar-links-icon" />
							</div>
						</div>
					</div>
				</div>

				<div className="w-full h-screen bg-blur block lg:hidden fixed z-40 top-0 bottom-0 left-0 right-0 overflow-auto overscroll-contain">
					<div className="w-full h-full flex bg-white bg-opacity-50">
						<div className="my-auto pt-20 pb-36">
							<div className="w-screen">
								<div className="header-links-mobile">
									<Link
										to="/dashboard"
										onClick={this.props.nesttoggleLeftSidebarMobile}>
										Dashboard
									</Link>
								</div>
								<div className="header-links-mobile">
									<Link
										to="/withdrawals"
										onClick={this.props.nesttoggleLeftSidebarMobile}>
										Withdrawals
									</Link>
								</div>
								{/* <div className="header-links-mobile">
                                    <Link to="/earnings" onClick={this.props.nesttoggleLeftSidebarMobile}>Earnings</Link>
                                </div> */}
								{/* 
								<div className="header-links-mobile">
									<Link
										to="/referrals"
										onClick={this.props.nesttoggleLeftSidebarMobile}>
										Referrals
									</Link>
								</div>
								<div className="header-links-mobile">
									<Link
										to={homeUrl}
										onClick={this.props.nesttoggleLeftSidebarMobile}>
										Mint
									</Link>
								</div>
								<div className="header-links-mobile">
									<Link
										to="/account"
										onClick={this.props.nesttoggleLeftSidebarMobile}>
										Accounts
									</Link>
								</div>
								 */}
							</div>

							<div className="flex mt-10">
								<div className="mx-auto flex space-x-6">
									<Link
										to={{ pathname: "https://www.facebook.com/Earnipay" }}
										target="_blank"
										onClick={this.props.nesttoggleLeftSidebarMobile}
										className="header-links-social group">
										<IconFacebook className="customizeSVG m-auto text-black group-hover:text-white" />
									</Link>
									<Link
										to={{ pathname: "https://www.instagram.com/getearnipay/" }}
										target="_blank"
										onClick={this.props.nesttoggleLeftSidebarMobile}
										className="header-links-social group">
										<IconInstagram className="customizeSVG m-auto text-black group-hover:text-white" />
									</Link>
									<Link
										to={{
											pathname: "https://www.linkedin.com/company/earnipay",
										}}
										target="_blank"
										onClick={this.props.nesttoggleLeftSidebarMobile}
										className="header-links-social group">
										<IconLinkedin className="customizeSVG m-auto text-black group-hover:text-white" />
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	loadingProfileCourse: state.entities.educations.loadingProfileCourse,
	profileCourse: state.entities.educations.profileCourse,
});

const mapDispatchToProps = (dispatch) => ({
	logout: () => dispatch(auth.logoutUser()),
	getCourseProfile: () => dispatch(getCourseStatus()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
