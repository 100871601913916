import React from 'react';
import { Dialog } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import animateSuccess from "assets/images/newDesignAssets/images/successfully-animation.gif";
import { CurrencyFormat } from 'utils/currencyFormat';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const WithdrawalModal = (props) => {
    const { withdrawalFeePayload: wdlPayload } = useSelector(state => state.entities.dashboard)
    const feeTypePercent = wdlPayload?.fee?.type === 'percentage'
    const feeValue = wdlPayload?.fee?.fee
    const percentInNaira = wdlPayload?.fee?.calculatedFee

    const getDynamicFee = () => {
        if (feeTypePercent) {
            return percentInNaira
        } else {
            return feeValue
        }
    }

    const completeButtonRef = React.createRef();
    const {
        open, handlePrev, withdrawalParams, current, handleChange, purpose, amount, handleToggleModalWithdrawal,
        // submitForm, displayWithdrawalFees, submitForm1, transactionFee, withdrawalFeeLoading, handleSetCurrent, cloaseModalAndClearFormFields,
        submitForm, displayWithdrawalFees, submitForm1, transactionFee, withdrawalFeeLoading, cloaseModalAndClearFormFields,
        loadingNewWithdrawal, newWithdrawalSuccess, paymentFeeBy, passwordForm, formChange, bgCol, addBgCol, refreshEarnipay, handleNext,
        loadingReasons, successReasonWithdrawal, withdrawalReasons, memorizedReasonWithdrawal, destinationAccount, userReason,
        autopayFee, autopayFeeLoading, allowAutopayToggle, withdrawalForm, walletDetails
    } = props;

    const halfFee = transactionFee * 0.5;
    const transactionCharge = paymentFeeBy === 'shared' ? halfFee : paymentFeeBy === 'employer' ? 0 : getDynamicFee();

    return (
        <Dialog
            open={open} initialFocus={completeButtonRef}
            onClose={() => handleToggleModalWithdrawal(false)}
            className="fixed z-50 inset-0 overflow-y-auto">
            {current === 0 && (
                <div className="flex items-center justify-center border-2 min-h-screen border-black" style={{ "height": "550px" }} >
                    <Dialog.Overlay className="fixed inset-0 bg-white bg-blur bg-opacity-50" />
                    <div className="modal-box">
                        <div className="modal-header">
                            <div className="modal-btn invisible"></div>
                            <div className="flex justify-between px-6">
                                <div onClick={() => handleToggleModalWithdrawal(false)} className="modal-btn btn-close cursor-pointer">
                                    <FontAwesomeIcon icon="angle-left" className="text-blaxk m-auto" />
                                </div>
                                <div className='font-recoleta text-base'>On-Demand Pay</div>
                                <div onClick={() => handleToggleModalWithdrawal(false)} ref={completeButtonRef} className="modal-btn btn-close cursor-pointer">
                                    <FontAwesomeIcon icon="times" className=" m-auto" />
                                </div>
                            </div>
                            <div ref={completeButtonRef} className="modal-btn btn-close hidden">
                                <FontAwesomeIcon icon="times" className="m-auto" />
                            </div>
                        </div>

                        <form className="px-7 " onSubmit={submitForm1}>
                            
                            <div className="my-4">
                                <div className="form-group ">
                                    <label htmlFor="withdrawal" className='text-sm pb-2' style={{ 'color': '#718096' }}>Get paid</label>
                                    <div className=' flex justify-between '>
                                        <button type='button' className='form-input-round'
                                            onClick={(e) => {
                                                e.preventDefault();
                                                withdrawalForm.amount = 500
                                                displayWithdrawalFees();
                                            }}
                                        >500</button>
                                        <button type='button' className='form-input-round'
                                            onClick={(e) => {
                                                e.preventDefault();
                                                withdrawalForm.amount = 5000
                                                displayWithdrawalFees();
                                            }}
                                        >5000</button>
                                        <button type='button' className='form-input-round'
                                            onClick={(e) => {
                                                e.preventDefault();
                                                withdrawalForm.amount = 10000
                                                displayWithdrawalFees();
                                            }}
                                        >10000</button>

                                    </div>
                                </div>
                            </div>

                            <div className="my-4">
                                <div className="form-group ">
                                    <label htmlFor="withdrawal" className='text-sm'>
                                        Enter Amount
                                        <span className="form-input-required">*</span>
                                    </label>
                                    <input type="text" name="amount" className='form-input' placeholder='N500' value={amount} onChange={handleChange} onKeyUp={() => displayWithdrawalFees()} />
                                </div>
                            </div>

                            <div className="my-2">
                                <div className="form-group ">
                                    <label htmlFor="withdrawal" className='text-sm'>
                                        Destination
                                        <span className="form-input-required">*</span>
                                    </label>

                                    <select
                                        name="destinationAccount"
                                        className='form-input' placeholder='Select Destination Account'
                                        style={{ "color": `${destinationAccount !== '' ? '#000' : "#9CA3AF"}` }}
                                        value={destinationAccount}
                                        // onChange={handleChange}
                                        readOnly={true}
                                    >

                                        {/* <option value=''>Select Destination Account</option> */}
                                        {/* <option value="salary-account">Salary Account</option> */}

                                        {(!walletDetails?.data?.accountNumber || !walletDetails?.data?.bankName) &&
                                            <option value="earnipay-account">
                                                Earnipay Account
                                            </option>
                                        }

                                        {walletDetails?.data?.accountNumber && walletDetails?.data?.bankName &&
                                            <option value="earnipay-account">
                                                Earnipay Account
                                                (
                                                    {walletDetails?.data?.accountNumber ?? "-"} |&nbsp;
                                                    {walletDetails?.data?.bankName?.substring(0,1)?.toUpperCase() ?? ""}
                                                    {walletDetails?.data?.bankName?.substring(1) ?? ""}
                                                )
                                            </option>
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="my-2">
                                <div className="form-group ">
                                    <label htmlFor="withdrawal" className='text-sm'>Withdrawal reason</label>

                                    <select
                                        name="purpose"
                                        className='form-input' placeholder='Select withdrawal reason'
                                        style={{ "color": `${purpose ? '#000' : '#9CA3AF'}` }}
                                        value={purpose}
                                        onChange={handleChange}
                                    >
                                        <option value="reason0">Select withdrawal reason</option>
                                        {loadingReasons ? (<FontAwesomeIcon icon='spinner' spin />) : (
                                            (successReasonWithdrawal) && (withdrawalReasons) && (memorizedReasonWithdrawal) && memorizedReasonWithdrawal.length > 0 && memorizedReasonWithdrawal.map((reason, index) => <option value={reason.id} key={index}>{reason.name.charAt(0).toUpperCase() + reason.name.slice(1)}</option>)
                                        )}
                                    </select>
                                </div>
                            </div>
                            {
                                (purpose === '100') && (
                                    <div className="my-4">
                                        <div className="form-group ">
                                            <label htmlFor="user reason" className='text-sm'>
                                                State your purpose
                                                <span className="form-input-required">*</span>
                                            </label>
                                            <textarea className='form-input' name="userReason" cols="30" rows="5" value={userReason} onChange={handleChange}></textarea>
                                        </div>
                                    </div>
                                )
                            }

                            <div className="mt-2 pt-2 flex justify-end space-x-2">

                                <button type="submit" className={`btn btn-lg btn-block ${amount < 500 ? 'btn-bg-disabled' : 'btn-ep-blue'}`} disabled={amount < 500}>
                                    {/* <FontAwesomeIcon icon="spinner" spin className={"text-xl mr-2 hidden" + (true ?"inline":"hidden")} /> */}
                                    Get Paid
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
            {current === 1 && (

                <div className="flex items-center justify-center border-2 min-h-screen border-black" style={{ "height": "550px" }}>
                    <Dialog.Overlay className="fixed inset-0 bg-white bg-blur bg-opacity-50" />
                    <div className="modal-box">
                        <div className="modal-header">
                            {/* header */}
                            <div className="modal-btn invisible"></div>
                            <div className="flex justify-between px-6">
                                <div onClick={() => handlePrev()} className="modal-btn btn-close cursor-pointer">
                                    <FontAwesomeIcon icon="angle-left" className="text-blaxk m-auto" />
                                </div>
                                <div className='font-recoleta text-base'>Review</div>
                                <div onClick={(e) => handleToggleModalWithdrawal(false)} className="modal-btn btn-close cursor-pointer">
                                    <FontAwesomeIcon icon="times" className=" m-auto" />
                                </div>
                            </div>

                        </div>
                        <div className=" text-center py-4 my-2" >
                            <strong className=' sm:text-lg md:text-4xl text-center font-recoleta font-semibold'>{CurrencyFormat(withdrawalParams.amount)}</strong>
                        </div>
                        <div className="px-7 my-4 font-sans ">
                            <div className='text-sm ep-card-blue flex justify-between my-2 bg-transaction-card'>
                                <div>
                                    <div className='py-1 bg-transaction-card-text'>Destination</div>
                                    <div className='py-1 bg-transaction-card-text'>Payment Reason</div>
                                </div>
                                <div className=' pl-4'>
                                    <div className='py-1 capitalize'>{destinationAccount}</div>
                                    {!newWithdrawalSuccess && <div className='py-1'>{(memorizedReasonWithdrawal && purpose !== "" && purpose !== '100') ? memorizedReasonWithdrawal[purpose - 1]?.name : userReason ? userReason : ''}</div>}
                                    {/* <div className='py-1'>{purpose}</div> */}
                                </div>
                            </div>

                            <div className=' text-sm font-medium ep-card-blue flex justify-between bg-transaction-card'>
                                <div>
                                    <div className='py-1 bg-transaction-card-text'>You will recieve</div>
                                    <div className='py-1 bg-transaction-card-text'>Transaction Fee</div>
                                </div>

                                <div className='py-1 font-semibold w-28 pl-2'>
                                    <div className='py-1 font-semibold ' > {CurrencyFormat(amount - transactionCharge)} </div>
                                    {(withdrawalFeeLoading) &&
                                        <FontAwesomeIcon icon="spinner" spin className="text-xl mr-2" />
                                    }
                                    {(!withdrawalFeeLoading) &&
                                        <div style={{ "color": "#A18A08" }} className='block'>
                                            {CurrencyFormat(transactionCharge)}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="px-7 pt-3 ">
                            {(withdrawalFeeLoading || autopayFeeLoading) &&
                                <FontAwesomeIcon icon="spinner" spin className="text-xl mr-2" />
                            }
                            {
                                (!autopayFeeLoading && !withdrawalFeeLoading && autopayFee && autopayFee.estimatedFee > 0 && transactionCharge - autopayFee.estimatedFee > 0) &&
                                <div className='bg-transaction-card2 rounded-md py-2 text-sm'>
                                    <div className='px-3 py-1'>

                                        <div className='pl-4 pr-2 text-[#1F2937]' style={{ 'color': '#1F2937' }}>
                                            Save {CurrencyFormat(transactionCharge - autopayFee.estimatedFee)} from your transaction fees when you use flexx for withdrawals
                                        </div>

                                    </div>
                                    <div className='bg-transaction-go2flex'>
                                        <Link onClick={() => allowAutopayToggle()}
                                        >Click here to use Flexx</Link>
                                    </div>
                                </div>
                            }

                        </div>
                        <div className="px-7 pt-3">

                            <div className='mt-4 text-center relative top-2'>
                                <small className='text-sm'>By Clicking Proceed you authorize a deduction of <span className='text-ep-blue text-xs'>{CurrencyFormat(amount)}</span> from your salary </small>
                            </div>
                            <div className=" pt-6 flex justify-end space-x-2" onClick={() => handleNext()}>
                                <button type="submit" className={`btn btn-lg btn-block ${amount < 500 ? 'btn-bg-disabled' : 'btn-ep-blue'}`} disabled={loadingNewWithdrawal}>
                                    {loadingNewWithdrawal === true && <FontAwesomeIcon icon="spinner" spin className={"text-xl mr-2"} />}
                                    Proceed ({CurrencyFormat(amount)})
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {current === 2 && (
                <div className="flex items-center justify-center border-2 min-h-screen border-black" style={{ "height": "550px" }}>
                    <Dialog.Overlay className="fixed inset-0 bg-white bg-blur bg-opacity-50" />

                    <div className="modal-box">
                        <div className=" mb-4 mx-auto flex justify-between px-6">
                            <FontAwesomeIcon icon="angle-left" className="text-gray-500 my-auto cursor-pointer" onClick={() => handlePrev()} />
                            <div className=' font-recoleta '>Transaction Pin</div>
                            <FontAwesomeIcon icon="times" className="text-gray-500 my-auto cursor-pointer" onClick={() => cloaseModalAndClearFormFields()} />
                        </div>
                        <div className="w-full max-w-lg mx-auto pt-10">
                            <div className='text-center font-recoleta font-semibold py-2 text-lg lg:text-xl'>Enter your Transaction Pin</div>
                            <div className='bg-white w-full pt-5'>
                                <form className=' mx-auto'>

                                    <div className="form-group">

                                        <div className='flex justify-between flex-wrap text-white px-6 '>
                                            <input type="password" name="newpin1" className="form-input text-center bg-new-ep-grey" id='pin-input1' maxLength={1} value={passwordForm.newpin1} onChange={formChange} style={{ "backgroundColor": `${bgCol.box1}`, "color": "#fff" }} onKeyUp={() => addBgCol()} />
                                            <input type="password" name="newpin2" className="form-input text-center bg-new-ep-grey" id='pin-input2' maxLength={1} value={passwordForm.newpin2} onChange={formChange} style={{ "backgroundColor": `${bgCol.box2}`, "color": "#fff" }} onKeyUp={() => addBgCol()} />
                                            <input type="password" name="newpin3" className="form-input text-center bg-new-ep-grey" id='pin-input3' maxLength={1} value={passwordForm.newpin3} onChange={formChange} style={{ "backgroundColor": `${bgCol.box3}`, "color": "#fff" }} onKeyUp={() => addBgCol()} />
                                            <input type="password" name="newpin4" className="form-input text-center bg-new-ep-grey" id='pin-input4' maxLength={1} value={passwordForm.newpin4} onChange={formChange} style={{ "backgroundColor": `${bgCol.box4}`, "color": "#fff" }} onKeyUp={() => addBgCol()} />
                                        </div>
                                    </div>

                                    <div className='px-6 pt-10'>
                                        <button type="button" className="btn btn-lg btn-block bg-payment-ep-blue text-white mt-8"
                                            onClick={() => submitForm()}
                                            disabled={loadingNewWithdrawal || passwordForm.newpin1 === '' || passwordForm.newpin2 === '' || passwordForm.newpin3 === '' || passwordForm.newpin4 === ''}
                                        >
                                            <FontAwesomeIcon icon="spinner" spin className={"text-xl mr-2 " + (loadingNewWithdrawal ? "inline" : "hidden")}

                                            />
                                            Continue
                                        </button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            )}

            {((newWithdrawalSuccess === true) && (current >= 2)) && (
                <div className="flex items-center justify-center border-2 min-h-screen border-black" style={{ "height": "550px" }}>
                    <Dialog.Overlay className="fixed inset-0 bg-white bg-blur bg-opacity-50" />
                    <div className="modal-box">
                        <div className="modal-header"  >
                            {/* header */}
                            <div className="modal-btn invisible"></div>
                            <div className="flex justify-between px-6">

                                <div className="h-48 text-center mx-auto my-8 ">
                                    <img src={animateSuccess} alt="EarniPay" className="h-full object-contain mx-auto" />
                                </div>
                                <div className="modal-btn btn-close hidden">
                                    <FontAwesomeIcon icon="times" className="text-blaxk m-auto" />
                                </div>
                            </div>

                        </div>

                        <div className="my-4">
                            <div className='font-recoleta text-center text-2xl'> Success</div>
                            <div className='font-sans text-center text-base' style={{ "color": "#374151" }}> Withdrawal successful</div>
                        </div>
                        <div className="mt-6 pt-6 flex justify-end space-x-2">

                            <button onClick={() => refreshEarnipay()} type="submit" className="btn btn-lg btn-block btn-ep-blue mx-5" ref={completeButtonRef}>
                                Go To Dashboard
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </Dialog>
    )
}

export default WithdrawalModal;
