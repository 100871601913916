import { monthNamesShort } from "./dateFormat";


export const CurrentDate = (separator = '-') => {
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  let newDate = new Date();
  let day = newDate.getDay();
  let date = newDate.getDate();
  let month = newDate.getMonth();
  let year = newDate.getFullYear();

  // const monthDisplay = ((month < 10) ? '0' + month : month);
  const dayDisplay = ((date < 10) ? '0' + date : date);

  return (days[day] + ", " + monthNamesShort[month] + separator + dayDisplay + separator + year);

};

export default CurrentDate;
