import * as actions from "./actionTypes";
import { toast } from 'react-toastify';

const initialState = {
    profileUpdateLoading: false,
    profileUpdated: false,
    passwordUpdateLoading: false,
    passwordUpdated: false,

    walletDetailsLoading: false,
    walletDetails: {},
    userInfoLoading: false,
    userInfo: {},
};


export default function reducer(state = initialState, action){
    switch (action.type){

        case actions.UPDATE_PROFILE:
            toast.success(action.payload.message);
            state = {
                ...state,
                profileUpdateLoading: false,
                profileUpdated: true,
            }
            return state;

           
        case actions.UPDATE_PROFILE_START:
            state = {
                ...state,
                profileUpdateLoading: true,
            }
            return state;


        case actions.UPDATE_PROFILE_FAILED:
            if (action.payload.message === "Validation Errors"){
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function(key) {
                    for (let i = 0; i < payloadErrors[key].length; i++){
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else{
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                profileUpdateLoading: false,
            }
            return state;


        case actions.UPDATE_PROFILE_RESET:
            state = {
                ...state,
                profileUpdated: false,
            }
            return state;


        case actions.UPDATE_PASSWORD:
            toast.success(action.payload.message);
            state = {
                ...state,
                passwordUpdateLoading: false,
                passwordUpdated: true,
            }
            return state;

           
        case actions.UPDATE_PASSWORD_START:
            state = {
                ...state,
                passwordUpdateLoading: true,
            }
            return state;


        case actions.UPDATE_PASSWORD_FAILED:
            if (action.payload.message === "Validation Errors"){
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function(key) {
                    for (let i = 0; i < payloadErrors[key].length; i++){
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else{
                toast.error(action.payload.message);
            }
            
            state = {
                ...state,
                passwordUpdateLoading: false,
            }
            return state;


        case actions.UPDATE_PASSWORD_RESET:
            state = {
                ...state,
                passwordUpdated: false,
            }
            return state;


        case actions.GET_WALLET_DETAILS:
            state = {
                ...state,
                walletDetailsLoading: false,
                walletDetails: action.payload,
            }
            return state;


        case actions.GET_WALLET_DETAILS_START:
            state = {
                ...state,
                walletDetailsLoading: true,
                walletDetails: {},
            }
            return state;


        case actions.GET_WALLET_DETAILS_FAILED:
            toast.error(action.payload.message);
            state = {
                ...state,
                walletDetailsLoading: false,
                walletDetails: {},
            }
            return state;
    
        
        case actions.GET_USER_INFO:
            state = {
                ...state,
                userInfoLoading: false,
                userInfo: action.payload,
            }
            return state;


        case actions.GET_USER_INFO_START:
            state = {
                ...state,
                userInfoLoading: true,
                userInfo: {},
            }
            return state;


        case actions.GET_USER_INFO_FAILED:
            toast.error(action.payload.message);
            state = {
                ...state,
                userInfoLoading: false,
                userInfo: {},
            }
            return state;
    

        default:
            return state;
    }
}