import * as actions from "./actionTypes";
import { toast } from 'react-toastify';
import logger from "utils/logger";

const initialState = {
    loading: false,
    allWithdrawals: {},
    allWithdrawalsSidebar: {},
    reloadPage: false,
    withdrawalParams: {},
    current: 0,
    schedules: {},
    schedule: {},
    autopayCancellationFee: {},
    secheduleGet: {},
    updatedSchedule: {},
    isDeletedSchedule: false,
    deleteErrorMsg: '',
    isOnboarded: false,
};


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.GET_ALL_WITHDRAWALS:
            state = {
                ...state,
                loading: false,
                allWithdrawals: { ...action.payload }
            }
            return state;


        case actions.GET_ALL_WITHDRAWALS_START:
            state = {
                ...state,
                loading: true,
            }
            return state;


        case actions.GET_ALL_WITHDRAWALS_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loading: false,
                allWithdrawals: {},
            }
            return state;
        
        
        // get withdrawal sidebar
        case actions.GET_ALL_WITHDRAWALSIDEBAR:
            state = {
                ...state,
                loading: false,
                allWithdrawalsSidebar: { ...action.payload }
            }
            return state;


        case actions.GET_ALL_WITHDRAWALSIDEBAR_START:
            state = {
                ...state,
                loading: true,
            }
            return state;


        case actions.GET_ALL_WITHDRAWALSIDEBAR_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loading: false,
                allWithdrawalsSidebar: {},
            }
            return state;
        // autopayment schedule
        case actions.CREATE_PAYMENT_SCHEDULE_START:
            state = {
                ...state,
                loading: true,
            }
            return state;

        case actions.CREATE_PAYMENT_SCHEDULE:
            state = {
                ...state,
                loading: false,
                schedule: { ...action.payload }
            }
            toast.success("autopayment scheduled successfully");
            logger("employeeweb-flexi-get-paid")
            return state;
        case actions.CREATE_PAYMENT_SCHEDULE_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loading: false,
                schedule: {},
            }
            return state;
        
        // flex cancellation

        case actions.CANCEL_AUTOPAY_START:
            state = {
                ...state,
                loading: true,
            }
            return state;

        case actions.CANCEL_AUTOPAY:
            state = {
                ...state,
                loading: false,
                autopayCancellationFee: { ...action.payload }
            }
            // toast.success("autopayment scheduled successfully");
            // logger("employeeweb-flexi-get-paid")
            return state;
        case actions.CANCEL_AUTOPAY_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loading: false,
                autopayCancellationFee: {},
            }
            return state;

        case actions.GET_ALL_PAYMENT_SCHEDULE_START:
            state = {
                ...state,
                loading: true,
            }
            return state;
        case actions.GET_ALL_PAYMENT_SCHEDULE :
            state = {
                ...state,
                loading: false,
                schedules: { ...action.payload }
            }
            return state;
        case actions.GET_ALL_PAYMENT_SCHEDULE_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loading: false,
                schedules: {},
            }
            return state;


        case actions.GET_PAYMENT_SCHEDULE_START:
            state = {
                ...state,
                loading: true,
            }
            return state;
        case actions.GET_PAYMENT_SCHEDULE :
            state = {
                ...state,
                loading: false,
                secheduleGet: { ...action.payload }
            }
            return state;
        case actions.GET_PAYMENT_SCHEDULE_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loading: false,
                secheduleGet: {},
            }
            return state;
        
        case actions.UPDATE_PAYMENT_SCHEDULE_START:
            state = {
                ...state,
                loading: true,
            }
            return state;

        case actions.UPDATE_PAYMENT_SCHEDULE :
            state = {
                ...state,
                loading: false,
                updatedSchedule: { ...action.payload }
            }
            return state;
        
        case actions.UPDATE_PAYMENT_SCHEDULE_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loading: false,
                updatedSchedule: {},
            }
            return state;
    
            
        case actions.DELETE_PAYMENT_SCHEDULE_START:
            state = {
                ...state,
                loading: true,
            }
            return state;

        case actions.DELETE_PAYMENT_SCHEDULE :
            state = {
                ...state,
                loading: false,
                isDeletedSchedule: true,
                deleteErrorMsg: action.payload.message
            }
            toast.success("Flexx cancelled successfully");

            return state;
        
        case actions.DELETE_PAYMENT_SCHEDULE_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loading: false,
                schedule: {},
            }
            return state;
        
        case actions.ACTIVATE_AUTOPAY_START:
            state = {
                ...state,
                loading: true,
            }
            return state;

        case actions.ACTIVATE_AUTOPAY:
            state = {
                ...state,
                loading: false,
                isOnboarded: true
            }
            return state;
        
        case actions.ACTIVATE_AUTOPAY_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loading: false,
                isOnboarded: {},
            }
            return state;
        
        case actions.RELOAD_PAGE_RESET:
            state = {
                ...state,
                reloadPage: false
            }
            return state;
        
        case actions.NEXT:
            state = {
                ...state,
                current: state.current + 1,
                withdrawalParams: { amount: action.payload.amount, purpose: action.payload.purpose },
            }
            return state;
        case actions.ZERO:
            state = {
                ...state,
                current: 0,
            }
            return state;
        case actions.PREV:
            state = {
                ...state,
                current: state.current - 1,
            }
        
            return state;
        
        
        case actions.RESET_WITHDRAWAL:
            state = {
                ...state,
                current: 0, 
                withdrawalParams: {},
            }
            return state;
         

        default:
            return state;
    }
}