import * as actions from "./actionTypes";
import { jwtDecode } from "jwt-decode";

const initialState = {
    loggedIn: false,
    data: {},
    signingUp: false,
    signUpPhoneNumberVerfied: false,
    signUpPinCreated: false,
    signUpUser: {},
    validatedPinDetails: {},
    loading: false,
    pinCreateSuccess: false,
};


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.LOGIN_USER:
            const {token, user, refreshToken} = action.payload.data;
            state = {
                ...state,
                loggedIn: true,
                data: {
                    ...user,
                    token: token,
                    refreshToken,
                    tokenExpiry: jwtDecode(token)?.exp ?? 0,
                    passwordSet: action?.payload?.data?.passwordSet ?? "",
                    name: user.firstName + " " + user.lastName,
                    bankName: "",
                    bankAccountNumber: "",
                    earningPercent: 0,
                    cutOffDate: 29,
                    referralCode: "",
                    referralBonus: "",
                    autoPaySetup: "",
                },
                signingUp: false,
            }
            return state;


        case actions.LOGIN_USER_START:
            state = {
                ...state,
                loggedIn: false,
                data: {}
            }
            return state;


        case actions.LOGIN_USER_FAILED:
            state = {
                ...state,
                loggedIn: false,
                data: {}
            }
            return state;


        case actions.UPDATE_LOGIN_PROFILE:
            state = {
                ...state,
                data: {
                    ...state.data,
                    ...action.payload,
                },
            }
            return state;


        case actions.LOGOUT_USER:
            state = {
                ...state,
                loggedIn: false,
                data: {}
            }
            window.location.href = "/login";
            return state;

        case actions.SAVE_SIGNUP_DETAILS:
            state = {
                ...state,
                signUpUser: {
                    phoneNumber: action.payload.phoneNumber,
                },
            }
            return state;
        
        case actions.SEND_ACTIVATION_PIN:
            state = {
                ...state,
                signingUp: true,
                signUpPhoneNumberVerfied: true,
            }
            return state;


        case actions.SEND_ACTIVATION_PIN_START:
            state = {
                ...state,
                loggedIn: false,
                signingUp: true,
                signUpPhoneNumberVerfied: false,
            }
            return state;


        case actions.SEND_ACTIVATION_PIN_FAILED:
            state = {
                ...state,
                signingUp: true,
                signUpPhoneNumberVerfied: false,
            }
            return state;


        case actions.SEND_ACTIVATION_PIN_RESET:
            state = {
                ...state,
                signingUp: true,
                signUpPhoneNumberVerfied: false,
            }
            return state;

       

        case actions.SET_LOGIN_PIN:
            state = {
                ...state,
                signingUp: true,
                signUpPinCreated: true,
                rawData: action.payload,
                data: {
                    token: action.payload.token,
                    _id: action.payload.employeeData._id,
                    name: action.payload.employeeData.name,
                    email: action.payload.employeeData.email,
                    phoneNumber: action.payload.employeeData.phoneNumber,
                    employeeId: action.payload.employeeData.employeeId,
                    // bankName: action.payload.employeeData.bankName,
                    // bankAccountNumber: action.payload.employeeData.bankAccountNumber,
                    message: action.payload.message
                    // earningPercent: action.payload.employeeData.earningPercent,
                    // cutOffDate: action.payload.employeeData.cutOffDate,
                },
            }
            return state;


        case actions.SET_LOGIN_PIN_START:
            state = {
                ...state,
                signingUp: true,
                signUpPinCreated: false,
                // loading: true
            }
            return state;


        case actions.SET_LOGIN_PIN_FAILED:
            state = {
                ...state,
                signingUp: true,
                signUpPinCreated: false,
                loading: false
            }
            return state;

        case actions.SIGNUP_COMPLETE:
            state = {
                ...state,
                loggedIn: true,
                signingUp: false,
                signUpPhoneNumberVerfied: false,
                signUpPinCreated: false,
                signUpUser: {},
            }
            return state;


        case actions.SOFT_LOGOUT:
            state = {
                ...state,
                loggedIn: false,
            }
            return state;


        default:
            return state;
    }
}