import moment from "moment";

export const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
]

export const monthNamesShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"
]


export const MonthName = (month) => {
    const monthIndex = month - 1;   // -1 due to array indexing starting at 0
    // console.log("month Name format", month);
    // console.log("monthNames[monthIndex]", monthNames[monthIndex]);
    // console.log("");
    return (monthNames[monthIndex]);
}


export const DateTimeFormat = (datetime) => {

    if ((typeof datetime !== undefined) && (datetime !== undefined) && (datetime !== null)) {

        let d = new Date(datetime),
            month = "" + d.getMonth(),
            day = "" + d.getDate(),
            year = "" + d.getFullYear(),
            hour = "" + d.getHours(),
            hour12 = 0,
            am_pm = "",
            min = "" + d.getMinutes(),
            sec = "" + d.getSeconds();

        if (day <= 9)
            day = '0' + day;

        if (hour > 12) {
            hour12 = (hour - 12);
            am_pm = "PM";
        }
        else {
            hour12 = hour;
            am_pm = "AM";
        }

        if (min.length < 2)
            min = '0' + min;

        if (sec.length < 2)
            sec = '0' + sec;

        // return [year, month, day].join('-');
        return [monthNames[month] + " " + day + ", " + year + " at " + hour12 + ":" + min + " " + am_pm];

    }
};


export const DateTimeMiniFormat = (datetime) => {

    if ((typeof datetime !== undefined) && (datetime !== undefined) && (datetime !== null)) {

        let d = new Date(datetime),
            month = "" + d.getMonth(),
            day = "" + d.getDate(),
            // year = "" + d.getFullYear(),
            hour = "" + d.getHours(),
            hour12 = 0,
            am_pm = "",
            min = "" + d.getMinutes(),
            sec = "" + d.getSeconds();

        if (day <= 9)
            day = '0' + day;

        if (hour > 12) {
            hour12 = (hour - 12);
            am_pm = "PM";
        }
        else {
            hour12 = hour;
            am_pm = "AM";
        }

        if (min.length < 2)
            min = '0' + min;

        if (sec.length < 2)
            sec = '0' + sec;

        // return [year, month, day].join('-');
        return [day + " " + monthNamesShort[month] + ". " + hour12 + ":" + min + " " + am_pm];

    }
};


export const DateFormat = (date) => {
    if ((typeof date !== undefined) && (date !== undefined) && (date !== null)) {

        let d = new Date(date),
            month = '' + d.getMonth(),
            day = '' + d.getDate(),
            year = d.getFullYear();

        // if (month.length < 2) 
        // month = '0' + month;

        if (day <= 9)
            day = '0' + day;

        // return [year, month, day].join('-');
        return [monthNames[month] + " " + day + ", " + year];

    }
};

export const DateFormat2 = (date) => {
    if ((typeof date !== undefined) && (date !== undefined) && (date !== null)) {

        let d = new Date(date),
            month = '' + d.getMonth(),
            day = '' + d.getDate()
            // year = d.getFullYear();

        // if (month.length < 2) 
        // month = '0' + month;

        if (day <= 9)
            day = '0' + day;

        // return [year, month, day].join('-');
        return [monthNames[month] + " " + day ];

    }
};

export const DateFormatTable = (date) => {
    if ((typeof date !== undefined) && (date !== undefined) && (date !== null)) {

        let d = new Date(date),
            month = '' + d.getMonth(),
            day = '' + d.getDate()
            // year = d.getFullYear();

        // if (month.length < 2) 
        // month = '0' + month;

        if (day <= 9)
            day = '0' + day;

        // return [year, month, day].join('-');
        // return [monthNames[month] + " " + day ];
        return [day + " " + monthNamesShort[month]];

    }
};

export const TimeFormat = (time) => {

    if ((typeof time !== undefined) && (time !== undefined) && (time !== null)) {

        let d = new Date(time),
            hour = "" + d.getHours(),
            hour12 = 0,
            am_pm = "",
            min = "" + d.getMinutes(),
            sec = "" + d.getSeconds();

        if (hour > 12) {
            hour12 = (hour - 12);
            am_pm = "PM";
        }
        else {
            hour12 = hour;
            am_pm = "AM";
        }

        if (min.length < 2)
            min = '0' + min;

        if (sec.length < 2)
            sec = '0' + sec;

        // return [year, month, day].join('-');
        return [hour12 + ":" + min + " " + am_pm];

    }

};

export const DateFormatDMY = (date) => {
    if ((typeof date !== undefined) && (date !== undefined) && (date !== null)) {

        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;

        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('-');
    }
};

export function DateDisplay(date) {
    let readable;
    const today = moment().format("DD-MM-YYYY");
    const yesterday = moment().subtract('days', 1).format("DD-MM-YYYY");
    const valueDate = DateFormatDMY(date)

    if (valueDate === today) {
        readable = 'Today';
    }
    else if (valueDate === yesterday) {
        readable = 'Yesterday';
    }
    else {
        readable = DateFormat(date).join("");
    }

    return readable;
}

export const MonthDisplay = () => {
    let monthName = ''
    let newDate = new Date();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    month === 1 ? monthName = 'January' : month === 2 ? monthName = 'February' : month === 3 ? monthName = 'March' : month === 4
        ? monthName = 'April' : month === 5 ? monthName = 'May' : month === 6 ? monthName = 'June' : month === 7
            ? monthName = 'July' : month === 8 ? monthName = 'August' : month === 9 ? monthName = 'September' : month === 10
                ? monthName = 'October' : month === 11 ? monthName = 'November' : month === 12 ? monthName = 'December' : monthName = '';
    return `${monthName} ${year}`;
}

export const MaxDaysInAMonth = () => {
    let newDate = new Date();
    const monthOf30Days = [4, 6, 9, 11];
    const feb = 2;
    let maxDay;
    let month = newDate.getMonth() + 1;
    if (monthOf30Days.includes(month)) {
        maxDay = 30;
    } else if (month === feb) {
        maxDay = 28;
    } else {
        maxDay = 31;
    }

    return maxDay;
}


export default DateFormat;