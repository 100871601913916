import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from "assets/images/newDesignAssets/svgs/logo.svg";
// import { StepLabel, } from './Step';
import { ProgressTracker, Steps } from 'utils/ProgressTracker';


class GetStarted2 extends Component {
    state = { 
        
    }

    componentDidMount() {
        if (this.props.signingUp === true){
            if (this.props.signUpPinCreated !== true){
                this.props.history.push("/get-started/create-pin");
            }
        }
        else{
            this.props.history.push("/get-started");
        }
    }

    render() { 
        
        const details = this.props.data;
        const currentStage = 2;

        return (
            <div className="w-full p-8 sm:p-14 md:my-auto">
                <div className="pb-8 lg:pl-4 lg:pr-4">
                    <div className=" my-8 h-8 sm:h-10 flex justify-center animate hover:scale-110 ">
                        <img src={logo} alt="EarniPay logo" className="h-full object-contain" />
                    </div>
                 
                    <div className="max-w-lg mx-auto pb-10">
                        <ProgressTracker current={currentStage} />
                        <div className='py-2'>
                            <Steps current={currentStage} />
                        </div>
                    </div>
                    <div>
                        <div className='max-w-lg mx-auto bg-white'>
                            <div className='pl-10 pt-10'>
                                <div className="font-recoleta font-semibold text-2xl sm:text-3xl text-center md:text-left">
                                    Personal Information
                                </div>
                                <div className="mt-2">
                                    Review your personal details
                                </div>
                           </div>

                            <div className="mt-2 w-full p-10 mx-auto">

                                <div className="form-group">
                                    <label>
                                        Full Name
                                        <span className="form-input-required">*</span>
                                    </label>
                                    <input type="text" className="form-input " value={details.name} readOnly disabled />
                                </div>

                                <div className="form-group">
                                    <label>
                                        Email Address
                                        <span className="form-input-required">*</span>
                                    </label>
                                    <input type="tel" name="phoneNumber" className="form-input" value={details.email} readOnly disabled />
                                </div>

                                <div className="form-group">
                                    <label>
                                        Phone Number
                                        <span className="form-input-required">*</span>
                                    </label>
                                    <input type="tel" name="phoneNumber" className="form-input" value={details.phoneNumber} readOnly disabled />
                                </div>

                                <div className="form-group">
                                    <label>
                                        Employee ID
                                        <span className="form-input-required">*</span>
                                    </label>
                                    <input type="tel" name="phoneNumber" className="form-input" value={details.employeeId} readOnly disabled />
                                </div>

                                <Link to="/get-started/bank-details" className="btn btn-lg btn-block btn-ep-blue mt-6">
                                    Continue
                                    <FontAwesomeIcon icon="angle-right" className="text-xl ml-2 inline" />
                                </Link>


                            </div>

                        </div>
                    </div>

                </div>
            </div>
        )
    }
}



const mapStateToProps = (state) => ({
    signingUp: state.auth.userPersist.signingUp,
    data: state.auth.userPersist.data,
    signUpPinCreated: state.auth.userPersist.signUpPinCreated,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(GetStarted2);