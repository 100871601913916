import React from "react";
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { Dialog } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import introTwo from "assets/images/newDesignAssets/svgs/paymentonboarding2.svg";
import introOne from "assets/images/newDesignAssets/svgs/paymentOnboardingOne.svg";
import flexiblePayment from "assets/images/newDesignAssets/svgs/FlexiblePayment.svg";
import logo from "assets/images/newDesignAssets/svgs/logo.svg";
import dangerIcon from "assets/images/newDesignAssets/svgs/DangerIcon.svg";
import animateSuccess from "assets/images/newDesignAssets/images/successfully-animation.gif";
import DateFormat, { TimeFormat } from "utils/dateFormat";

import { validateAmountScheduleAutopay } from "utils/validateAmountSchedule";
import { CurrencyFormat } from "utils/currencyFormat";
import dayObject from "utils/daysFormat";

import logger from "utils/logger";

export const FirstSlide = ({
	current,
	completeButtonRef,
	handleSetCurrent,
	onboardToAutoPay,
}) => (
	<div
		className="pt-7 animate border border-new-ep-blue"
		current={current}
		ref={completeButtonRef}>
		<div className="h-48 my-5 w-4/5 mx-auto">
			<img
				src={introOne}
				alt="illustration"
				className="h-full w-full object-contain"
			/>
		</div>
		<div className="pb-6 pt-2">
			<div className="mt-4 py-2 text-center text-2xl lg:text-2xl font-semibold font-recoleta text-white">
				Control your <br /> finances
			</div>
			<div className="text-center sm:text-xs md:text-sm lg:text-sm sm:px-12 md:px-10 lg:px-14 font-sans text-gray-100">
				Flexible payments are payments that are scheduled for a specific date
				prior to the bill due date.
			</div>
		</div>
		<div className="flex justify-between space-x-2 mt-6 w-1/4 mx-auto ">
			<div
				className={`h-1 w-20 rounded-lg cursor-pointer pointer-light-blue `}
				onClick={() => handleSetCurrent(2)}></div>
			<div
				className={`h-1 w-10 rounded-lg cursor-pointer bg-gray-400 `}
				onClick={() => handleSetCurrent(1)}></div>
		</div>
		<div className="pt-8 pb-10 px-4 w-5/6 mx-auto flex justify-between align-center space-x-4 ">
			<button
				className=" btn btn-block border cursor-pointer text-gray-300 font-sans"
				onClick={() => onboardToAutoPay()}>
				Skip
			</button>

			<button
				className={`btn btn-block border space-x-4 text-center bg-gray-200 text-blue-600 font-sans  `}
				onClick={() => handleSetCurrent(2)}>
				Next
			</button>
		</div>
	</div>
);

export const SecondSlide = ({
	current,
	completeButtonRef,
	handleSetCurrent,
	onboardToAutoPay,
}) => {
	const user = useSelector((s) => s.auth.userPersist.data);

	return (
		<div className="pt-7 animate" current={current} ref={completeButtonRef}>
			<div className="h-48 my-5 w-4/5 mx-auto">
				<img
					src={introTwo}
					alt="illustration"
					className="h-full w-full object-contain"
				/>
			</div>
			<div className="pb-6 pt-2">
				<div className="mt-4 py-2 text-center text-2xl lg:text-2xl font-semibold font-recoleta text-white">
					Get paid daily or weekly, <br /> the choice is yours
				</div>
				<div className="text-center sm:text-xs md:text-sm lg:text-sm sm:px-12 md:px-10 lg:px-14 font-sans text-gray-100">
					The date of a flexible payment can be changed as long as it is adjusted
					before the date scheduled.
				</div>
			</div>
			<div className="flex justify-between space-x-2 mt-6 w-1/4 mx-auto ">
				<div
					className={`h-1 w-10 rounded-lg cursor-pointer bg-gray-400 `}
					onClick={() => handleSetCurrent(1)}></div>
				<div
					className={`h-1 w-20 rounded-lg cursor-pointer pointer-light-blue `}
					onClick={() => handleSetCurrent(1)}></div>
			</div>
			<div className="pt-8 pb-10 px-4 w-5/6 mx-auto flex justify-between align-center space-x-4 ">
				<button
					className=" btn2 border cursor-pointer text-gray-300 font-sans"
					onClick={() => onboardToAutoPay()}>
					Skip
				</button>

				<button
					className={`btn2 btn-block border text-center bg-gray-200 text-blue-600 font-sans  `}
					onClick={() => {
						handleSetCurrent(3);
						logger("employeeweb-flexi-get-paid", user);
					}}>
					Get paid today
				</button>
			</div>
		</div>
	)
}

export const ThirdSlide = ({
	current,
	handleTogglePayment,
	completeButtonRef,
	handleSetCurrent,
	memorizedDetails,
}) => {
	const user = useSelector((s) => s.auth.userPersist.data);

	return (
		<div className=" px-6 animate" current={current}>
			<div className="modal-header">
				<div className="modal-btn invisible"></div>
				<div className="flex justify-between px-6">
					<div className="modal-btn btn-close" ref={completeButtonRef}>
						<FontAwesomeIcon icon="angle-left" className="text-gray-500 m-auto" />
					</div>
					<div className="font-recoleta text-base">Payments</div>
					<div
						className="modal-btn btn-close cursor-pointer"
						onClick={() => handleTogglePayment(false)}>
						<FontAwesomeIcon icon="times" className=" m-auto text-gray-500 " />
					</div>
				</div>
				<div className="pt-6">
					<div
						className="text-center rounded py-2"
						style={{ backgroundColor: "#D9E3FF" }}>
						<div className="pt-4 pb-2 font-sans text-sm">You’ve earned</div>
						<div className="pb-4 font-recoleta text-xl font-semibold">
							{CurrencyFormat(memorizedDetails?.totalAccured)}{" "}
						</div>
					</div>
					<div className="mx-auto mt-8 py-3 rounded-md border border-gray-300">
						<div className=" w-56 mx-auto h-40 my-3">
							<img
								src={flexiblePayment}
								alt="schedule payment"
								className="h-full w-full object-cover"
							/>
						</div>
						<div className=" text-center text-base">
							You don’t have a <br />
							flexible payment yet
						</div>
						<div
							className="mt-4 p-4 cursor-pointer"
							onClick={() => {
								handleSetCurrent(4);
								logger("employeeweb-flexi-get-paid", user);
							}}>
							<button className="btn btn-block payment-blue-bg text-white">
								{" "}
								<FontAwesomeIcon icon="plus" className="mr-3" /> get paid today{" "}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export const FourthSlide = ({
	memorizedDetails,
	current,
	handleTogglePayment,
	handleSetCurrent,
	handleChange,
	autoWithdrawalForm,
	btnBgColor,
	displayWithdrawalFees,
}) => {
	const user = useSelector((s) => s.auth.userPersist.data);

	const inputColorSetter = (label) => (label !== "" ? "" : "#9CA3AF");
	const withdrawalPercent = memorizedDetails.hasOwnProperty("companyInfo")
		? memorizedDetails.companyInfo[0].withdrawalPercent
		: null;
	const { frequency } = autoWithdrawalForm;
	const userCanWithdraw = (frequency = "daily") =>
		frequency === "daily" || frequency === ""
			? memorizedDetails.netPay / 60
			: (memorizedDetails.netPay / 60) * 7;
	const allowWithdrawal = userCanWithdraw(frequency);
	const logScheduleAttempt = () => {
		if (frequency === "weekly") {
			logger("employeeweb-flexi-weekly-schedule-attempt", user);
		}
		if (frequency === "daily") {
			logger("employeeweb-flexi-daily-schedule-attempt", user);
		}
	};

	return (
		<div className=" px-6 animate" current={current}>
			<div className="modal-header">
				<div className="modal-btn invisible"></div>
				<div className="flex justify-between px-6">
					<div
						className="modal-btn btn-close cursor-pointer"
						onClick={() => handleSetCurrent(3)}>
						<FontAwesomeIcon
							icon="angle-left"
							className="text-gray-500 m-auto"
						/>
					</div>
					<div className="font-recoleta text-base">Get Paid</div>
					<div
						className="modal-btn btn-close cursor-pointer"
						onClick={handleTogglePayment}>
						<FontAwesomeIcon icon="times" className=" m-auto text-gray-500 " />
					</div>
				</div>
				<div className="pt-6">
					<form className="px-7 ">
						<div className="mt-8 mb-6 pb-6">
							<div className="form-group ">
								<label htmlFor="withdrawal" className="text-sm">
									Frequency
								</label>

								<select
									name="frequency"
									className="form-input"
									placeholder="Select Frequency"
									style={{
										color: `${inputColorSetter(autoWithdrawalForm.frequency)}`,
									}}
									value={autoWithdrawalForm.frequency}
									onChange={handleChange}>
									<option value="">Select Frequency</option>
									<option value="daily">Daily</option>
									{/* <option value="2-days">Every two days</option> */}
									<option value="weekly">Weekly</option>
									{/* <option value="custom">Custom</option> */}
								</select>

								{autoWithdrawalForm.frequency !== "" && (
									<FontAwesomeIcon
										icon="check"
										className=" text-new-ep-blue text-sm float-right relative bottom-8 right-3"
									/>
								)}
								{autoWithdrawalForm.frequency === "daily" && (
									<div className=" text-sm text-gray-400">
										You'll get paid daily
									</div>
								)}
								{autoWithdrawalForm.frequency === "2-days" && (
									<div className=" text-sm text-gray-400">
										You'll get paid every two days
									</div>
								)}
							</div>
							{autoWithdrawalForm.frequency === "weekly" && (
								<>
									<div className="form-group ">
										<label htmlFor="withdrawal" className="text-sm">
											Day of the week
										</label>

										<select
											name="weekday"
											className="form-input"
											placeholder="Select Frequency"
											style={{
												color: `${inputColorSetter(
													autoWithdrawalForm.weekday
												)}`,
											}}
											value={autoWithdrawalForm.weekday}
											onChange={handleChange}>
											<option value="">Select day of the week</option>

											{dayObject.map((day, index) => (
												<option value={day.name} key={index}>
													{day.name}
												</option>
											))}
										</select>
										{autoWithdrawalForm.weekday !== "" && (
											<FontAwesomeIcon
												icon="check"
												className=" text-new-ep-blue text-sm float-right relative bottom-8 right-3"
											/>
										)}
									</div>

									{autoWithdrawalForm.weekday && (
										<div className=" text-sm text-gray-400">
											You'll get paid every {autoWithdrawalForm.weekday} of the
											week
										</div>
									)}
								</>
							)}
							{autoWithdrawalForm.frequency === "custom" && (
								<div className="mx-auto mt-8 py-3 px-3 rounded-md border border-gray-400">
									<div className="sm:grid grid-cols-2 gap-4">
										<div className="form-group ">
											<label htmlFor="start date" className="text-sm">
												Start Date
											</label>
											<input
												name="startDate"
												className="form-input-day"
												placeholder="Select Date"
												style={{
													color: `${inputColorSetter(
														autoWithdrawalForm.startDate
													)}`,
												}}
												value={autoWithdrawalForm.startDate}
												onChange={handleChange}
												type="date"
											/>
										</div>
										<div className="form-group ">
											<label htmlFor="End date" className="text-sm">
												End Date
											</label>

											<input
												name="endDate"
												className="form-input-day"
												placeholder="e.g N2,000"
												style={{
													color: `${inputColorSetter(
														autoWithdrawalForm.endDate
													)}`,
												}}
												value={autoWithdrawalForm.endDate}
												onChange={handleChange}
												type="date"
											/>
										</div>
									</div>

									<div className="form-group ">
										<label htmlFor="frequency-custom" className="text-sm">
											Repeat Every
										</label>
										<div className="sm:grid grid-cols-3 gap-2">
											<select
												name="repeatIntervalDay"
												className="form-input-day"
												placeholder="Select Frequency"
												style={{
													color: `${inputColorSetter(
														autoWithdrawalForm.repeatIntervalDay
													)}`,
												}}
												value={autoWithdrawalForm.repeatIntervalDay}
												onChange={handleChange}>
												{autoWithdrawalForm.repeatIntervalWeek === "weeks" && (
													<>
														<option value="1">1</option>
														<option value="2">2</option>
														<option value="3">3</option>
													</>
												)}
												{autoWithdrawalForm.repeatIntervalWeek === "days" && (
													<>
														<option value="1">1</option>
														<option value="2">2</option>
														<option value="3">3</option>
														<option value="4">4</option>
														<option value="5">5</option>
														<option value="6">6</option>
													</>
												)}
											</select>
											<select
												name="repeatIntervalWeek"
												className="form-input-day"
												placeholder="Select Frequency"
												style={{
													color: `${inputColorSetter(
														autoWithdrawalForm.repeatIntervalWeek
													)}`,
												}}
												value={autoWithdrawalForm.repeatIntervalWeek}
												onChange={handleChange}>
												<option value=""></option>
												<option value="days">Days</option>
												<option value="weeks">Weeks</option>
											</select>
										</div>
										{
											<div className=" text-sm text-gray-400">
												You'll get paid starting from{" "}
												{autoWithdrawalForm.startDate} every{" "}
												{`${autoWithdrawalForm.repeatIntervalDay} ${autoWithdrawalForm.repeatIntervalWeek}`}{" "}
												till {autoWithdrawalForm.endDate}
											</div>
										}
									</div>
								</div>
							)}
							<div className="p-5 border border-gray-300 rounded-md">
								<div className="form-group ">
									<label htmlFor="Amount" className="text-sm">
										Maximum Amount:{" "}
										<span className=" text-new-ep-blue">
											{CurrencyFormat(allowWithdrawal)}
										</span>
									</label>

									<input
										name="amount"
										className="form-input"
										placeholder="Input amount to get paid"
										style={{
											color: `${inputColorSetter(autoWithdrawalForm.amount)}`,
										}}
										value={autoWithdrawalForm.amount}
										onChange={handleChange}
										onKeyUp={() => displayWithdrawalFees()}
									/>
								</div>
								<div className="my-4">
									<div className="form-group ">
										<label
											htmlFor="withdrawal"
											className="text-sm pb-2"
											style={{ color: "#718096" }}>
											Or quick choice
										</label>
										<div className=" flex justify-between ">
											<button
												type="button"
												className="form-input-round"
												onClick={(e) => {
													e.preventDefault();
													autoWithdrawalForm.amount = 500;
													displayWithdrawalFees();
												}}>
												{" "}
												500
											</button>
											<button
												type="button"
												className="form-input-round"
												onClick={(e) => {
													e.preventDefault();
													autoWithdrawalForm.amount = Math.floor(
														allowWithdrawal / 2
													);
													displayWithdrawalFees();
												}}>
												{" "}
												{Math.floor(allowWithdrawal / 2)}
											</button>

											<button
												type="button"
												className="form-input-round"
												onClick={(e) => {
													e.preventDefault();
													autoWithdrawalForm.amount =
														Math.floor(allowWithdrawal);
													displayWithdrawalFees();
												}}>
												{" "}
												{Math.floor(allowWithdrawal)}
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className=" pt-12 flex justify-between space-x-2 mt-8 py-3  ">
							<button
								className=" btn w-2/4 btn-transparent cursor-pointer font-sans"
								onClick={() => handleTogglePayment(false)}>
								Cancel
							</button>

							<button
								className={`btn btn-lg lg:w-1/2 border text-center font-sans bg-payment-ep-blue text-white ${btnBgColor}`}
								type="button"
								onClick={() => {
									if (
										!validateAmountScheduleAutopay(
											autoWithdrawalForm.amount,
											memorizedDetails.netPay,
											autoWithdrawalForm.frequency,
											withdrawalPercent,
											allowWithdrawal
										)
									) {
										return null;
									} else {
										handleSetCurrent(5);
										logScheduleAttempt();
									}
								}}>
								Continue
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export const FifthSlide = ({
	current,
	handleTogglePayment,
	setPayementDueDate,
	paymentDueDate,
	handleSetCurrent,
	autoWithdrawalForm,
	withdrawalFee,
	paymentFeeBy,
	autopayFeeLoading,
	autopayFee,
}) => {
	const { amount, frequency, weekday } = autoWithdrawalForm;
	const dayIndex =
		weekday === "Monday"
			? 1
			: weekday === "Tuesday"
			? 2
			: weekday === "Wednesday"
			? 3
			: weekday === "Thursday"
			? 4
			: weekday === "Friday"
			? 5
			: weekday === "Saturday"
			? 6
			: weekday === "Sunday"
			? 7
			: null;
	if (dayIndex !== null) {
		setPayementDueDate(dayIndex);
	}
	const paydayIsTodayOrTomorrow =
		new Date().getHours() < 17 ? "today" : "tomorrow";

	const halfFee = withdrawalFee.fee * 0.5;
	const transactionCharge =
		paymentFeeBy === "shared"
			? halfFee
			: paymentFeeBy === "employer"
			? 0
			: withdrawalFee.fee;
	return (
		<div className=" px-6 animate" current={current}>
			<div className="modal-header">
				<div className="modal-btn invisible"></div>
				<div className="flex justify-between">
					<div
						className="modal-btn btn-close cursor-pointer"
						onClick={() => handleSetCurrent(4)}>
						<FontAwesomeIcon
							icon="angle-left"
							className="text-gray-500 m-auto"
						/>
					</div>
					<div className="font-recoleta text-base">Payment Preview</div>
					<div
						className="modal-btn btn-close cursor-pointer"
						onClick={() => handleTogglePayment(false)}>
						<FontAwesomeIcon icon="times" className=" m-auto text-gray-500 " />
					</div>
				</div>

				<div className="pt-6 px-5">
					<div className="py-3 text-gray-700 text-base">
						Please review the details below carefully before confirming the
						transfer:
					</div>
					<div className="mx-auto">
						<div className=" my-4 font-sans ">
							<div className="text-sm ep-card-blue flex justify-between my-2 bg-flex-card-summary">
								<div>
									<div className="py-1 bg-transaction-card-text">Amount</div>
									<div className="py-1 bg-transaction-card-text">
										Transaction Fee
									</div>
								</div>
								<div className=" pl-4">
									<div className="font-medium flex-custom-num-color py-1 capitalize">
										{CurrencyFormat(amount)}{" "}
									</div>
									{/* <div className='font-medium text-gray-400 py-1'>{CurrencyFormat(transactionCharge)} per payment</div> */}
									{autopayFeeLoading && (
										<FontAwesomeIcon
											icon="spinner"
											spin
											className="text-xl mr-2"
										/>
									)}
									{!autopayFeeLoading && (
										<div style={{ color: "#A18A08" }} className="block">
											{CurrencyFormat(autopayFee.estimatedFee)} per payment
										</div>
									)}
								</div>
							</div>

							<div className=" my-2">
								{autopayFeeLoading && (
									<FontAwesomeIcon
										icon="spinner"
										spin
										className="text-xl mr-2"
									/>
								)}
								{!autopayFeeLoading &&
									autopayFee &&
									autopayFee.estimatedFee > 0 &&
									transactionCharge - autopayFee.estimatedFee > 0 && (
										<div className="bg-transaction-flex rounded-md py-2 text-sm border border-new-ep-blue">
											<div className="px-3 py-1">
												<div
													className="pl-1 pr-2 text-[#1F2937]"
													style={{ color: "#1F2937" }}>
													You have saved{" "}
													{CurrencyFormat(
														transactionCharge - autopayFee.estimatedFee
													)}{" "}
													transaction charge {autoWithdrawalForm.frequency} by
													using flexx
												</div>
											</div>
										</div>
									)}
							</div>
							<div className=" text-sm font-medium ep-card-blue flex justify-between bg-flex-card-summary">
								<div>
									<div className="py-1 bg-transaction-card-text">
										You will recieve
									</div>
								</div>

								<div className="py-1 font-semibold w-28 pl-2">
									{autopayFeeLoading && (
										<FontAwesomeIcon
											icon="spinner"
											spin
											className="text-xl mr-2"
										/>
									)}
									{!autopayFeeLoading && autopayFee && (
										<div className="py-1 font-semibold flex-custom-num-color ">
											{" "}
											{CurrencyFormat(amount - autopayFee?.estimatedFee)}{" "}
										</div>
									)}
								</div>
							</div>
							<div className="text-sm ep-card-blue flex justify-between my-2 bg-flex-card-summary">
								<div>
									<div className="py-1 bg-transaction-card-text">Frequency</div>
								</div>
								<div className=" pl-4">
									<div className="font-medium flex-custom-num-color py-1 capitalize">
										<span>{frequency}</span>
										{frequency === "weekly" && <span> {`(${weekday})`}</span>}
									</div>
								</div>
							</div>
							<div className="my-2">
								<div className="bg-flex-start-date rounded-md py-2 text-sm">
									<div className="px-3 py-1">
										<div className="pl-4 pr-2">
											Your flex payment starts{" "}
											{frequency === "daily"
												? paydayIsTodayOrTomorrow
												: `on ${paymentDueDate}`}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="  mx-auto pt-6 flex justify-between space-x-2 mt-12 py-3  ">
						<button
							className=" btn btn-transparent cursor-pointer font-sans"
							onClick={() => handleTogglePayment(false)}>
							Cancel
						</button>

						<button
							className={`btn btn-lg lg:w-1/2 border text-center font-sans bg-payment-ep-blue text-white`}
							onClick={() => {
								handleSetCurrent(6);
							}}>
							Save
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

const ValidateSchedule = ({
	memorizedSchedules,
	deleteSchedulePayment,
	handleScheduleUpdate,
	handleSetCurrent,
	submitForm,
	formChange,
	passwordForm,
	addBgCol,
	bgCol,
	loadingCreateWithdrawal,
	loadingUpdate,
	validateAction,
	loadingDelete,
}) => {
	return (
		<div className="w-full p-8 sm:p-14 md:my-auto">
			<div className="pb-14 lg:pl-4 lg:pr-4">
				<div className="font-recoleta font-semibold text-2xl sm:text-3xl text-center">
					<Link
						to="/login"
						className=" my-8 h-8 sm:h-10 flex justify-center animate hover:scale-110 ">
						<img
							src={logo}
							alt="EarniPay logo"
							className="h-full object-contain"
						/>
					</Link>
				</div>
				<div>
					<div className="mt-10 w-full max-w-lg mx-auto">
						<div className="bg-white w-full py-5">
							<div className=" mb-4 mx-auto flex justify-between w/3/5">
								<FontAwesomeIcon
									icon="angle-left"
									className="text-gray-500 my-auto"
									onClick={() =>
										validateAction === "delete"
											? handleSetCurrent(11)
											: handleSetCurrent(5)
									}
								/>
								<div className="font-recoleta font-semibold py-2 text-lg lg:text-2xl ">
									Enter Access Pin
								</div>
								{/* <FontAwesomeIcon icon="angle-right" className="text-gray-500 my-auto" /> */}
								<div></div>
							</div>
							<form className=" mx-auto">
								<div className="form-group">
									<div className="flex justify-between flex-wrap text-white">
										<input
											type="password"
											name="newpin1"
											className="form-input text-center bg-new-ep-grey"
											id="pin-input1"
											maxLength={1}
											value={passwordForm.newpin1}
											onChange={formChange}
											style={{
												backgroundColor: `${bgCol.box1}`,
												color: "#D1D5DB",
											}}
											onKeyUp={() => addBgCol()}
										/>
										<input
											type="password"
											name="newpin2"
											className="form-input text-center bg-new-ep-grey"
											id="pin-input2"
											maxLength={1}
											value={passwordForm.newpin2}
											onChange={formChange}
											style={{
												backgroundColor: `${bgCol.box2}`,
												color: "#D1D5DB",
											}}
											onKeyUp={() => addBgCol()}
										/>
										<input
											type="password"
											name="newpin3"
											className="form-input text-center bg-new-ep-grey"
											id="pin-input3"
											maxLength={1}
											value={passwordForm.newpin3}
											onChange={formChange}
											style={{
												backgroundColor: `${bgCol.box3}`,
												color: "#D1D5DB",
											}}
											onKeyUp={() => addBgCol()}
										/>
										<input
											type="password"
											name="newpin4"
											className="form-input text-center bg-new-ep-grey"
											id="pin-input4"
											maxLength={1}
											value={passwordForm.newpin4}
											onChange={formChange}
											style={{
												backgroundColor: `${bgCol.box4}`,
												color: "#D1D5DB",
											}}
											onKeyUp={() => addBgCol()}
										/>
									</div>
								</div>

								{/* <button type="button" className="btn btn-lg btn-block bg-payment-ep-blue text-white mt-8" onClick={(action) => {
                                    memorizedSchedules?.length > 0 ? handleScheduleUpdate() : submitForm()
                                }} */}
								<button
									type="button"
									className="btn btn-lg btn-block bg-payment-ep-blue text-white mt-8"
									onClick={() => {
										validateAction === "delete"
											? deleteSchedulePayment(memorizedSchedules[0]._id)
											: validateAction === "update"
											? handleScheduleUpdate()
											: submitForm();
									}}
									disabled={
										loadingCreateWithdrawal || loadingUpdate || loadingDelete
									}>
									<FontAwesomeIcon
										icon="spinner"
										spin
										className={
											"text-xl mr-2 " +
											(loadingCreateWithdrawal || loadingUpdate
												? "inline"
												: "hidden")
										}
									/>
									Continue
								</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const SeventhSlide = ({ completeButtonRef }) => {
	return (
		<div className="w-full md:my-auto">
			<div className="pb-14 md:pt-20 lg:pl-4 lg:pr-4">
				<div
					className="mt-4 text-center max-w-md mx-auto"
					ref={completeButtonRef}>
					<div className="h-48 sm:h-56">
						<img
							src={animateSuccess}
							alt="EarniPay"
							className="h-full object-contain mx-auto"
						/>
					</div>
					<div className="mt-8 font-recoleta font-semibold text-2xl sm:text-3xl">
						Saved
					</div>
					<div className="mt-2 text-gray-500">
						Payment Schedule saved <br /> Successfully
					</div>

					{/* <Link to="/dashboard" className="btn btn-lg btn-block btn-ep-blue mt-8">Go To Dashboard</Link> */}
				</div>
			</div>
		</div>
	);
};

export const SchedulSummary = ({
	current,
	handleTogglePayment,
	handleSetCurrent,
	memorizedDetails,
	memorizedWithdrawals,
	memorizedSchedules,
	getScheduleById,
	loadingGetSchedule,
	handleDaySelectLog,
	cancelAutopaySchedule,
}) => {
	const user = useSelector((s) => s.auth.userPersist.data);
	
	const lastWitdrawal = memorizedWithdrawals[0] || {};
	//    const lastWitdrawal = memorizedWithdrawals.length > 1 ? memorizedWithdrawals[memorizedWithdrawals.length - 1] : memorizedWithdrawals[0] || {}
	const lastSchedule = memorizedSchedules[0];
	// const lastSchedule = memorizedSchedules.length > 1 ? memorizedSchedules[memorizedSchedules.length - 1] : memorizedSchedules[0];

	handleDaySelectLog(lastSchedule.duration);
	return (
		<div className=" px-6 animate" current={current}>
			<div className="modal-header">
				<div className="modal-btn invisible"></div>
				<div className="flex justify-between">
					<div
						className="modal-btn btn-close cursor-pointer"
						onClick={() => handleSetCurrent(6)}>
						<FontAwesomeIcon
							icon="angle-left"
							className="text-gray-500 m-auto"
						/>
					</div>
					<div className="font-recoleta text-base">Payments</div>
					<div
						className="modal-btn btn-close cursor-pointer"
						onClick={() => handleTogglePayment(false)}>
						<FontAwesomeIcon icon="times" className=" m-auto text-gray-500 " />
					</div>
				</div>
				<div className="pt-6">
					<div className="text-center rounded py-2 bg-ep-blue bg-opacity-30">
						<div
							className="pt-4 pb-2 font-sans text-sm"
							style={{ color: "#05083F" }}>
							You’ve earned
						</div>
						<div className="pb-4 font-recoleta text-xl font-semibold">
							{CurrencyFormat(memorizedDetails.totalAccured)}{" "}
						</div>
						<div className=" leading-7 text-sm" style={{ color: "#718096" }}>
							<div>Last Payment</div>
							<div>
								{" "}
								{CurrencyFormat(lastWitdrawal.amountRequested)} on{" "}
								{DateFormat(lastWitdrawal.createdAt)} at{" "}
								{TimeFormat(lastWitdrawal.createdAt)}{" "}
							</div>
						</div>
						<div className="py-8">
							<Link
								to="/withdrawals"
								className="text-payment-ep-blue text-base text-center">
								View payments
							</Link>
						</div>
					</div>
					<div className="mx-auto mt-8 py-6 ">
						<div className=" leading-7">
							{memorizedSchedules && memorizedSchedules.length > 0 && (
								<>
									<div
										className="text-lg font-recoleta"
										style={{ color: "#1F2937" }}>
										Flexible Payment
									</div>
									<div className=" text-sm pt-2" style={{ color: "#4A5564" }}>
										<span className="font-semibold">
											{CurrencyFormat(memorizedSchedules[0].amount)}
										</span>{" "}
										has been scheduled for payment
									</div>
									{
										<div className="text-sm" style={{ color: "#4A5564" }}>
											{memorizedSchedules[0].duration === "days"
												? "Daily"
												: "Weekly"}
											<span className="text-2xl font-semibold capitalize">
												.
											</span>{" "}
											{memorizedSchedules[0].duration === "weeks" &&
												memorizedSchedules[0].dayOfWeek
													.charAt(0)
													.toUpperCase() +
													memorizedSchedules[0].dayOfWeek.slice(1)}
										</div>
									}
								</>
							)}
						</div>
						<div className="mt-4 p-4 flex justify-between space-x-3 text-sm">
							<button
								className="btn btn-transparent text-red-500 border border-red-500 cursor-pointer"
								onClick={() => {
									cancelAutopaySchedule(memorizedSchedules[0]._id);
									handleSetCurrent(9);
									logger("employeeweb-flexi-cancel-schedule-attempt", user);
								}}>
								{" "}
								<FontAwesomeIcon icon="times" className="mr-3" /> Cancel Payment
							</button>
							<button
								className="btn payment-blue-bg text-white cursor-pointer"
								onClick={() => getScheduleById(memorizedSchedules[0]._id)}
								disabled={loadingGetSchedule}>
								{loadingGetSchedule ? (
									<FontAwesomeIcon
										icon="spinner"
										spin
										className="text-xl mr-3"
									/>
								) : (
									<FontAwesomeIcon icon="edit" className="text-xl mr-3" />
								)}
								Edit Payment
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export const EditAutoPay = ({
	clickedDate,
	setClickedDate,
	setValidateAction,
	memorizedDetails,
	setActive,
	current,
	handleTogglePayment,
	handleSetCurrent,
	handleChange,
	autoWithdrawalForm,
	btnBgColor,
	displayWithdrawalFees,
	secheduleGet,
	setAutoWithdrawalForm,
}) => {
	const inputColorSetter = (label) => (label !== "" ? "" : "#9CA3AF");
	const handleSetDayValue = (day, selDay) => {
		if (day === selDay) {
			setActive(true);
		}
	};
	const userCanWithdraw = (frequency = "daily") =>
		frequency === "daily" || frequency === ""
			? memorizedDetails.netPay / 60
			: (memorizedDetails.netPay / 60) * 7;
	const allowWithdrawal = userCanWithdraw(autoWithdrawalForm.frequency);

	return (
		<div className=" px-6 animate" current={current}>
			<div className="modal-header">
				<div className="modal-btn invisible"></div>
				<div className="flex justify-between px-6">
					<div
						className="modal-btn btn-close cursor-pointer"
						onClick={() => handleSetCurrent(8)}>
						<FontAwesomeIcon
							icon="angle-left"
							className="text-gray-500 m-auto"
						/>
					</div>
					<div className="font-recoleta text-base">Get Paid</div>
					<div
						className="modal-btn btn-close cursor-pointer"
						onClick={handleTogglePayment}>
						<FontAwesomeIcon icon="times" className=" m-auto text-gray-500 " />
					</div>
				</div>
				<div className="pt-6">
					<form className="px-7">
						<div className="mt-6 mb-6 pb-6">
							<div className="form-group ">
								<label htmlFor="withdrawal" className="text-sm">
									Frequency
								</label>
								<select
									name="frequency"
									className="form-input"
									placeholder="Select Frequency"
									style={{
										color: `${inputColorSetter(autoWithdrawalForm.frequency)}`,
									}}
									value={autoWithdrawalForm.frequency}
									onChange={handleChange}>
									<option value="frequency">Select Frequency</option>
									<option value="daily">Daily</option>
									{/* <option value="2-days">Every two days</option> */}
									<option value="weekly">Weekly</option>
									{/* <option value="custom">Custom</option> */}
								</select>
								{autoWithdrawalForm.weekday !== "" && (
									<FontAwesomeIcon
										icon="check"
										className=" text-new-ep-blue text-sm float-right relative bottom-8 right-3"
									/>
								)}

								{autoWithdrawalForm.frequency === "daily" && (
									<div className=" text-sm text-gray-400">
										You'll get paid Everyday
									</div>
								)}
							</div>
							{autoWithdrawalForm.frequency === "weekly" && (
								<>
									<div className="form-group hidden ">
										<label htmlFor="withdrawal" className="text-sm">
											Day of the week
										</label>

										<select
											name="weekday"
											className="form-input"
											placeholder="Select Frequency "
											style={{
												color: `${inputColorSetter(
													autoWithdrawalForm.weekday
												)}`,
											}}
											value={
												clickedDate !== ""
													? clickedDate
													: autoWithdrawalForm.weekday
											}
											onChange={handleChange}>
											<option value="choose day">Select day of the week</option>

											{dayObject.map((day, index) => (
												<option value={day.name.toLowerCase()} key={index}>
													{day.name}
												</option>
											))}
										</select>
									</div>
									<div className="selectedDay-container">
										{dayObject.map((day, index) => (
											<button
												type="button"
												className={` w-8 h-8 mx-1 ${
													autoWithdrawalForm.weekday === day.name.toLowerCase()
														? "selectedDay"
														: ""
												}`}
												key={index}
												onClick={(e) => {
													e.preventDefault();
													handleSetDayValue(
														autoWithdrawalForm.weekday,
														day.name.toLowerCase()
													);
													setClickedDate(day.name.toLowerCase());
													autoWithdrawalForm.weekday = day.name.toLowerCase();
												}}>
												{day.symbol}
											</button>
										))}
									</div>
									{autoWithdrawalForm.weekday && (
										<div className=" text-sm text-gray-400 pt-1">
											You'll get paid every {autoWithdrawalForm.weekday} of the
											week
										</div>
									)}
								</>
							)}
							<div className="p-5 mt-8 border border-gray-300 rounded-md">
								<div className="form-group ">
									<label htmlFor="Amount" className="text-sm">
										Maximum Amount:{" "}
										<span className=" text-new-ep-blue">
											{CurrencyFormat(Math.floor(allowWithdrawal))}
										</span>
									</label>

									<input
										name="amount"
										className="form-input"
										placeholder="Input amount to get paid"
										style={{
											color: `${inputColorSetter(autoWithdrawalForm.amount)}`,
										}}
										value={autoWithdrawalForm.amount}
										onChange={handleChange}
										onKeyUp={() => displayWithdrawalFees()}
									/>
								</div>
								<div className="my-2">
									<div className="form-group ">
										<label
											htmlFor="withdrawal"
											className="text-sm pb-2"
											style={{ color: "#718096" }}>
											Or quick choice
										</label>
										<div className=" flex justify-between ">
											<button
												type="button"
												className="form-input-round"
												onClick={(e) => {
													e.preventDefault();
													autoWithdrawalForm.amount = 500;
													displayWithdrawalFees();
												}}>
												{" "}
												500
											</button>

											<button
												type="button"
												className="form-input-round"
												onClick={(e) => {
													e.preventDefault();
													autoWithdrawalForm.amount = Math.floor(
														allowWithdrawal / 2
													);
													displayWithdrawalFees();
												}}>
												{" "}
												{Math.floor(allowWithdrawal / 2)}
											</button>
											<button
												type="button"
												className="form-input-round"
												onClick={(e) => {
													e.preventDefault();
													autoWithdrawalForm.amount =
														Math.floor(allowWithdrawal);
													displayWithdrawalFees();
												}}>
												{" "}
												{Math.floor(allowWithdrawal)}
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className=" pt-6 flex justify-between space-x-2 mt-8 py-3  ">
							<button
								className=" btn btn-transparent border cursor-pointer font-sans"
								onClick={() => handleSetCurrent(8)}>
								Cancel
							</button>

							<button
								type="button"
								className={`btn btn-lg lg:w-1/2 border text-center  font-sans bg-payment-ep-blue text-white ${btnBgColor}`}
								onClick={() => {
									setValidateAction("update");
									handleSetCurrent(5);
								}}>
								Continue
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

const CancelPaymentSlide = ({
	handleTogglePayment,
	loadingDelete,
	autopayCancellationFee,
	loadingCancelAutopay,
	handleSetCurrent,
	setValidateAction,
}) => {
	return (
		<div className="w-full md:my-auto">
			<div className="pb-14 md:pt-5 lg:pl-4 lg:pr-4">
				<div className="mx-auto mt-8 py-6 rounded-md border border-gray-400">
					<div className=" px-5 mx-auto h-60 my-3">
						<div className="w-10 h-10">
							{" "}
							<img
								src={dangerIcon}
								alt="cancel payment logo"
								className="w-full h-full object-contain"
							/>
						</div>
						<div className="pt-8">
							<div className="text-base" style={{ color: "#101828" }}>
								Turn off payment
							</div>
							{/* {
                                loadingCancelAutopay && <FontAwesomeIcon icon="times" className=" m-auto text-gray-500 " />

                            } */}
							{autopayCancellationFee !== null && (
								<div className="text-sm" style={{ color: "#667085" }}>
									Are you sure you want to cancel your payment?, you would be
									charged{" "}
									{CurrencyFormat(autopayCancellationFee?.cancellationFee)}{" "}
								</div>
							)}
							<div className="pt-3">
								{/* <div className='mt-4' onClick={() => deleteSchedulePayment(memorizedSchedules[0]._id)}>
                                    <button className='btn btn-block bg-red-600 text-white'> <FontAwesomeIcon icon='spinner' spin className={`mr-3 ${loadingDelete ? 'inline' : 'hidden'}`} /> Turn off payment</button>
                                </div> */}
								<div
									className="mt-4"
									onClick={() => {
										handleSetCurrent(11);
										setValidateAction("delete");
									}}>
									<button className="btn btn-block bg-red-600 text-white">
										{" "}
										<FontAwesomeIcon
											icon="spinner"
											spin
											className={`mr-3 ${loadingDelete ? "inline" : "hidden"}`}
										/>{" "}
										Turn off payment
									</button>
								</div>
								<div
									className="mt-4"
									onClick={() => handleTogglePayment(false)}>
									<button className="btn btn-block bg-gray-200">
										{" "}
										Cancel{" "}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export const PreviewCancelPayment = ({
	current,
	handleTogglePayment,
	handleSetCurrent,
	autopayCancellationFee,
	loadingCancelAutopay,
}) => {
	return (
		<div className=" px-6 animate" current={current}>
			<div className="modal-header">
				<div className="modal-btn invisible"></div>
				<div className="flex justify-between">
					<div
						className="modal-btn btn-close cursor-pointer"
						onClick={() => handleSetCurrent(8)}>
						<FontAwesomeIcon
							icon="angle-left"
							className="text-gray-500 m-auto"
						/>
					</div>
					<div className="font-recoleta text-base">Payment Preview</div>
					<div
						className="modal-btn btn-close cursor-pointer"
						onClick={() => handleTogglePayment(false)}>
						<FontAwesomeIcon icon="times" className=" m-auto text-gray-500 " />
					</div>
				</div>

				<div className="pt-6 px-5">
					<div className="py-3 text-gray-700 text-base">
						Please review the details below carefully before continuing:
					</div>
					<div className="mx-auto">
						<div className=" my-4 font-sans  pb-6">
							<div className="text-sm ep-card-blue flex justify-between my-2 bg-flex-card-summary">
								<div>
									<div className="py-1 bg-transaction-card-text">Action</div>
									<div className="py-1 bg-transaction-card-text">
										Charge Fee
									</div>
								</div>
								<div className=" pl-4">
									<div className="font-medium flex-custom-num-color py-1 capitalize">
										Turn off Flexx Payment{" "}
									</div>
									{loadingCancelAutopay && (
										<FontAwesomeIcon
											icon="spin"
											spinner
											className=" m-auto text-gray-500 "
										/>
									)}
									{/* brb */}
									{autopayCancellationFee !== null && (
										<div className="text-sm" style={{ color: "#667085" }}>
											{CurrencyFormat(autopayCancellationFee?.cancellationFee)}{" "}
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
					<div className="  mx-auto pt-10 flex justify-between space-x-2 mt-12 py-3  ">
						<button
							className=" btn btn-transparent cursor-pointer font-sans"
							onClick={() => handleTogglePayment(false)}>
							Cancel
						</button>

						<button
							className={`btn btn-lg lg:w-1/2 border text-center font-sans bg-payment-ep-blue text-white`}
							onClick={() => {
								handleSetCurrent(6);
							}}>
							Continue
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export const PaymentModal = (props) => {
	const completeButtonRef = React.createRef(null);

	const {
		open,
		handleTogglePayment,
		current,
		handleSetCurrent,
		autoWithdrawalForm,
		handleChange,
		disableContinue,
		passwordForm,
		bgCol,
		formChange,
		submitForm,
		memorizedDetails,
		displayWithdrawalFees,
		withdrawalFee,
		setPasswordPageForEdit,
		loadingCreateWithdrawal,
		loadingUpdate,
		schedule,
		memorizedWithdrawals,
		memorizedSchedules,
		secheduleGet,
		getScheduleById,
		deleteSchedulePayment,
		loadingDelete,
		paymentFeeBy,
		handleInputScheduleOnEdit,
		handleScheduleUpdate,
		loadingGetSchedule,
		onboardToAutoPay,
		handleDaySelectLog,
		addBgCol,
		autopayFeeLoading,
		autopayFee,
		setAutoWithdrawalForm,
		setPayementDueDate,
		days,
		paymentDueDate,
		cancelAutopaySchedule,
		autopayCancellationFee,
		loadingCancelAutopay,
		validateAction,
		setValidateAction,
		active,
		setActive,
		handleSetDayValue,
		clickedDate,
		setClickedDate,
	} = props;
	return (
		<Dialog
			open={open}
			initialFocus={completeButtonRef}
			onClose={() => handleTogglePayment(false)}
			className="fixed z-10 inset-0 overflow-y-auto">
			<div className="flex items-center justify-center border-2 min-h-screen border-black">
				<Dialog.Overlay className="fixed inset-0 bg-white bg-blur bg-opacity-50" />

				<div
					className={`modal-body ${
						current <= 2 ? "modal-payment-box" : "modal-box2"
					}`}>
					{current === 1 && (
						<FirstSlide
							current={current}
							completeButtonRef={completeButtonRef}
							handleSetCurrent={handleSetCurrent}
							onboardToAutoPay={onboardToAutoPay}
						/>
					)}
					{current === 2 && (
						<SecondSlide
							current={current}
							handleSetCurrent={handleSetCurrent}
							onboardToAutoPay={onboardToAutoPay}
						/>
					)}
					{current === 3 && (
						<ThirdSlide
							current={current}
							completeButtonRef={completeButtonRef}
							handleSetCurrent={handleSetCurrent}
							handleTogglePayment={handleTogglePayment}
							memorizedDetails={memorizedDetails}
							memorizedSchedules={memorizedSchedules}
						/>
					)}
					{current === 4 && (
						<FourthSlide
							setAutoWithdrawalForm={setAutoWithdrawalForm}
							memorizedDetails={memorizedDetails}
							current={current}
							handleSetCurrent={handleSetCurrent}
							completeButtonRef={completeButtonRef}
							handleTogglePayment={handleTogglePayment}
							autoWithdrawalForm={autoWithdrawalForm}
							handleChange={handleChange}
							disableContinue={disableContinue}
							displayWithdrawalFees={displayWithdrawalFees}
							days={days}
						/>
					)}
					{current === 5 && (
						<FifthSlide
							paymentFeeBy={paymentFeeBy}
							paymentDueDate={paymentDueDate}
							memorizedSchedules={memorizedSchedules}
							setPasswordPageForEdit={setPasswordPageForEdit}
							current={current}
							handleSetCurrent={handleSetCurrent}
							completeButtonRef={completeButtonRef}
							handleTogglePayment={handleTogglePayment}
							autoWithdrawalForm={autoWithdrawalForm}
							withdrawalFee={withdrawalFee}
							autopayFeeLoading={autopayFeeLoading}
							autopayFee={autopayFee}
							setPayementDueDate={setPayementDueDate}
						/>
					)}
					{current === 6 && (
						<ValidateSchedule
							loadingDelete={loadingDelete}
							deleteSchedulePayment={deleteSchedulePayment}
							validateAction={validateAction}
							loadingUpdate={loadingUpdate}
							memorizedSchedules={memorizedSchedules}
							handleScheduleUpdate={handleScheduleUpdate}
							current={current}
							handleSetCurrent={handleSetCurrent}
							handleTogglePayment={handleTogglePayment}
							passwordForm={passwordForm}
							bgCol={bgCol}
							addBgCol={addBgCol}
							formChange={formChange}
							submitForm={submitForm}
							autoWithdrawalForm={autoWithdrawalForm}
							loadingCreateWithdrawal={loadingCreateWithdrawal}
							completeButtonRef={completeButtonRef}
						/>
					)}
					{current === 7 && (
						<SeventhSlide
							current={current}
							completeButtonRef={completeButtonRef}
						/>
					)}
					{current === 8 && (
						<SchedulSummary
							cancelAutopaySchedule={cancelAutopaySchedule}
							handleDaySelectLog={handleDaySelectLog}
							loadingGetSchedule={loadingGetSchedule}
							autoWithdrawalForm={autoWithdrawalForm}
							handleTogglePayment={handleTogglePayment}
							current={current}
							completeButtonRef={completeButtonRef}
							handleSetCurrent={handleSetCurrent}
							schedule={schedule}
							memorizedDetails={memorizedDetails}
							memorizedWithdrawals={memorizedWithdrawals}
							memorizedSchedules={memorizedSchedules}
							getScheduleById={getScheduleById}
						/>
					)}
					{current === 9 && (
						<CancelPaymentSlide
							setValidateAction={setValidateAction}
							autopayCancellationFee={autopayCancellationFee}
							loadingCancelAutopay={loadingCancelAutopay}
							current={current}
							completeButtonRef={completeButtonRef}
							handleSetCurrent={handleSetCurrent}
							deleteSchedulePayment={deleteSchedulePayment}
							schedule={secheduleGet}
							memorizedSchedules={memorizedSchedules}
							handleTogglePayment={handleTogglePayment}
							loadingDelete={loadingDelete}
						/>
					)}

					{current === 10 && (
						<EditAutoPay
							setValidateAction={setValidateAction}
							clickedDate={clickedDate}
							setClickedDate={setClickedDate}
							memorizedDetails={memorizedDetails}
							setAutoWithdrawalForm={setAutoWithdrawalForm}
							active={active}
							setActive={setActive}
							handleSetDayValue={handleSetDayValue}
							current={current}
							handleSetCurrent={handleSetCurrent}
							setPasswordPageForEdit={setPasswordPageForEdit}
							handleTogglePayment={handleTogglePayment}
							autoWithdrawalForm={autoWithdrawalForm}
							handleChange={handleChange}
							disableContinue={disableContinue}
							displayWithdrawalFees={displayWithdrawalFees}
							completeButtonRef={completeButtonRef}
							handleScheduleUpdate={handleScheduleUpdate}
							secheduleGet={secheduleGet}
							handleInputScheduleOnEdit={handleInputScheduleOnEdit}
							loadingGetSchedule={loadingGetSchedule}
						/>
					)}
					{current === 11 && (
						<PreviewCancelPayment
							current={current}
							handleSetCurrent={handleSetCurrent}
							autopayCancellationFee={autopayCancellationFee}
							handleTogglePayment={handleTogglePayment}
							loadingCancelAutopay={loadingCancelAutopay}
						/>
					)}
				</div>
				<div ref={completeButtonRef}></div>
			</div>
		</Dialog>
	);
};
