import "./authPages.css";
import React, { Component } from "react";
import store from "store/store";
import Joi from "joi-browser";
import logger from "utils/logger";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import logo from "assets/images/icons/grn-logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as auth from "store/auth/user/action";
import googleAnalyticsTrack from "utils/googleAnalyticsTrack";
import DynamicHeadTag from "components/common/DynamicHeadTag";

class Login extends Component {
	state = {
		loginForm: {
			phoneNumber: "",
            pin: ""
		},
		errors: {},
	};

	formChange = (e) => {
		const formValue = { ...this.state.loginForm };
		formValue[e.currentTarget.name] = e.currentTarget.value;
		this.setState({ loginForm: formValue });
	};

	schema = {
        phoneNumber: Joi.string().min(11).max(11).required().label("Phone Number").error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "any.empty":
                        err.message = "Please enter your phone number";
                        break;
                    case "string.min":
                        err.message = `Please enter 11 digits for your phone number`;
                        break;
                    case "string.max":
                        err.message = `Please enter 11 digits for your phone number`;
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
        pin: Joi.string().required().label("PIN").error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "any.empty":
                        err.message = "Please enter your PIN";
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
    }

	validate = () => {
		const options = { abortEarly: false };
		const result = Joi.validate(this.state.loginForm, this.schema, options);
		// console.log(result);
		const error = result.error;
		if (!error) return null;

		const errors = {};
		for (let item of result.error.details) {
			errors[item.path[0]] = item.message;
		}
		return errors;
	};

	submitForm = async (e) => {
		e.preventDefault();
		const errors = this.validate();
		this.setState({ errors: errors || {} });
		if (errors) {
			// console.log(errors);
			for (var x in errors) {
				toast.error(errors[x]);
			}
			return;
		} else {
			const {phoneNumber, pin} = this.state.loginForm;
            this.props.login({phoneNumber, pin});
		}
	};

	componentDidMount() {
		logger("employeeweb-app-page");
		googleAnalyticsTrack();

		this.unsubscribe = store.subscribe(() => {
			if (store.getState().auth.userPersist.loggedIn === true) {
				logger("employeeweb-sign-in");
				this.props.history.push("/dashboard");
			}
		});
	}

	componentWillUnmount() {
		this.unsubscribe();
	}

	render() {
		const { loginForm } = this.state;

		return (
			<div className="w-full p-8 sm:p-14 md:my-auto">
				<DynamicHeadTag headerText="Login" />
				<div className="pb-14 md:pt-20 lg:pl-4 lg:pr-4">
					<Link
						to="/login"
						className=" my-8 h-8 sm:h-10 flex justify-center animate hover:scale-110 ">
						<img
							src={logo}
							alt="EarniPay logo"
							className="h-full object-contain"
						/>
					</Link>
					<div className="font-recoleta font-semibold text-2xl sm:text-3xl text-center">
						Welcome back!
					</div>

					<div>
						<form onSubmit={this.submitForm}>
							<div className="my-10 w-full max-w-sm mx-auto p-10 bg-white rounded-md">
								<div className="form-group">
                                    <label>
                                        Phone Number
                                        <span className="form-input-required">*</span>
                                    </label>
                                    <input type="tel" name="phoneNumber" placeholder="080XXXXXXXX" className="form-input" value={loginForm.phoneNumber} onChange={this.formChange} />
                                </div>

                                <div className="form-group">
                                    <label>
                                        PIN
                                        <span className="form-input-required">*</span>
                                    </label>
                                    <input type="password" name="pin" placeholder="XXXX" className="form-input" value={loginForm.pin} onChange={this.formChange} />
                                </div>

								<div className="-mt-3 text-sm font-semibold text-gray-500">
									<Link
										to="/forgot-password"
										className="text-ep-blue hover:underline">
										Forgot Password
									</Link>
								</div>

								<button
									type="submit"
									className="btn btn-lg btn-block btn-ep-blue mt-8"
									disabled={this.props.loading}>
									<FontAwesomeIcon
										icon="spinner"
										spin
										className={
											"text-xl mr-2 " +
											(this.props.loading ? "inline" : "hidden")
										}
									/>
									Log In
								</button>

								{/* 
								<div className="mt-3 text-sm font-semibold text-gray-500">
									I dont have an account? &nbsp;
									<Link
										to="/get-started"
										className="text-ep-blue hover:underline">
										Register Now
									</Link>
								</div>
								*/}

								<div className="mt-3 text-sm text-center font-semibold">
									<Link to="/login" className="text-ep-blue hover:underline">
										Login with password
									</Link>
								</div>

							</div>
						</form>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	userLoggedIn: state.auth.userPersist.loggedIn,
	loading: state.auth.user.loading,
});

const mapDispatchToProps = (dispatch) => ({
	login: (params) => dispatch(auth.loginUserWithPin(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
