import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import authIntro1 from "assets/images/newDesignAssets/svgs/authIllustration.svg";
import authIntro2 from "assets/images/newDesignAssets/svgs/AuthSlideTwo.svg";
import authIntro3 from "assets/images/newDesignAssets/svgs/AuthSlideThree.svg";

const slideshowProperties = {
    duration: 3000,
    transitionDuration: 300,
    pauseOnHover: true,
    indicators: true,
    autoplay: true,
    infinite: true,
    canSwipe: true,
    prevArrow: <div></div>,
    nextArrow: <div></div>,
}

const AuthLayout = (props) => {
    return ( 
        <div className="md:flex">
            <div className="w-full md:w-2/4 md:flex bg-new-ep-grey">
                {props.children}
            </div>
            <div className="w-full  md:w-2/4 md:min-h-screen overflow-hidden">
                <div className="w-full h-full p-6 pb-10 md:pb-20 auth-bg-ep-pattern bg-cover bg-no-repeat">
                    <div className="auth-slideshow max-w-sm mx-auto mt-10">
                        <Slide {...slideshowProperties} easing="ease">
                                
                            <div className="slideshow-each ">
                                <div className="max-w-sm text-center">
                                    <div className="w-56 h-56 md:w-64 md:h-64 lg:w-96 lg:h-96 mx-auto flex bg-opacity-5 rounded-full">
                                        <div className="w-full h-full  m-auto flex bg-opacity-5 rounded-full">
                                            <div className="w-full h-full">
                                                <img src={authIntro1} alt="Improve Employee Productivity" className="object-contain mx-auto lg:mx-0" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-4 lg:-mt-8">
                                        <div className="mt-8 font-recoleta font-semibold text-xl sm:text-2xl md:text-3xl">
                                            Improve Employee&nbsp;
                                            <br className="hidden md:block" />
                                            Productivity
                                        </div>
                                        <div className="mt-3 md:mt-6 text-base">
                                            Reduce distractions caused due to financial short falls and lack of disposable income.
                                        </div>
                                    </div>
                                </div>
                            </div>
                                
                            <div className="slideshow-each">
                                <div className="max-w-sm text-center">
                                    <div className="w-56 h-56 md:w-64 md:h-64 lg:w-96 lg:h-96 mx-auto flex bg-opacity-5 rounded-full">
                                        <div className="w-full h-full m-auto flex bg-opacity-5 rounded-full">
                                            <div className="w-full h-full">
                                                <img src={authIntro2} alt="Attract and Retain Best Talent" className="object-contain mx-auto lg:mx-0" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-4 lg:-mt-8">
                                        <div className="mt-8 font-recoleta font-semibold text-xl sm:text-2xl md:text-3xl">
                                            Attract and Retain&nbsp;
                                            <br className="hidden md:block" />
                                            Best Talent
                                        </div>
                                        <div className="mt-3 md:mt-6 text-base">
                                            Incentivise current and future employees with the #1 employee benefit they need.
                                        </div>
                                    </div>
                                </div>
                            </div>
                                
                            <div className="slideshow-each">
                                <div className="max-w-sm text-center">
                                    <div className="w-56 h-56 md:w-64 md:h-64 lg:w-96 lg:h-96 mx-auto flex bg-opacity-5 rounded-full">
                                        <div className="w-full h-full m-auto flex bg-opacity-5 rounded-full">
                                            <div className="w-full h-full">
                                                <img src={authIntro3} alt="Build Financial Resilience" className="object-contain mx-auto lg:mx-0" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-10 lg:-mt-8">
                                        <div className=" mt-8 font-recoleta font-semibold text-xl sm:text-2xl md:text-3xl">
                                            Build Financial&nbsp;
                                            <br className="hidden md:block" />
                                            Resilience
                                        </div>
                                        <div className="mt-3 md:mt-6 text-base">
                                            Empower employees to withstand unexpected expenses and avoid unnecessary borrowing.
                                        </div>
                                    </div>
                                </div>
                            </div>
                                
                        </Slide>
                    </div>
                </div>
            </div>
           
        </div>
     );
}
 
export default AuthLayout;